import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { formatDate } from '../../helpers/Date';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { PreplanExport } from '../../models/Preplan';
import DownloadIcon from '../../components/FigmaExport/DownloadIcon';


const getVisibleDataHeaders = (visibleHeaderIds) => {
  const requiredFields = [
    { label: 'FlowMSP Building ID', key: 'id' },
    { label: 'Business Name', key: 'name' },
    { label: 'Street Address 1', key: 'address1' },
    { label: 'Street Address 2', key: 'address2' },
    { label: 'City', key: 'city' },
    { label: 'State', key: 'state' },
    { label: 'Zip', key: 'zip' },
    { label: 'Lot Number', key: 'lotNumber' },
    { label: 'District', key: 'customerZone' }
  ];

  const optionalFields = [
    { label: 'Floors Above', key: 'storey' },
    { label: 'Floors Below', key: 'storeyBelow' },
    { label: 'Roof Area', key: 'roofArea' },
    { label: 'Hydrants', key: 'hydrants' },
    { label: 'Images', key: 'imageLength' },
    { label: 'ImageAnnotations', key: 'imageAnnotations' },
    { label: 'Building Data Completion', key: 'completion' },
    { label: 'Floor Plan', key: 'floorPlan' },
    { label: 'High Hazard', key: 'isHighHazard' },
    { label: 'Risk Assessment Score', key: 'riskAssessmentScore' },
    { label: 'Review Status', key: 'reviewStatus' },
    { label: 'Created On', key: 'originalPrePlan' },
    { label: 'Last Reviewed On', key: 'lastReviewedOn' },
    { label: 'Last Reviewed By', key: 'lastReviewedBy' },
    { label: 'Assigned By', key: 'assignedBy' },
    { label: 'Assigned To', key: 'assignedTo' },
    { label: 'Assignment Status', key: 'assignmentStatus' },
    { label: 'Assignment Due On', key: 'assignmentDueOn' },
    // .building fields
    { label: 'Commercial', key: 'building.commercialStructure' },
    { label: 'Occupancy Type', key: 'building.occupancyType' },
    { label: 'Construction Type', key: 'building.constructionType' },
    { label: 'Roof Type', key: 'building.roofType' },
    { label: 'Roof Construction', key: 'building.roofConstruction' },
    { label: 'Roof Material', key: 'building.roofMaterial' },
    { label: 'Sprinklered', key: 'building.sprinklered' },
    { label: 'Stand Pipe', key: 'building.standPipe' },
    { label: 'Fire Alarm', key: 'building.fireAlarm' },
    { label: 'Normal Population', key: 'building.normalPopulation' },
    { label: 'Hours Of Operation', key: 'building.hoursOfOperation' },
    { label: 'Owner Contact', key: 'building.ownerContact' },
    { label: 'Owner Phone', key: 'building.ownerPhone' },
    { label: 'Notes (Optional)', key: 'building.notes' },
    { label: 'Third-Party Notes', key: 'building.partnerNotes' }
  ];

  return [
    ...requiredFields,
    ...optionalFields.filter(h => visibleHeaderIds.indexOf(h.key) > -1)
  ];
};

export const DownloadButton = (props) => {
  const customer = useSelector(state => state.customer);
  const [downloadData, setDownloadData] = useState([]);
  useEffect(() => {
    if (!props.tableData) return;

    try {
      setDownloadData(mapTableData(props.tableData));
    } catch (e) {
      console.error('Error mapping table data to download data: ', e); // eslint-disable-line no-console
      setDownloadData([]);
    }
  }, [props.tableData]);

  const mapTableData = (locs) => {
    return locs.map((item) => PreplanExport.parse({
      ...item,
      address1: item.address.address1,
      address2: item.address.address2,
      city: item.address.city,
      state: item.address.state,
      zip: item.address.zip,
      completion: item.completion.completion,
    }));
  };

  if (props.menuItem) {
    return (
        <Button variant="ghost" style={{
          fontWeight: 'inherit',
          color: 'inherit',
          flexGrow: 1,
          justifyContent: 'start',
          borderRadius: 0
        }} onClick={props.onComplete} as={CSVLink} headers={getVisibleDataHeaders(props.visibleHeaderIds)} filename={`${customer.name} Preplan Report ${formatDate(new Date())}.csv`} data={downloadData}>
          {props.children || 'Download CSV'}
        </Button>
    );
  }

  return (
      <Button onClick={props.onComplete} variant="outline" as={CSVLink} headers={getVisibleDataHeaders(props.visibleHeaderIds)} filename={`${customer.name} Preplan Report ${formatDate(new Date())}.csv`} data={downloadData} leftIcon={<DownloadIcon />}>
        {props.children || 'Download CSV'}
      </Button>
  );

};
