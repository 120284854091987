import React, { useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import FormComponent from './FormComponent';
import { Divider, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { OutlineButton, SubmitButton, SubmitButtonDanger } from './Button';
import { useDispatch } from 'react-redux';
import { addOccupant, deleteOccupant, editOccupant } from '../../features/occupants/occupantsSlice';


const occupantFormFields = (floors) => [
  {
    label: 'Occupant Label',
    id: 'name',
    name: 'name',
    type: 'text',
    placeholder: '',
    required: true,
  },
  {
    label: 'Address 1',
    id: 'address1',
    name: 'address1',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Address 2',
    id: 'address2',
    name: 'address2',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Zip',
    id: 'zip',
    name: 'zip',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Floor',
    id: 'floor',
    name: 'floor',
    type: 'select',
    options: [
      {
        label: 'Select Floor',
        value: '',
      },
      ...floors.map(f => ({
        label: f.name,
        value: f.name,
      })),
    ],
    required: false
  },
  {
    label: 'Occupancy Type',
    id: 'occupancyType',
    name: 'occupancyType',
    type: 'select',
    options: [
      {
        label: 'Select Occupancy Type',
        value: ''
      },
      {
        label: 'Assembly',
        value: 'Assembly'
      },
      {
        label: 'Board & Care',
        value: 'Board & Care'
      },
      {
        label: 'Business / Mercantile',
        value: 'Business / Mercantile'
      },
      {
        label: 'Day-Care',
        value: 'Day-Care'
      },
      {
        label: 'Detention & Correctional',
        value: 'Detention & Correctional'
      },
      {
        label: 'Educational',
        value: 'Educational'
      },
      {
        label: 'High Hazard',
        value: 'High Hazard'
      },
      {
        label: 'Industrial',
        value: 'Industrial'
      },
      {
        label: 'Medical Care / Institutional',
        value: 'Medical Care / Institutional'
      },
      {
        label: 'Mixed Business / Residential',
        value: 'Mixed Business / Residential'
      },
      {
        label: 'Multi-Family',
        value: 'Multi-Family'
      },
      {
        label: 'Residential',
        value: 'Residential'
      },
      {
        label: 'Special Structures',
        value: 'Special Structures'
      },
      {
        label: 'Storage',
        value: 'Storage'
      }
    ],
    required: false
  },
  {
    label: 'Construction Type',
    id: 'constructionType',
    name: 'constructionType',
    type: 'select',
    options: [
      {
        label: 'Select Construction Type',
        value: ''
      },
      {
        label: 'Not Classified',
        value: 'Not Classified'
      },
      {
        label: 'Type IA - Fire Resistive, Non-combustible',
        value: 'Type IA - Fire Resistive, Non-combustible'
      },
      {
        label: 'Type IB - Fire Resistive, Non-combustible',
        value: 'Type IB - Fire Resistive, Non-combustible'
      },
      {
        label: 'Type IIA - Protective, Non-combustible',
        value: 'Type IIA - Protective, Non-combustible'
      },
      {
        label: 'Type IIB - Unprotective, Non-combustible',
        value: 'Type IIB - Unprotective, Non-combustible'
      },
      {
        label: 'Type IIIA - Protected Ordinary',
        value: 'Type IIIA - Protected Ordinary'
      },
      {
        label: 'Type IIIB - Unprotected Ordinary',
        value: 'Type IIIB - Unprotected Ordinary'
      },
      {
        label: 'Type IV - Heavy Timber',
        value: 'Type IV - Heavy Timber'
      },
      {
        label: 'Type VA - Protected Combustible',
        value: 'Type VA - Protected Combustible'
      },
      {
        label: 'Type VB - Unprotected Combustible',
        value: 'Type VB - Unprotected Combustible'
      }
    ],
    required: false
  },
  {
    label: 'Do Not Share',
    id: 'doNotShare',
    name: 'doNotShare',
    type: 'checkbox',
    description: 'Do Not Share'
  },
  {
    label: 'Square Feet',
    id: 'sqft',
    name: 'sqft',
    type: 'number',
    placeholder: ''
  },
  {
    label: 'Roof Type',
    id: 'roofType',
    name: 'roofType',
    type: 'select',
    options: [
      {
        label: 'Select Roof Type',
        value: ''
      },
      {
        label: 'Bonnet',
        value: 'Bonnet'
      },
      {
        label: 'Bowstring Truss',
        value: 'Bowstring Truss'
      },
      {
        label: 'Butterfly',
        value: 'Butterfly'
      },
      {
        label: 'Combination',
        value: 'Combination'
      },
      {
        label: 'Curved',
        value: 'Curved'
      },
      {
        label: 'Dome',
        value: 'Dome'
      },
      {
        label: 'Flat',
        value: 'Flat'
      },
      {
        label: 'Gable',
        value: 'Gable'
      },
      {
        label: 'Gambrel',
        value: 'Gambrel'
      },
      {
        label: 'Hip',
        value: 'Hip'
      },
      {
        label: 'Jerkinhead',
        value: 'Jerkinhead'
      },
      {
        label: 'Mansard',
        value: 'Mansard'
      },
      {
        label: 'Pyramid',
        value: 'Pyramid'
      },
      {
        label: 'Saltbox',
        value: 'Saltbox'
      },
      {
        label: 'Sawtooth',
        value: 'Sawtooth'
      },
      {
        label: 'Skillion',
        value: 'Skillion'
      }
    ],
    required: false
  },
  {
    label: 'Roof Construction',
    id: 'roofConstruction',
    name: 'roofConstruction',
    type: 'select',
    options: [
      {
        label: 'Select Roof Construction',
        value: ''
      },
      {
        label: 'Beam - Concrete',
        value: 'Beam - Concrete'
      },
      {
        label: 'Beam - Steel',
        value: 'Beam - Steel'
      },
      {
        label: 'Beam - Wood',
        value: 'Beam - Wood'
      },
      {
        label: 'Steel Truss - Open Web',
        value: 'Steel Truss - Open Web'
      },
      {
        label: 'Wood / Steel - Closed Web',
        value: 'Wood / Steel - Closed Web'
      },
      {
        label: 'Wood / Steel - Open Web',
        value: 'Wood / Steel - Open Web'
      },
      {
        label: 'Wood Truss - Closed Web',
        value: 'Wood Truss - Closed Web'
      },
      {
        label: 'Wood Truss - Open Web',
        value: 'Wood Truss - Open Web'
      }
    ],
    required: false
  },
  {
    label: 'Roof Material',
    id: 'roofMaterial',
    name: 'roofMaterial',
    type: 'select',
    options: [
      {
        label: 'Select Roof Material',
        value: ''
      },
      {
        label: 'Built-Up',
        value: 'Built-Up'
      },
      {
        label: 'Composition Shingles',
        value: 'Composition Shingles'
      },
      {
        label: 'Membrane - Plastic elastomer',
        value: 'Membrane - Plastic elastomer'
      },
      {
        label: 'Membrane - Synthetic elastomer',
        value: 'Membrane - Synthetic elastomer'
      },
      {
        label: 'Metal',
        value: 'Metal'
      },
      {
        label: 'Metal - Corrugated',
        value: 'Metal - Corrugated'
      },
      {
        label: 'Metal - Shake',
        value: 'Metal - Shake'
      },
      {
        label: 'Metal - Standing Seam',
        value: 'Metal - Standing Seam'
      },
      {
        label: 'Roof Covering Not Class',
        value: 'Roof Covering Not Class'
      },
      {
        label: 'Roof Covering Undetermined/Not Reported',
        value: 'Roof Covering Undetermined/Not Reported'
      },
      {
        label: 'Shingle - Asphalt / Composition',
        value: 'Shingle - Asphalt / Composition'
      },
      {
        label: 'Slate - Composition',
        value: 'Slate - Composition'
      },
      {
        label: 'Slate - Natural',
        value: 'Slate - Natural'
      },
      {
        label: 'Structure Without Roof',
        value: 'Structure Without Roof'
      },
      {
        label: 'Tile - Clay',
        value: 'Tile - Clay'
      },
      {
        label: 'Tile - Concrete',
        value: 'Tile - Concrete'
      },
      {
        label: 'Tile (clay, cement, slate, etc.)',
        value: 'Tile (clay, cement, slate, etc.)'
      },
      {
        label: 'Wood - Shingle/Shake',
        value: 'Wood - Shingle/Shake'
      },
      {
        label: 'Wood Shakes/Shingles (Treated)',
        value: 'Wood Shakes/Shingles (Treated)'
      },
      {
        label: 'Wood Shakes/Shingles (Untreated)',
        value: 'Wood Shakes/Shingles (Untreated)'
      }
    ],
    required: false
  },
  {
    label: 'Sprinklered',
    id: 'sprinklered',
    name: 'sprinklered',
    type: 'select',
    options: [
      {
        label: 'Select Sprinkler Type',
        value: ''
      },
      {
        label: 'Dry System',
        value: 'Dry System'
      },
      {
        label: 'Wet System',
        value: 'Wet System'
      },
      {
        label: 'Both',
        value: 'Both'
      },
      {
        label: 'None',
        value: 'None'
      }
    ],
    required: false
  },
  {
    label: 'Stand Pipe',
    id: 'standPipe',
    name: 'standPipe',
    type: 'select',
    options: [
      {
        label: 'Select Stand Pipe',
        value: ''
      },
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      }
    ],
    required: false
  },
  {
    label: 'Fire Alarm',
    id: 'fireAlarm',
    name: 'fireAlarm',
    type: 'select',
    options: [
      {
        label: 'Select Fire Alarm',
        value: ''
      },
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      }
    ],
    required: false
  },
  {
    label: 'Normal Population',
    id: 'normalPopulation',
    name: 'normalPopulation',
    type: 'select',
    options: [
      {
        label: 'Select Normal Population',
        value: ''
      },
      {
        label: 'Vacant',
        value: 'Vacant'
      },
      {
        label: '1 - 10',
        value: '1 - 10'
      },
      {
        label: '11 - 50',
        value: '11 - 50'
      },
      {
        label: '51 - 100',
        value: '51 - 100'
      },
      {
        label: '101 - 500',
        value: '101 - 500'
      },
      {
        label: '501 - 1000',
        value: '501 - 1000'
      }
    ],
    required: false
  },
  {
    label: 'Hours Of Operation',
    id: 'hoursOfOperation',
    name: 'hoursOfOperation',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Owner Contact',
    id: 'ownerContact',
    name: 'ownerContact',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Owner Phone',
    id: 'ownerPhone',
    name: 'ownerPhone',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Notes (Optional)',
    id: 'notes',
    name: 'notes',
    type: 'textarea',
    placeholder: ''
  },
];


export const OccupantForm = ({
  occupant,
  action,
  closeModal,
  floors
}) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const dispatchAddOccupant = async(data) => {
    const result = await dispatch(addOccupant(data));
    if (addOccupant.fulfilled.match(result)) {
      onSuccess('Success', 'Occupant added successfully');
    } else {
      onError('Failed to add occupant', 'Please check occupant fields and try again.');
    }
  };

  const dispatchEditOccupant = async(id, occupantPatch) => {
    const result = await dispatch(editOccupant({ occupantId: id, occupantPatch: occupantPatch }));
    if (editOccupant.fulfilled.match(result)) {
      onSuccess('Success', 'Occupant updated');
    } else {
      onError('Failed to update occupant', 'Please check occupant fields and try again.');
    }
  };

  const dispatchDeleteOccupant = async(id) => {
    const result = await dispatch(deleteOccupant(id));
    if (deleteOccupant.fulfilled.match(result)) {
      onSuccess('Success', 'Occupant deleted');
    } else {
      onError('Cannot Delete Occupant');
    }
  };

  const onSubmit = (data) => {
    if (action === 'ADD_OCCUPANT') {
      const occupantAdd = {
        structureId: occupant.structureId,
      };
      data.name && (occupantAdd.name = data.name);
      if (
        data.address1 ||
        data.address2 ||
        data.city ||
        data.state ||
        data.zip
      ) {
        occupantAdd.address = {};
        data.address1 && (occupantAdd.address.address1 = data.address1);
        data.address2 && (occupantAdd.address.address2 = data.address2);
        data.city && (occupantAdd.address.city = data.city);
        data.state && (occupantAdd.address.state = data.state);
        data.zip && (occupantAdd.address.zip = data.zip);
      }
      data.floor && (occupantAdd.floor = data.floor);
      data.doNotShare && (occupantAdd.doNotShare = data.doNotShare);
      data.sqft && (occupantAdd.sqft = data.sqft);
      data.notes && (occupantAdd.notes = data.notes);
      if (
        data.occupancyType ||
        data.constructionType ||
        data.roofType ||
        data.roofConstruction ||
        data.roofMaterial ||
        data.sprinklered ||
        data.standPipe ||
        data.fireAlarm ||
        data.normalPopulation ||
        data.hoursOfOperation ||
        data.ownerContact ||
        data.ownerPhone
      ) {
        occupantAdd.building = {};
        data.occupancyType && (occupantAdd.building.occupancyType = data.occupancyType);
        data.constructionType && (occupantAdd.building.constructionType = data.constructionType);
        data.roofType && (occupantAdd.building.roofType = data.roofType);
        data.roofConstruction && (occupantAdd.building.roofConstruction = data.roofConstruction);
        data.roofMaterial && (occupantAdd.building.roofMaterial = data.roofMaterial);
        data.sprinklered && (occupantAdd.building.sprinklered = data.sprinklered);
        data.standPipe && (occupantAdd.building.standPipe = data.standPipe);
        data.fireAlarm && (occupantAdd.building.fireAlarm = data.fireAlarm);
        data.normalPopulation && (occupantAdd.building.normalPopulation = data.normalPopulation);
        data.hoursOfOperation && (occupantAdd.building.hoursOfOperation = data.hoursOfOperation);
        data.ownerContact && (occupantAdd.building.ownerContact = data.ownerContact);
        data.ownerPhone && (occupantAdd.building.ownerPhone = data.ownerPhone);
      }

      return dispatchAddOccupant(occupantAdd);
    } else if (action === 'EDIT_OCCUPANT') {
      const occupantPatch = [];
      (data.name || occupant.name) && occupantPatch.push({
        op: 'replace',
        path: '/name',
        value: data.name
      });
      (data.address1 || occupant.address?.address1) && occupantPatch.push({
        op: 'replace',
        path: '/address/address1',
        value: data.address1
      });
      (data.address2 || occupant.address?.address2) && occupantPatch.push({
        op: 'replace',
        path: '/address/address2',
        value: data.address2
      });
      (data.city || occupant.address?.city) && occupantPatch.push({
        op: 'replace',
        path: '/address/city',
        value: data.city
      });
      (data.state || occupant.address?.state) && occupantPatch.push({
        op: 'replace',
        path: '/address/state',
        value: data.state
      });
      (data.zip || occupant.address?.zip) && occupantPatch.push({
        op: 'replace',
        path: '/address/zip',
        value: data.zip
      });
      (data.floor || occupant.floor) && occupantPatch.push({
        op: 'replace',
        path: '/floor',
        value: data.floor
      });
      (data.occupancyType || occupant.building?.occupancyType) && occupantPatch.push({
        op: 'replace',
        path: '/building/occupancyType',
        value: data.occupancyType
      });
      (data.constructionType || occupant.building?.constructionType) && occupantPatch.push({
        op: 'replace',
        path: '/building/constructionType',
        value: data.constructionType
      });
      (data.doNotShare || occupant.doNotShare) && occupantPatch.push({
        op: 'replace',
        path: '/doNotShare',
        value: data.doNotShare || false,
      });
      (data.sqft || occupant.sqft) && occupantPatch.push({
        op: 'replace',
        path: '/sqft',
        value: data.sqft
      });
      (data.roofType || occupant.building?.roofType) && occupantPatch.push({
        op: 'replace',
        path: '/building/roofType',
        value: data.roofType
      });
      (data.roofConstruction || occupant.building?.roofConstruction) && occupantPatch.push({
        op: 'replace',
        path: '/building/roofConstruction',
        value: data.roofConstruction
      });
      (data.roofMaterial || occupant.building?.roofMaterial) && occupantPatch.push({
        op: 'replace',
        path: '/building/roofMaterial',
        value: data.roofMaterial
      });
      (data.sprinklered || occupant.building?.sprinklered) && occupantPatch.push({
        op: 'replace',
        path: '/building/sprinklered',
        value: data.sprinklered
      });
      (data.standPipe || occupant.building?.standPipe) && occupantPatch.push({
        op: 'replace',
        path: '/building/standPipe',
        value: data.standPipe
      });
      (data.fireAlarm || occupant.building?.fireAlarm) && occupantPatch.push({
        op: 'replace',
        path: '/building/fireAlarm',
        value: data.fireAlarm
      });
      (data.normalPopulation || occupant.building?.normalPopulation) && occupantPatch.push({
        op: 'replace',
        path: '/building/normalPopulation',
        value: data.normalPopulation
      });
      (data.hoursOfOperation || occupant.building?.hoursOfOperation) && occupantPatch.push({
        op: 'replace',
        path: '/building/hoursOfOperation',
        value: data.hoursOfOperation
      });
      (data.ownerContact || occupant.building?.ownerContact) && occupantPatch.push({
        op: 'replace',
        path: '/building/ownerContact',
        value: data.ownerContact
      });
      (data.ownerPhone || occupant.building?.ownerPhone) && occupantPatch.push({
        op: 'replace',
        path: '/building/ownerPhone',
        value: data.ownerPhone
      });
      (data.notes || occupant.notes) && occupantPatch.push({
        op: 'replace',
        path: '/notes',
        value: data.notes
      });
      return dispatchEditOccupant(occupant.id, occupantPatch);
    } else if (action === 'DELETE_OCCUPANT') {
      return dispatchDeleteOccupant(occupant.id);
    }
    return Promise.resolve();
  };

  const onError = (...p) => onComplete('error', ...p);

  const onSuccess = (...p) => {
    closeModal();
    onComplete('success', ...p);
  };

  const onComplete = (type, title, body) => {
    toast({
      title: title,
      position: 'top',
      description: body,
      status: type,
      duration: 2500,
      isClosable: true
    });
  };

  const onCancel = () => {
    methods.reset();
    closeModal();
  };

  const editData = useMemo(() => {
    if (!occupant) return {};
    const data = {
      structureId: occupant.structureId,
      name: occupant.name,
      floor: occupant.floor,
      doNotShare: occupant.doNotShare,
      sqft: occupant.sqft,
      notes: occupant.notes,
    };
    if (occupant.address) {
      data.address1 = occupant.address.address1;
      data.address2 = occupant.address.address2;
      data.city = occupant.address.city;
      data.state = occupant.address.state;
      data.zip = occupant.address.zip;
    }
    if (occupant.building) {
      data.occupancyType = occupant.building.occupancyType;
      data.constructionType = occupant.building.constructionType;
      data.roofType = occupant.building.roofType;
      data.roofConstruction = occupant.building.roofConstruction;
      data.roofMaterial = occupant.building.roofMaterial;
      data.sprinklered = occupant.building.sprinklered;
      data.standPipe = occupant.building.standPipe;
      data.fireAlarm = occupant.building.fireAlarm;
      data.normalPopulation = occupant.building.normalPopulation;
      data.hoursOfOperation = occupant.building.hoursOfOperation;
      data.ownerContact = occupant.building.ownerContact;
      data.ownerPhone = occupant.building.ownerPhone;
    }
    return data;  
  }, [occupant]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {(action === 'DELETE_OCCUPANT') ? (
          <DeleteForm name={occupant?.name} onCancel={onCancel} />
        ) : (
          <EditForm data={editData} onCancel={onCancel} floors={floors} />
        )}
      </form>
    </FormProvider>
  );
};

const DeleteForm = (props) => {
  const methods = useFormContext();

  return (
    <Stack spacing="1rem">
      <Text>Are you sure you want to delete <strong>{ props.name || "this occupant" }</strong>?</Text>
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={props.onCancel} width="8rem">No</OutlineButton>
        <SubmitButtonDanger isLoading={methods.formState.isSubmitting} width="8rem">Delete</SubmitButtonDanger>
      </HStack>
    </Stack>
  );
};

const EditForm = ({
  onCancel,
  data,
  floors
}) => {
  const methods = useFormContext();

  const fields = useMemo(() => occupantFormFields(floors.slice(1)), [floors]);

  return (
    <Stack spacing="1rem">
      <FormComponent formFields={fields} data={data} />
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={onCancel} width="8rem">Cancel</OutlineButton>
        <SubmitButton isLoading={methods.formState.isSubmitting} width="8rem">Save</SubmitButton>
      </HStack>
    </Stack>
  );

};
