import React from 'react';
import { Flex, Icon, Stack, Text } from '@chakra-ui/react';
import HighHazardIcon from '../FigmaExport/HighHazardIcon';


export const HighHazardBanner = ({ highHazardDescription }) => {

  return (
    <Stack
      py="4px"
      px="8px"
      gap="4px"
      borderRadius="8px"
      textColor="black"
      backgroundColor="#FF42141A"
    >
      <Flex gap="4px" alignItems="center">
        <Text fontWeight={600} fontSize="18px">High Hazard</Text>
        <Icon as={HighHazardIcon} />
      </Flex>
      {highHazardDescription && (
        <Text fontWeight={400} fontSize="12px">{highHazardDescription}</Text>
      )}
    </Stack>
  );
};
