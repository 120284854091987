import config from '../../config.js';
import * as AJAXUtil from './AJAXUtil';
import { addDispatchMessage } from '../features/dispatch/dispatchSlice';
import { store } from '../index';

// WIP
export function dispatchEventSource(dispatch, customerId) {
  const state = store.getState();
  const jwt = state && state.session && state.session.jwt;

  const source = new EventSource(
    `${config.API_BASE_URL}/eventsource?CustomerID=${customerId}&jwt=${encodeURIComponent(jwt)}`
  );
  source.onmessage = function(event) {
    if (event.id === 'CLOSE') {
      source.close();
    } else {
      dispatch(addDispatchMessage(event.data));
    }
  };
  source.onerror = function(event) {
  };
  source.onopen = function(event) {
  };
  // source.addEventListener('backlog', function(event) {
  //   const data = JSON.parse(event.data);
  //   console.log('backlog', data);
  //   // dispatch(addDispatchMessage({id: data.sequence, ...data}));
  //   // if (typeof data.map === 'function') {
  //   //   data.map(m => );
  //   // }
  // });
  return source;
}

export function fetchDispatchMessages(slug) {
  // @todo incorporate user preference for dispatch history
  const dispatchLimit = 200;
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/${slug}/dispatchfeed?limit=${dispatchLimit}`
  });
}

export function fetchDispatchUnitList(slug) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/${slug}/psap/units`
  });
}

export function setDispatchUnits(unitIds, slug) {
  const payload = {
    unitIds
  };
  return AJAXUtil.AJAX({
    method: 'POST',
    url: `${config.API_BASE_URL}/api/${slug}/psap/units`,
    data: payload
  });
}
