import * as React from "react";


const EliteVPPFlowMSPLogo = (props) => (
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1679 231.74" height={48} width={130.496} {...props} >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path d="M78.87,108.08v12.37H0V11.55h76.39v12.37H13.2v35.14h49.17v12.37H13.2v36.63h65.67v.02Z"/>
        <path d="M115.33,98.35c0,8.41,2.14,11.05,8.91,11.05,4.45,0,7.26-.49,12.04-2.31l-2.14,12.7c-3.46,1.49-7.92,2.31-12.21,2.31-13.36,0-19.8-7.09-19.8-22.11V0h13.2v98.34h0Z"/>
        <path d="M149.98,12.22c0-5.94,3.96-9.9,9.9-9.9s9.9,3.96,9.9,9.9-3.96,9.9-9.9,9.9-9.9-3.96-9.9-9.9ZM166.48,120.46h-13.2V37.96h13.2v82.5Z"/>
        <path d="M247.66,114.68c-5.11,4.62-12.21,7.42-21.28,7.42-12.21,0-22.77-6.1-22.93-22.11v-50.65h-16.5v-11.38h16.5v-19.8l13.2-3.63v23.43h30.52v11.38h-30.52v48.34c0,8.08,5.12,12.04,12.38,12.04,5.94,0,10.23-2.31,14.02-6.1l4.62,11.05h0Z"/>
        <path d="M336.43,84.16h-62.2c1.65,16.66,11.71,26.4,26.89,26.4,12.04,0,20.29-4.29,23.26-12.21l11.05,5.77c-6.27,11.22-16.5,17.98-34.32,17.98-24.91,0-39.93-16.17-39.93-42.9s14.02-42.9,38.94-42.9,36.96,17.32,36.96,38.61c0,3.79-.33,6.93-.66,9.24h.01ZM274.39,72.44h49.99c-1.81-15.18-10.23-24.58-24.25-24.58s-23.76,9.07-25.74,24.58Z"/>
      </g>
      <path d="M463.8,120.46h-15.01l-41.41-108.9h14.19l27.72,75.24,6.93,21.61,6.93-21.45,27.72-75.4h14.19l-41.25,108.9h0Z"/>
      <path d="M521.71,12.22c0-5.94,3.96-9.9,9.9-9.9s9.9,3.96,9.9,9.9-3.96,9.9-9.9,9.9-9.9-3.96-9.9-9.9ZM538.21,120.46h-13.2V37.96h13.2v82.5Z"/>
      <path d="M561.48,103.46l10.72-7.42c3.96,10.06,14.19,14.52,26.07,14.52,13.53,0,19.96-5.44,19.96-12.21,0-6.1-5.12-9.73-21.61-12.7-23.43-4.29-32.01-10.72-32.01-24.58s12.37-24.75,32.5-24.75c17.65,0,28.05,6.27,32.67,19.8l-11.22,6.6c-3.63-10.89-11.05-14.85-21.45-14.85-11.05,0-19.3,5.94-19.3,12.04,0,7.09,5.45,10.72,22.27,13.53,23.1,3.79,31.35,10.72,31.35,24.25,0,14.35-13.36,24.42-34.48,24.42-15.18,0-29.2-5.94-35.47-18.64h0Z"/>
      <path d="M727.3,37.96v82.5h-11.55l-.83-12.54c-5.77,9.9-16.33,14.19-29.53,14.19-16.33,0-30.36-8.41-30.36-30.19v-53.95h13.2v50.49c0,16.99,7.59,22.11,19.47,22.11,14.02,0,26.4-8.25,26.4-27.72v-44.88h13.2Z"/>
      <path d="M832.24,110.06l-.83,10.39c-2.31.83-5.78,1.65-9.9,1.65-8.08,0-13.37-2.97-14.85-12.21-5.28,7.75-16,12.21-26.56,12.21-15.51,0-28.05-7.26-28.05-22.11,0-12.37,10.89-22.11,32.17-25.74l21.94-3.63v-4.62c0-10.89-6.93-18.15-19.3-18.15-10.89,0-19.14,5.28-23.59,15.67l-9.73-7.09c4.29-12.21,17.32-20.13,33.33-20.13,18.81,0,32.5,8.91,32.5,28.05v40.59c0,3.96,1.65,5.94,6.27,5.94,2.15,0,4.62-.33,6.6-.82h0ZM806.17,92.74v-11.38l-18.48,3.46c-14.52,2.64-21.94,7.42-21.94,15.18,0,6.93,5.61,10.56,15.01,10.56,13.2,0,25.41-6.93,25.41-17.82h0Z"/>
      <path d="M863.92,98.35c0,8.41,2.14,11.05,8.91,11.05,4.45,0,7.26-.49,12.04-2.31l-2.14,12.7c-3.46,1.49-7.92,2.31-12.21,2.31-13.36,0-19.8-7.09-19.8-22.11V0h13.2v98.34h0Z"/>
      <path d="M1029.91,44.72c0,20.46-14.68,33.16-36.63,33.16h-25.24v42.57h-13.2V11.55h38.44c21.94,0,36.63,12.7,36.63,33.16h0ZM1016.05,44.72c0-13.53-7.59-20.79-24.75-20.79h-23.26v41.58h23.26c17.16,0,24.75-7.26,24.75-20.79h0Z"/>
      <path d="M1101.02,37.96l-2.64,12.87c-2.48-1.32-6.11-2.15-10.39-2.15-9.9,0-21.78,8.74-21.78,27.22v44.55h-13.2V37.95h10.72l1.81,13.53c4.62-10.06,13.53-15.18,26.4-15.18,2.8,0,6.6.33,9.07,1.65h0Z"/>
      <path d="M1187.81,84.16h-62.2c1.65,16.66,11.71,26.4,26.89,26.4,12.04,0,20.29-4.29,23.26-12.21l11.05,5.77c-6.27,11.22-16.5,17.98-34.32,17.98-24.91,0-39.93-16.17-39.93-42.9s14.02-42.9,38.94-42.9,36.96,17.32,36.96,38.61c0,3.79-.33,6.93-.66,9.24h.01ZM1125.77,72.44h49.99c-1.81-15.18-10.23-24.58-24.25-24.58s-23.76,9.07-25.74,24.58Z"/>
      <path d="M1347.36,44.72c0,20.46-14.68,33.16-36.63,33.16h-25.24v42.57h-13.2V11.55h38.44c21.94,0,36.63,12.7,36.63,33.16h0ZM1333.5,44.72c0-13.53-7.59-20.79-24.75-20.79h-23.26v41.58h23.26c17.16,0,24.75-7.26,24.75-20.79h0Z"/>
      <path d="M1383,98.35c0,8.41,2.14,11.05,8.91,11.05,4.45,0,7.26-.49,12.04-2.31l-2.14,12.7c-3.46,1.49-7.92,2.31-12.21,2.31-13.36,0-19.8-7.09-19.8-22.11V0h13.2v98.34h0Z"/>
      <path d="M1495.2,110.06l-.83,10.39c-2.31.83-5.78,1.65-9.9,1.65-8.08,0-13.37-2.97-14.85-12.21-5.28,7.75-16,12.21-26.56,12.21-15.51,0-28.05-7.26-28.05-22.11,0-12.37,10.89-22.11,32.17-25.74l21.94-3.63v-4.62c0-10.89-6.93-18.15-19.3-18.15-10.89,0-19.14,5.28-23.59,15.67l-9.73-7.09c4.29-12.21,17.32-20.13,33.33-20.13,18.81,0,32.5,8.91,32.5,28.05v40.59c0,3.96,1.65,5.94,6.27,5.94,2.15,0,4.62-.33,6.6-.82h0ZM1469.13,92.74v-11.38l-18.48,3.46c-14.52,2.64-21.94,7.42-21.94,15.18,0,6.93,5.61,10.56,15.01,10.56,13.2,0,25.41-6.93,25.41-17.82Z"/>
      <path d="M1586.61,67.66v52.8h-13.2v-48.18c0-18.31-8.91-24.42-20.29-24.42-12.04,0-25.57,7.59-25.57,28.71v43.89h-13.2V37.96h11.55l.83,12.87c5.77-10.4,16.33-14.52,27.72-14.52,17.65,0,32.17,10.06,32.17,31.35h-.01Z"/>
      <path d="M1609.05,103.46l10.72-7.42c3.96,10.06,14.19,14.52,26.07,14.52,13.53,0,19.96-5.44,19.96-12.21,0-6.1-5.12-9.73-21.61-12.7-23.43-4.29-32.01-10.72-32.01-24.58s12.37-24.75,32.5-24.75c17.65,0,28.05,6.27,32.67,19.8l-11.22,6.6c-3.63-10.89-11.05-14.85-21.45-14.85-11.05,0-19.3,5.94-19.3,12.04,0,7.09,5.45,10.72,22.27,13.53,23.1,3.79,31.35,10.72,31.35,24.25,0,14.35-13.36,24.42-34.48,24.42-15.18,0-29.2-5.94-35.47-18.64h0Z"/>
      <g>
        <path d="M1354.94,182.45v20.14h25.04v3.71h-25.04v24.65h-3.95v-52.14h35.55v3.63h-31.6Z"/>
        <path d="M1399.58,221.39c0,4.82,1.42,6.64,4.98,6.64,2.21,0,3.4-.32,5.21-1.11l-.55,3.79c-1.34.63-3.16,1.03-5.21,1.03-5.61,0-8.37-3.08-8.37-9.95v-48.5h3.95v48.11Z"/>
        <path d="M1452.35,211.2c0,12.8-7.11,20.54-18.8,20.54s-18.8-7.74-18.8-20.54,7.11-20.54,18.8-20.54,18.8,7.74,18.8,20.54ZM1418.7,211.2c0,10.67,5.61,17.14,14.85,17.14s14.85-6.48,14.85-17.14-5.61-17.14-14.85-17.14-14.85,6.48-14.85,17.14Z"/>
        <path d="M1518,191.45l-13.51,39.5h-3.95l-12.48-34.52-12.17,34.52h-3.95l-13.51-39.5h4.42l11.06,34.92,12.32-34.92h3.95l12.32,34.92,11.06-34.92h4.42Z"/>
        <path d="M1577.32,230.95h-3.79v-38.87l.47-9.48h-.08l-19.04,48.34h-3.63l-19.04-48.34h-.08l.47,9.48v38.87h-3.79v-52.14h5.92l15.72,39.5,2.61,7.98h.08l2.53-7.9,15.72-39.58h5.92v52.14Z"/>
        <path d="M1629.54,189.56l-3.4,2.76c-2.69-7.5-7.66-10.66-16.19-10.66-9.4,0-14.93,4.74-14.93,10.82,0,5.77,3.87,8.69,16.51,10.82,13.75,2.37,18.72,6.48,18.72,14.22,0,8.45-7.9,14.22-19.59,14.22-10.98,0-18.8-4.58-21.57-12.48l3.48-2.69c2.13,7.5,8.85,11.53,18.17,11.53s15.4-3.71,15.4-10.35c0-5.45-3.95-8.53-15.72-10.58-14.61-2.45-19.59-6.48-19.59-14.3s7.19-14.85,19.04-14.85c9.72,0,16.35,3.79,19.67,11.53Z"/>
        <path d="M1677.65,194.06c0,9.56-6.87,15.33-17.69,15.33h-13.35v21.57h-3.95v-52.14h17.3c10.82,0,17.69,5.77,17.69,15.25ZM1673.47,194.06c0-7.35-4.82-11.61-13.98-11.61h-12.88v23.22h12.88c9.16,0,13.98-4.19,13.98-11.61Z"/>
      </g>
    </g>
  </svg>
);

export default EliteVPPFlowMSPLogo;
