import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as structureAPI from '../../api/StructureAPI';
import { formatLocationDate, isAfterSubDays, isBeforeSubDays } from '../../helpers/Date';


// Thunks
export const getAllStructures = createAsyncThunk(
  'structures/getAllStructures',
  async(data, thunkAPI) => {
    const response = await structureAPI.getAllStructures();
    return response.data;
  }
);

export const getStructureById = createAsyncThunk(
  'structures/getStructureById',
  async(data, thunkAPI) => {
    const response = await structureAPI.getStructureById(data);
    return response.data;
  }
);

export const addStructure = createAsyncThunk(
  'structures/addStructure',
  async(data, thunkAPI) => {
    const response = await structureAPI.addStructure(data);
    return response.data;
  }
);

export const editStructure = createAsyncThunk(
  'structures/editStructure',
  async(data, thunkAPI) => {
    const response = await structureAPI.editStructure(data.structureId, data.structurePatch);
    return response.data;
  }
);

export const deleteStructure = createAsyncThunk(
  'structures/deleteStructure',
  async(data, thunkAPI) => {
    const response = await structureAPI.deleteStructure(data);
    return { ...response.data, id: data };
  }
);

export const addImageToStructure = createAsyncThunk(
  'structures/addImageToStructure',
  async(data, thunkAPI) => {
    const response = await structureAPI.addImageToStructure(data.structureId, data.imageId);
    return response.data;
  }
);

export const removeImageFromStructure = createAsyncThunk(
  'structures/removeImageFromStructure',
  async(data, thunkAPI) => {
    const response = await structureAPI.removeImageFromStructure(data.structureId, data.imageId);
    return response.data;
  }
);

export const fetchPartnerStructures = createAsyncThunk(
  'structures/fetchPartnerStructures',
  async(partnerId, thunkAPI) => {
    const response = await structureAPI.fetchPartnerStructures(partnerId);
    return response.data;
  }
);


// Adapter & State
export const structuresAdapter = createEntityAdapter();
const initialState = structuresAdapter.getInitialState({
  loading: 'idle',
  error: null,
  lastFetch: null,
  selectedStructureId: null,
  selectedStructures: null,
  filteredStructureIds: [],
  partnerLoading: 'idle',
  lastPartnerFetch: null,
  structureToEdit: null,
});

export const structuresSlice = createSlice({
  name: 'structures',
  initialState,
  reducers: {
    // structuresLoading: (state, action) => {
    //   if (state.loading === 'idle') {
    //     state.loading = 'pending';
    //   }
    // },
    // structuresReceived: (state, action) => {
    //   if (state.loading === 'pending') {
    //     state.loading = 'idle';
    //     state.entities = action.payload;
    //   }
    // },
    setSelectedStructureId: (state, action) => {
      state.selectedStructureId = action.payload;
    },
    setSelectedStructures: (state, action) => {
      state.selectedStructures = action.payload;
    },
    setStructureFilters: (state, action) => {
      const structures = structuresAdapter.getSelectors().selectAll(state);
      state.filteredStructureIds = getFilteredStructures(structures, action.payload);
    },
    setStructureToEdit: (state, action) => {
      state.structureToEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getAllStructures
    builder
      .addCase(getAllStructures.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(getAllStructures.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.lastFetch = Date.now();
        if (action.payload && action.payload.length > 0) {
          structuresAdapter.setAll(state, action.payload.map(structure => ({ ...structure, isMine: true })));
        }
      })
      .addCase(getAllStructures.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.lastFetch = Date.now();
        }
      });
    // getStructureById
    builder
      .addCase(getStructureById.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(getStructureById.fulfilled, (state, action) => {
        state.loading = 'idle';
        structuresAdapter.setOne(state, { ...action.payload, isMine: true });
      })
      .addCase(getStructureById.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // addStructure
    builder
      .addCase(addStructure.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(addStructure.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          structuresAdapter.addOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(addStructure.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // editStructure
    builder
      .addCase(editStructure.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(editStructure.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          structuresAdapter.setOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(editStructure.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // deleteStructure
    builder
      .addCase(deleteStructure.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(deleteStructure.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          structuresAdapter.removeOne(state, action.payload.id);
        }
      })
      .addCase(deleteStructure.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // addImageToStructure
    builder
      .addCase(addImageToStructure.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(addImageToStructure.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          structuresAdapter.setOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(addImageToStructure.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // removeImageFromStructure
    builder
      .addCase(removeImageFromStructure.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(removeImageFromStructure.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          structuresAdapter.setOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(removeImageFromStructure.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // fetchPartnerStructures
    builder
      .addCase(fetchPartnerStructures.pending, (state, action) => {
        if (state.partnerLoading === 'idle') {
          state.partnerLoading = 'pending';
        }
      })
      .addCase(fetchPartnerStructures.fulfilled, (state, action) => {
          state.partnerLoading = 'idle';
        if (action.payload.data) {
          structuresAdapter.upsertMany(state, action.payload.data.map(structure => ({ ...structure, isMine: false })));
          state.lastPartnerFetch = Date.now();
        }
      })
      .addCase(fetchPartnerStructures.rejected, (state, action) => {
        if (
          state.partnerLoading === 'pending'
        ) {
          state.partnerLoading = 'idle';
          state.error = action.error;
        }
      });
  }
});

// Selector?
const getFilteredStructures = (structures, filters) => {
  // @todo refactor: this is from previous reducer
  const locationsFilterData = filters;
  const locationFilterApplied = [];
  let prevStructuresArr = structures;
  for (let knt1 = 0; knt1 < locationsFilterData.length; knt1++) {
    const eachFieldVal = locationsFilterData[knt1].value;
    const eachFieldName = locationsFilterData[knt1].field;
    if (eachFieldName === 'excludePartner') {
      prevStructuresArr = prevStructuresArr.filter(x => x.isMine);
      locationFilterApplied.push(' Exclude partner');
    } else if (eachFieldName === 'selectBuildingInfo') {
      if (eachFieldVal === 'Present') {
        locationFilterApplied.push(`Building info ${eachFieldVal}`);
        prevStructuresArr = prevStructuresArr.filter(x => (x.building && (x.building.occupancyType || x.building.constructionType || x.building.roofType || x.building.roofConstruction || x.building.roofMaterial || x.building.sprinklered || x.building.standPipe || x.building.fireAlarm || x.building.normalPopulation || x.building.hoursOfOperation || x.building.ownerContact || x.building.ownerPhone)));
      } else if (eachFieldVal === 'Not present') {
        prevStructuresArr = prevStructuresArr.filter(x => (!x.building || (!x.building.occupancyType && !x.building.constructionType && !x.building.roofType && !x.building.roofConstruction && !x.building.roofMaterial && !x.building.sprinklered && !x.building.standPipe && !x.building.fireAlarm && !x.building.normalPopulation && !x.building.hoursOfOperation && !x.building.ownerContact && !x.building.ownerPhone)));
        locationFilterApplied.push(`Building info ${eachFieldVal}`);
      }
    } else if (eachFieldName === 'commercial') {
      prevStructuresArr = prevStructuresArr.filter(x => x.building && ((x.building.occupancyType === 'Business / Mercantile') || (x.building.occupancyType === 'Industrial')));
      locationFilterApplied.push(' Commercial');
    } else if (eachFieldName === 'vacant') {
      prevStructuresArr = prevStructuresArr.filter(x => x.building && (x.building.normalPopulation === 'Vacant'));
      locationFilterApplied.push(' Vacant');
    } else if (eachFieldName === 'sprinklered') {
      prevStructuresArr = prevStructuresArr.filter(x => x.building && ((x.building.sprinklered === 'Dry System') || (x.building.sprinklered === 'Wet System') || (x.building.sprinklered === 'Both')));
      locationFilterApplied.push(' Sprinklered');
    } else if (eachFieldName === 'nonSprinklered') {
      prevStructuresArr = prevStructuresArr.filter(x => x.building && ((!x.building.sprinklered) || (x.building.sprinklered && ((x.building.sprinklered === '') || (x.building.sprinklered === 'None')))));
      locationFilterApplied.push(' Non sprinklered');
    } else if (eachFieldName === 'withPictures') {
      prevStructuresArr = prevStructuresArr.filter(x => x.images?.length > 0);
      locationFilterApplied.push(' With pictures');
    } else if (eachFieldName === 'withoutPictures') {
      prevStructuresArr = prevStructuresArr.filter(x => !x.images?.length);
      locationFilterApplied.push(' Without pictures');
    } else if (eachFieldName === 'trussRoof') {
      prevStructuresArr = prevStructuresArr.filter(x => x.building && (x.building.roofType === 'Bowstring Truss' || x.building.roofConstruction === 'Steel Truss - Open Web' || x.building.roofConstruction === 'Wood Truss - Closed Web' || x.building.roofConstruction === 'Wood Truss - Open Web'));
      locationFilterApplied.push(' Truss Roof');
    } else if (eachFieldName === 'standpipes') {
      prevStructuresArr = prevStructuresArr.filter(x => x.building && x.building.standPipe === 'Yes');
      locationFilterApplied.push(' Standpipes');
    } else if (eachFieldName === 'fireAlarm') {
      prevStructuresArr = prevStructuresArr.filter(x => x.building && x.building.fireAlarm === 'Yes');
      locationFilterApplied.push(' Fire Alarms');
    } else if (eachFieldName === 'multiFamily') {
      prevStructuresArr = prevStructuresArr.filter(x => x.building && x.building.occupancyType === 'Multi-Family');
      locationFilterApplied.push(' Multi-family');
    } else if (eachFieldName === 'special') {
      prevStructuresArr = prevStructuresArr.filter(x => x.building && x.building.occupancyType === 'Special Structures');
      locationFilterApplied.push(' Special Structures');
    } else if (eachFieldName === 'selectPreplanAge') {

      if (eachFieldVal === 'withinThirtyDays') {
        prevStructuresArr = prevStructuresArr.filter(x => x.building && isAfterSubDays(formatLocationDate(x.building.originalPrePlan), 30));
        locationFilterApplied.push(' Pre-plan within last 30 days');
      } else if (eachFieldVal === 'olderThanAYear') {
        prevStructuresArr = prevStructuresArr.filter(x => x.building && isBeforeSubDays(formatLocationDate(x.building.originalPrePlan), 365));
        locationFilterApplied.push(' Pre-plan older than a year');
      }
    } else if (eachFieldName === 'roofAreaMin') {
      prevStructuresArr = prevStructuresArr.filter(x => x.roofArea >= eachFieldVal);
      locationFilterApplied.push(` Area minimum  ${eachFieldVal}`);
    } else if (eachFieldName === 'roofAreaMax') {
      prevStructuresArr = prevStructuresArr.filter(x => x.roofArea <= eachFieldVal);
      locationFilterApplied.push(` Area maximum ${eachFieldVal}`);
    }
  }
  const filteredIds = prevStructuresArr.map(x => x.id);
  return filteredIds;
};

const { actions, reducer } = structuresSlice;

export const {
  setSelectedStructureId,
  setSelectedStructures,
  setStructureFilters,
  setStructureToEdit,
} = actions;

export default reducer;


export const {
  selectById: selectStructureById,
  selectAll: selectAllStructures,
  selectTotal: selectTotalStructures,
} = structuresAdapter.getSelectors(state => state.structures);
