import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Flex, Radio, RadioGroup, Spacer, Stack, Text } from '@chakra-ui/react';


export const ImageFilterForm = ({
  onApply, 
  onCancel,
  tags,
  defaultValue,
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Box pb={4}>
      <Text mb={2}>Tag</Text>
      <Stack spacing={2}>
        <RadioGroup onChange={setValue} value={value}>
          <Stack>
            {tags.map((tag, index) => (
              <Radio key={index} value={tag.value}>{tag.label} ({tag.count})</Radio>
            ))}
          </Stack>
        </RadioGroup>
        <Divider mb="2" />
        <Flex w="100%">
          <Button
            width="8rem"
            bg={'white'}
            color={'#2C62CB'}
            borderColor={'#2C62CB'}
            borderWidth={2}
            _hover={{
              bg: 'white',
            }}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Spacer />
          <Button
            width="8rem"
            bg={'#2C62CB'}
            color={'white'}
            _hover={{
              bg: '#2C62CB',
            }}
            onClick={() => onApply(value)}
          >
            Apply
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
};
