// import './wdyr'; // why-did-you-render debug tool
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Index as V4Index } from './v4/index.js';
import { Index as V5Index } from './v5/index.js';
import { Index as LoginIndex } from './login/index.js';
import reportWebVitals from './reportWebVitals';

// Import this now, so it's cached for the duration of the app
// noinspection ES6UnusedImports
import config from './config.js'; // eslint-disable-line no-unused-vars


const tryParse = (item) => {
    var result = null;
    try {
        result = JSON.parse(item);
    } catch (e) {
        // console.error(e);
    }
    return result;
};
  
const defaultState = {
    isLoggedIn: !!sessionStorage.jwt,
    customerId: null,
    jwt: sessionStorage.jwt || null,
    customerLink: tryParse(sessionStorage.customer),
    userLink: tryParse(sessionStorage.user),
    tokenType: sessionStorage.tokenType || null,
    customerIDLink: tryParse(sessionStorage.customerID),
    dataFormat: sessionStorage.dataFormat || 'OLD'
};

const AppLoader = () => {
    const [session, setSession] = React.useState(defaultState);

    if (session && session.isLoggedIn) {
        if (session && session.dataFormat && session.dataFormat === 'NEW') {
            return (
                <V5Index session={session} />
            );
        } else { // 'OLD'
            return (
                <V4Index session={session} />
            );
        }
    } else {
        return (
            <LoginIndex setSession={setSession} />
        );
    }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppLoader />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
