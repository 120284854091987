import { Box, Center, Divider, Flex, Heading, Link, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import ErrorComponent from "../components/common/ErrorComponent";
import { FlexWithBackgroundTheme } from './ThemeContainer';

export function NotFoundContainer() {
  const errorMessage = "The page you are looking for is unavailable.";
  return (
    <FlexWithBackgroundTheme overflow="auto" w="100%" flexDirection="column" paddingBottom="1.5rem" paddingX={"1.5rem"}>
      <Box
        marginTop="2rem"
        maxWidth="32rem"
        minWidth="24rem"
        bg={useColorModeValue("white", "gray.900")}
        p="1rem"
        borderWidth="1px"
        borderRadius="0.5rem"
        boxShadow="md"
      >
        <Flex align="center" justify="space-between">
          <Heading as="h4" size="md">
            Not Found
          </Heading>
        </Flex>
        <Divider my="0.5rem" />
        <Center>
          <ErrorComponent title={errorMessage}>
            Go to the <Link as={RouterLink} to="/">home page</Link> to try again.
          </ErrorComponent>
        </Center>
      </Box>
    </FlexWithBackgroundTheme>
  );
}
