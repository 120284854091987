import React, { useState } from 'react';
import {
  Button,
  useToast
} from '@chakra-ui/react';


export const CSVDownloadButton = ({ children, execute, onComplete, filename }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const toast = useToast();

  return (
      <Button variant="ghost"
              style={{
                fontWeight: 'inherit',
                color: 'inherit',
                flexGrow: 1,
                justifyContent: 'start',
                borderRadius: 0
              }}
              as={'a'}
              isLoading={isLoading}
              loadingText="Downloading"
              onClick={(e) => {
                setIsLoading(true);
                execute().then(res => {

                  if (!res || !res.data) {
                    toast({
                      title: 'Error',
                      position: 'top',
                      description: 'There was a problem downloading the file, please try again.',
                      status: 'error',
                      duration: 5000,
                      isClosable: true
                    });
                    setIsLoading(false);
                    onComplete();
                    return;
                  }

                  const blob = new window.Blob([res.data], {
                    type: 'text/csv'
                  });
                  const file = URL.createObjectURL(blob);

                  const a = document.createElement('a');
                  a.href = file;
                  a.download = filename;
                  e.target.parentNode.appendChild(a);
                  a.click();
                  a.remove();
                  onComplete();
                  setIsLoading(false);
                }, err => {
                  console.error('Failed to download file', err); // eslint-disable-line no-console
                  toast({
                    title: 'Error',
                    position: 'top',
                    description: 'There was a problem downloading the file, please try again.',
                    status: 'error',
                    duration: 2500,
                    isClosable: true
                  });
                });
                
              }}
      >
        {children || 'Download CSV'}
      </Button>
  );

};
