import React from 'react';
import { configureStore, history } from './store/configureStore';
import Root from './containers/Root';
import ErrorBoundary from './containers/ErrorBoundary';
import './styles/app.scss';
import {ColorModeScript} from '@chakra-ui/react';
import theme from './styles/theme/default';
import { pdfjs } from 'react-pdf';


// Config PDF worker
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

export const store = configureStore();


export const Index = ({ session }) => {
    return (
        <ErrorBoundary>
            <ColorModeScript storageKey="fmsp-cm" initialColorMode={theme.config.initialColorMode} />
            <Root store={store} history={history} session={session} />
        </ErrorBoundary>
    );
};