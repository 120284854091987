import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  IconButton,
  Text,
  HStack,
  Flex,
  Select,
  Spinner,
  Center,
  Divider,
} from '@chakra-ui/react';
import { FiArrowDown } from '@react-icons/all-files/fi/FiArrowDown';
import { FiArrowUp } from '@react-icons/all-files/fi/FiArrowUp';
import { FaAngleDoubleLeft } from '@react-icons/all-files/fa/FaAngleDoubleLeft';
import { FaAngleDoubleRight } from '@react-icons/all-files/fa/FaAngleDoubleRight';
import { FaAngleLeft } from '@react-icons/all-files/fa/FaAngleLeft';
import { FaAngleRight } from '@react-icons/all-files/fa/FaAngleRight';

import ErrorBoundary from '../../containers/ErrorBoundary';
import { useReactTable, getCoreRowModel, getFilteredRowModel, getExpandedRowModel, getPaginationRowModel, getSortedRowModel, flexRender } from 'react-table-v8';
import { OutlineButton } from '../form/Button';
import ExpandAllIcon from '../FigmaExport/ExpandAllIcon';
import CollapseAllIcon from '../FigmaExport/CollapseAllIcon';
import RestartIcon from '../FigmaExport/RestartIcon';
import { DebouncedInput } from '../common/DebouncedInput';


export function DataTable({
  data,
  columns,
  hiddenColumns = null,
  loading,
  globalSearch = false,
  selectable = false,
  Filters = null,
  activeFilters = null,
  chakraProps,
  initialSortBy = null,
  showExpandAllButton = false,
  onRowsSelected, // must be a consistent ref like useCallback or causes infinite render loop
  actionComponent,
  onResetFilters,
  customSizing = true,
  breakActionComponent = false,
}) {
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);

  useEffect(() => {
    if (hiddenColumns) {
      const newColumnVisiblity = {};
      for (const column of hiddenColumns) {
        newColumnVisiblity[column] = false;
      }
      setColumnVisibility(newColumnVisiblity);
    }
  }, [hiddenColumns, setColumnVisibility]);

  const realColumns = useMemo(() => {
    if (columns && selectable) {
      const selectColumnDefinition = {
        id: 'selection',
        size: 50,
        header: ({ table, header }) => !header.isPlaceholder && (
         <Box pl={4} pr={2} as="span">
           <IndeterminateCheckbox
             checked={table && table.getIsAllPageRowsSelected()}
             indeterminate={table && table.getIsSomePageRowsSelected()}
             onChange={table && table.getToggleAllPageRowsSelectedHandler()}
           />
         </Box>
        ),
        cell: ({ row }) => (
          <Box pl={4} pr={2}>
            <IndeterminateCheckbox
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          </Box>
        ),
      };
      return [selectColumnDefinition, ...columns];
    }
    return columns || [];
  }, [selectable, columns]);

  useEffect(() => {
    if (typeof onRowsSelected === 'function') {
      const rowIds = rowSelection ? Object.keys(rowSelection).filter(rowId => rowSelection[rowId]) : [];
      onRowsSelected(rowIds);
    }
  }, [rowSelection, onRowsSelected]);


  useEffect(() => {
    if (activeFilters) {
      const newColumnFilters = [];
      Object.keys(activeFilters).forEach(key => {
        if(activeFilters[key]) {
          newColumnFilters.push({ id: key, value: activeFilters[key] });
        }
      });
      setColumnFilters(newColumnFilters);
    }
  }, [activeFilters, setColumnFilters]);

  const [globalFilter, setGlobalFilter] = React.useState('');

  const table = useReactTable(
    {
      columns: realColumns,
      data,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      filterFromLeafRows: true, // search through the expanded rows
      getSubRows: (row) => row.subRows,
      getRowId: (originalRow) => originalRow.id, // setting this to get correct id of selected rows instead of index
      initialState: {
        sorting: initialSortBy || [],
      },
      state: {
        columnVisibility,
        rowSelection,
        columnFilters,
        globalFilter,
      },
      onColumnVisibilityChange: setColumnVisibility,
      onRowSelectionChange: setRowSelection,
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      defaultColumn: {
        size: 80,
      },
      paginateExpandedRows: false,
      enableSorting: true,
    },
  );

  return (
    <ErrorBoundary>
      <Box>
        {globalSearch && (
          <React.Fragment>
            <Flex direction={ breakActionComponent ? "column" : undefined } width="100%" gap="12px" alignItems="end" justifyContent="space-between">
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={value => setGlobalFilter(String(value))}
                placeholder={`Search ${table.getPreFilteredRowModel().rows.length} record${table.getPreFilteredRowModel().rows.length > 1 ? 's' : ''}`}
              />
              <Flex gap="12px">
                { Filters && (
                  <OutlineButton
                    fontSize="sm"
                    color="#2C62CB"
                    borderColor="#2C62CB"
                    leftIcon={<RestartIcon />}
                    onClick={() => {
                      table && table.setGlobalFilter('');
                      onResetFilters();
                    }}
                  >
                    Reset All Filters
                  </OutlineButton>
                )}
                { showExpandAllButton && (
                  <OutlineButton
                    fontSize="sm"
                    color="#2C62CB"
                    borderColor="#2C62CB"
                    leftIcon={table && table.getIsAllRowsExpanded() ? <CollapseAllIcon /> : <ExpandAllIcon />}
                    onClick={() => table && table.toggleAllRowsExpanded()}
                  >
                    { table && table.getIsAllRowsExpanded() ? "Collapse" : "Expand" } All Occupants
                  </OutlineButton>
                )}
                {actionComponent}
              </Flex>
            </Flex>
            { !breakActionComponent && <Divider mt="12px" mb="24px" /> }
          </React.Fragment>
        )}
        {Filters && (
          <React.Fragment>
            <Filters
              rows={table && table.getRowModel().rows}
              data={data}
              visibleColumns={table && table.getVisibleFlatColumns()}
              allColumns={table && table.getAllColumns()}
            />
          </React.Fragment>
        )}
        <Flex maxH='calc(100vh - 370px)' direction='column'>
          <Box mt="2px" overflowX="auto" overflowY="auto" onScroll={ e => e.stopPropagation() } style={{ scrollbarColor: '#2C62CB #EBEBEF', scrollbarWidth: 'thin' }}>
            <Table
              size="md"
              borderTopRadius="6px"
              borderWidth="1px"
              style={{
                borderCollapse: "separate",
                borderSpacing: 0,
                width: customSizing ? table.getTotalSize() : undefined
              }}
              {...chakraProps}
            >
              <Thead>
                {table && table.getHeaderGroups() && table.getHeaderGroups().map((headerGroup) => {
                  return (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                              <Th
                                  key={header.id}
                                  colSpan={header.colSpan}
                                  style={ customSizing ? { width: `${header.getSize()}px` } : {} }
                                  backgroundColor="#FAFAFA"
                                  // isNumeric={column.isNumeric}
                                  verticalAlign="bottom"
                                  borderTopRadius="6px"
                              >
                                <HStack onClick={ header.column.getToggleSortingHandler() } cursor={ header.column.getCanSort() ? 'pointer' : null }>
                                  <Text>{flexRender(header.column.columnDef.header, header.getContext())}</Text>
                                  {header.column.getIsSorted() ? (
                                      header.column.getIsSorted() === 'desc' ? (
                                          <FiArrowDown display="inline" aria-label="sorted descending"/>
                                      ) : (
                                          <FiArrowUp display="inline" aria-label="sorted ascending"/>
                                      )
                                  ) : null}
                                </HStack>
                              </Th>
                          );
                        })}
                      </Tr>
                  );
                })}
              </Thead>
              {loading ? (
                <Tbody>
                  <Tr>
                    <Td
                      textAlign="center"
                      bg="white"
                      colSpan="100%"
                      >
                      <TableLoader />
                    </Td>
                  </Tr>
                </Tbody>
              ) : (
                <Tbody>
                  {table && table.getRowModel().rows.length < 1 ? (
                    <Tr>
                      <Td
                      colSpan="100%"
                        textAlign="center"
                        bg="white"
                      >
                        <Box margin={8}>
                          <Text fontStyle="italic">No records found</Text>
                        </Box>
                      </Td>
                    </Tr>
                  ) : (
                    table && table.getRowModel().rows.map((row) => {
                      return (
                        <Tr key={`${row.original.type}-${row.id}`}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                                <Td
                                    key={cell.id}
                                    style={ customSizing ? { width: `${cell.column.getSize()}px` } : {} }
                                    borderWidth="1px"
                                    // isNumeric={cell.column.isNumeric}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Td>
                            );
                          })}
                        </Tr>
                      );
                    }))}
                </Tbody>)}
            </Table>
          </Box>
          <Flex mt="24px">
            <Box mr="auto">
              <Select
                placeholder=""
                variant="unstyled"
                color="#2C62CB"
                value={table && table.getState().pagination.pageSize}
                onChange={(e) => {
                  table && table.setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={`pagesize-option-${pageSize}`} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </Box>
            <HStack>
              <HStack>
                <IconButton
                  aria-label="Go to Page 1"
                  backgroundColor="transparent"
                  icon={<FaAngleDoubleLeft fill="#2C62CB" />}
                  onClick={() => table && table.firstPage()}
                  isDisabled={table && !table.getCanPreviousPage()}
                />
                <IconButton
                  aria-label="Previous Page"
                  backgroundColor="transparent"
                  icon={<FaAngleLeft fill="#2C62CB" />}
                  onClick={() => table && table.previousPage()}
                  isDisabled={table && !table.getCanPreviousPage()}
                />
              </HStack>
              <Box>
                <Text>
                  Page <strong>{table && table.getState().pagination.pageIndex + 1}</strong> of <strong>{table && table.getPageCount()}</strong>
                </Text>
              </Box>
              <HStack>
                <IconButton
                  aria-label="Next page"
                  backgroundColor="transparent"
                  icon={<FaAngleRight fill="#2C62CB" />}
                  onClick={() => table && table.nextPage()}
                  isDisabled={table && !table.getCanNextPage()}
                />
                <IconButton
                  aria-label="Go to Last Page"
                  backgroundColor="transparent"
                  icon={<FaAngleDoubleRight fill="#2C62CB" />}
                  onClick={() => table && table.lastPage()}
                  isDisabled={table && !table.getCanNextPage()}
                />
              </HStack>
            </HStack>
          </Flex>
        </Flex>
      </Box>
    </ErrorBoundary>
  );
}

const TableLoader = () => {

  return (
    <Center>
      <Spinner
        thickness=".25rem"
        speed=".5s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  );
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input type="checkbox" ref={resolvedRef} {...rest} />
    );
  }
);
