import { Menu, MenuList, MenuItem, Tooltip } from '@chakra-ui/react';
import React from 'react';


class ContextMenu extends React.Component {

  render() {
    const contextMenu = (this.props.role === 'ADMIN' || this.props.role === 'PLANNER') ? this.props.contextMenu : null;
    const entity = contextMenu && contextMenu.entity;
    const readOnly = entity?.structure?.readOnly;
    const reason = "Editing is disabled. Visit Elite to change.";
    const style = {
      display: entity ? 'block' : 'none',
      position: 'absolute',
      left: entity ? entity.x : 0,
      top: entity ? entity.y : 0
    };
    return (
      <div style={style} className="context-menu-container" onContextMenu={e => e.preventDefault()}>
        <Menu isOpen={contextMenu}>
          <MenuList minWidth="">
            {contextMenu ? (contextMenu.items.map((menuItem, index) => (
              <Tooltip key={index} hasArrow isDisabled={!readOnly} placement="right" label={reason}>
                <MenuItem
                  icon={menuItem.icon}
                  textColor={menuItem.color}
                  fontFamily="Inter"
                  fontWeight="600"
                  fontSize="sm"
                  key={index}
                  onClick={e => this.props.handleContextMenuItemSelect(menuItem, entity)}
                  isDisabled={readOnly}
                >
                  {menuItem.label}
                </MenuItem>
              </Tooltip>
            ))) : null}
          </MenuList>
        </Menu>
      </div>
    );
  }

}

export default ContextMenu;
