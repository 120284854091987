import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import * as userAPI from '../../api/UserAPI';

import { allColumnIds, hiddenColumnIds } from '../../containers/dashboard/PrePlanTableColumns';

// Setting up according to redux-toolkit best practices
// Reference: https://redux-toolkit.js.org/usage/usage-guide

// interface ThunkAPI {
//   dispatch: Function
//   getState: Function
//   extra?: any
//   requestId: string
//   signal: AbortSignal
// }
// Thunks
export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async(/*thunkAPI*/) => {
    const response = await userAPI.fetchUser();
    return response.data;
  }
);

export const updateUserConfig = createAsyncThunk(
  'user/updateUserConfig',
  async(userConfig, /*thunkAPI*/) => {
    const response = await userAPI.updateConfig(userConfig);
    return response.data;
  }
);

export const patchUserConfig = createAsyncThunk(
  'user/patchUserConfig',
  async(userConfig) => {
    for (const key in userConfig) {
      if (userConfig[key] && typeof(userConfig[key]) === 'object') {
        userConfig[key] = JSON.stringify(userConfig[key]);
      }
    }

    const response = await userAPI.updateConfig(userConfig);
    return response.data;
  }
);

const syncDashboardFilterValues = createAsyncThunk(
  'user/syncDashboardFilterValues',
  async(_, { dispatch, getState }) => {
    await dispatch(patchUserConfig({ dashboardFilterValues: { ...getState().user.uiConfig.dashboardFilterValues }}));
  }
);

const syncDashboardColumnsVisibility = createAsyncThunk(
  'user/syncDashboardColumnsVisibility',
  async(_, { dispatch, getState }) => {
    await dispatch(patchUserConfig({ dashboardColumnsVisibility: { ...getState().user.uiConfig.dashboardColumnsVisibility }}));
  }
);

const syncFiltersAndColumns = createAsyncThunk(
  'user/syncFiltersAndColumns',
  async(_, { dispatch, getState }) => {
    await dispatch(patchUserConfig({
      dashboardFilterValues: { ...getState().user.uiConfig.dashboardFilterValues },
      dashboardColumnsVisibility: { ...getState().user.uiConfig.dashboardColumnsVisibility }
    }));
  }
);

export const debouncedSyncDashboardFilterValues = debounce((dispatch) => dispatch(syncDashboardFilterValues()), 1000);
export const debouncedSyncDashboardColumnsVisibility = debounce((dispatch) => dispatch(syncDashboardColumnsVisibility()), 1000);
export const debouncedSyncFiltersAndColumns = debounce((dispatch) => dispatch(syncFiltersAndColumns()), 1000);

export const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async(userProfile, /*thunkAPI*/) => {
    const response = await userAPI.updateProfile(userProfile);
    return response.data;
  }
);

export const updateUserPassword = createAsyncThunk(
  'user/updateUserPassword',
  async(form, /*thunkAPI*/) => {
    const response = await userAPI.updatePassword(form);
    return response.data;
  }
);

// @todo set intial state to move uiConfig from customer to user...

/**
 * mapTypeId: customerConfig.mapTypeId,
    center: customerConfig.center,
    bounds: null,
    zoom: customerConfig.zoom
 */

const initialState = () => {

  const dashboardColumnsVisibility = {};
  allColumnIds.forEach(c => {
    dashboardColumnsVisibility[c] = !hiddenColumnIds.includes(c);
  });

  const localConfig = {
    mapTypeId: localStorage.getItem('mapTypeId') ? JSON.parse(localStorage.mapTypeId) : 'hybrid',
    zoom: localStorage.getItem('zoom') ? JSON.parse(localStorage.zoom) : 18,
    center: localStorage.getItem('center') ? JSON.parse(localStorage.center) : undefined,
    disableDblClick: false,
    enableUnitFilter: localStorage.getItem('enableUnitFilter') === 'true',
    dashboardFilterValues: {
      completion: [0, 100]
    },
    dashboardColumnsVisibility: dashboardColumnsVisibility
  };
  return {
    uiConfig: localConfig,
    loading: 'idle',
    error: null,
    currentRequestId: undefined
  };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState(),
  reducers: {
    setUserConfig: (state, action) => {
      if (action.payload) {
        if (action.payload.center) {
          localStorage.setItem('center', JSON.stringify(action.payload.center));
        }
        if (action.payload.zoom) {
          localStorage.setItem('zoom', JSON.stringify(action.payload.zoom));
        }
        if (action.payload.mapTypeId) {
          localStorage.setItem('mapTypeId', JSON.stringify(action.payload.mapTypeId));
        }
        if (action.payload.enableUnitFilter) {
          localStorage.setItem('enableUnitFilter', `${action.payload.enableUnitFilter}`);
        }
      }
      state.uiConfig = {...state.uiConfig, ...action.payload};
    },
    setDashboardFilterValues: (state, action) => {
      state.uiConfig.dashboardFilterValues = { ...state.uiConfig.dashboardFilterValues, ...action.payload };
    },
    setDashboardColumnsVisibility: (state, action) => {
      state.uiConfig.dashboardColumnsVisibility = { ...action.payload };
    },
    resetFilters: (state, action) => {
      state.uiConfig.dashboardFilterValues = {
        completion: [0, 100],
        lastReviewedOn: null,
        lastReviewedBy: null,
        reviewStatus: null,
        originalPrePlan: null,
        assignedTo: null,
        assignmentStatus: null
      };
    },
    resetColumns: (state, action) => {
      const dashboardColumnsVisibility = {};
      allColumnIds.forEach(c => {
        dashboardColumnsVisibility[c] = !hiddenColumnIds.includes(c);
      });

      state.uiConfig.dashboardColumnsVisibility = dashboardColumnsVisibility;
    },
  },
  extraReducers: (builder) => {
    // @todo consider using entityAdapter
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchUser.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const dashboardFilterValues = {
          ...state.uiConfig.dashboardFilterValues,
          ...(action.payload.uiConfig && action.payload.uiConfig.dashboardFilterValues && JSON.parse(action.payload.uiConfig.dashboardFilterValues))
        };
        const dashboardColumnsVisibility = {
          ...state.uiConfig.dashboardColumnsVisibility,
          ...(action.payload.uiConfig && action.payload.uiConfig.dashboardColumnsVisibility && JSON.parse(action.payload.uiConfig.dashboardColumnsVisibility))
        };
        const uiConfig = {
          ...state.uiConfig,
          ...action.payload.uiConfig,
          ...{
            dashboardFilterValues: dashboardFilterValues,
            dashboardColumnsVisibility: dashboardColumnsVisibility
          }
        };
        return {
          ...state,
          ...action.payload,
          uiConfig,
          loading: 'idle',
          currentRequestId: undefined,
        };
      })
      .addCase(fetchUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
    builder
      .addCase(updateUserConfig.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;

          // Copy the preference immediately on pending so the UI updates regardless of the outcome of the pref save
          if (typeof action.meta.arg.enableUnitFilter !== 'undefined') {
            localStorage.setItem('enableUnitFilter', action.meta.arg.enableUnitFilter);
            state.uiConfig = { ...state.uiConfig, enableUnitFilter: action.meta.arg.enableUnitFilter };
          }
        }
      })
      .addCase(updateUserConfig.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.currentRequestId = undefined;

        if (typeof action.payload.enableUnitFilter !== 'undefined') {
          localStorage.setItem('enableUnitFilter', action.payload.enableUnitFilter);
        }

        state.uiConfig = {
          ...state.uiConfig,
          ...{
            disableDblClick: action.payload.disableDblClick,
            enableUnitFilter: action.payload.enableUnitFilter,
            mapTypeId: action.payload.mapTypeId,
            zoom: action.payload.zoom,
            center: action.payload.center,
            assignmentDeliveryEmail: action.payload.assignmentDeliveryEmail,
            assignmentDeliveryPushNotification: action.payload.assignmentDeliveryPushNotification
            // removing dashboardFilterValues and dashboardColumnsVisibility to avoid them being overwritten on save
          }
        };
      })
      .addCase(updateUserConfig.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
      builder
      .addCase(updateUserProfile.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {

        return {
          loading: 'idle',
          currentRequestId: undefined,
          uiConfig: { ...state.uiConfig },
          ...action.payload
        };
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });

  }
});

export const uiConfigSelector = state => {
  if (state.user === undefined) {
    return {};
  }
  if (state.user.uiConfig === undefined) {
    return {};
  }
  return {
    // we allow center to be undefined, as mapComponent will handle defaulting to customer address
    center: state.user.uiConfig.center,
    zoom: state.user.uiConfig.zoom ? state.user.uiConfig.zoom : 16,
    // important, this is where we set mapType => mapTypeId (@todo fix)
    mapTypeId: state.user.uiConfig.mapTypeId ? state.user.uiConfig.mapTypeId : 'hybrid',
    disableDblClick: state.user.uiConfig.disableDblClick ? state.user.uiConfig.disableDblClick : false,
    dashboardFilterValues: state.user.uiConfig.dashboardFilterValues,
    dashboardColumnsVisibility: state.user.uiConfig.dashboardColumnsVisibility
  };
};

export function getUIConfig(userState) {
  if (userState === undefined) {
    return {};
  }
  if (userState.uiConfig === undefined) {
    return {};
  }
  return {
    // we allow center to be undefined, as mapComponent will handle defaulting to customer address
    center: userState.uiConfig.center,
    zoom: userState.uiConfig.zoom ? userState.uiConfig.zoom : 16,
    // important, this is where we set mapType => mapTypeId (@todo fix)
    mapTypeId: userState.uiConfig.mapTypeId ? userState.uiConfig.mapTypeId : 'hybrid',
    disableDblClick: userState.uiConfig.disableDblClick ? userState.uiConfig.disableDblClick : false,
    dashboardFilterValues: userState.uiConfig.dashboardFilterValues,
    dashboardColumnsVisibility: userState.uiConfig.dashboardColumnsVisibility
  };
}

export const userRoleSelector = state => {
  if (state.user === undefined) {
    return {};
  }
  if (state.user.role === undefined) {
    return {};
  }
  return state.user.role;
};

const { actions, reducer } = userSlice;

export const {
  setUserConfig,
  setDashboardFilterValues,
  setDashboardColumnsVisibility,
  resetFilters,
  resetColumns,
} = actions;

export default reducer;
