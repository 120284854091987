import * as AJAXUtil from './AJAXUtil';
import config from '../../config';


export function getAllStructures() {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/structure`,
  }); // Structure_out
};

export function addStructure(structure) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${config.API_BASE_URL}/api/v2/structure`,
    data: structure, // Structure_in
  }); // Structure_out
};

export function getStructureById(structureId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/structure/${structureId}`,
  }); // Structure_out
};

export function deleteStructure(structureId) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${config.API_BASE_URL}/api/v2/structure/${structureId}`,
  }); // Structure_out
}

export function editStructure(structureId, structurePatch) {
  return AJAXUtil.AJAX({
    method: 'PATCH',
    url: `${config.API_BASE_URL}/api/v2/structure/${structureId}`,
    data: structurePatch,
  }); // Structure_out
}

export function searchStructure(structureSearchRequest) {
  return AJAXUtil.AJAX({
    method: 'POST',
    url: `${config.API_BASE_URL}/api/v2/structure/search`,
    data: structureSearchRequest,
  }); // StructureSearchResponse
}

export function searchStructure2(structureSearchRequest2) {
  return AJAXUtil.AJAX({
    method: 'POST',
    url: `${config.API_BASE_URL}/api/v2/structure/search2`,
    data: structureSearchRequest2,
  }); // StructureSearchResponse
}

export function getImages(structureId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/structure/${structureId}/images`,
  }); // [Image]
};

export function addImageToStructure(structureId, imageId) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${config.API_BASE_URL}/api/v2/structure/${structureId}/image/${imageId}`,
  });
};

export function removeImageFromStructure(structureId, imageId) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${config.API_BASE_URL}/api/v2/structure/${structureId}/image/${imageId}`,
  });
};

export const fetchPartnerStructures = (partnerId) => {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/structure/partner/${partnerId}`
  });
};

export const getPrePlan = (locationCoords, storey, storeyBelow, preplanLink) => {
  return AJAXUtil.AJAX({
    method: 'POST',
    data: { locationCoords, storey, storeyBelow },
    url: preplanLink.href
  });
};
