import React from 'react';
import { configureStore, history } from './store/configureStore';
import Root from './containers/Root';
import ErrorBoundary from './containers/ErrorBoundary';
import './styles/app.scss';
import {ColorModeScript} from '@chakra-ui/react';
import theme from './styles/theme/default';


export const store = configureStore();


export const Index = ({ setSession }) => {
    return (
        <ErrorBoundary>
            <ColorModeScript storageKey="fmsp-cm" initialColorMode={theme.config.initialColorMode} />
            <Root store={store} history={history} setSession={setSession} />
        </ErrorBoundary>
    );
};