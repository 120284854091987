import React, { useEffect, useState } from 'react';
import { Box, Text, Divider, Heading, useColorModeValue, Flex, Stack, useToast, Select, Icon } from '@chakra-ui/react';
import { LinkButton, SubmitButton } from '../form/Button';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import FormComponent from '../../components/form/FormComponent';
import { useDispatch } from 'react-redux';
import * as customerAPI from '../../api/CustomerAPI';
import { ESOIntegration, ESODispatchIntegration, FirstArrivingIntegration, EmergencyNetworkingIntegration } from '../../models/Integrations';
import { zodResolver } from '@hookform/resolvers/zod';
import EditIcon from '../FigmaExport/EditIcon';


const resolvers = {
    "ESO": ESOIntegration,
    "ESODispatch": ESODispatchIntegration,
    "FirstArriving": FirstArrivingIntegration,
    "EmergencyNetworking": EmergencyNetworkingIntegration,
};

const ESOFormFields = [
    {
        id: 'ESOPrimarySubscription',
        name: 'ESOPrimarySubscription',
        type: 'text',
        label: 'ESO Primary Subscription Key',
        required: true
    },
    {
        id: 'ESOSecondarySubscription',
        name: 'ESOSecondarySubscription',
        type: 'text',
        label: 'ESO Secondary Subscription Key',
        required: false
    },
];

const ESODispatchFormFields = [
    {
        id: 'ESODispatch',
        name: 'ESODispatch',
        type: 'text',
        label: 'ESO Dispatch',
        required: true
    },
];

const FirstArrivingFormFields = [
    {
        id: 'FirstArriving',
        name: 'FirstArriving',
        type: 'text',
        label: 'First Arriving',
        required: true
    },
];

const EmergencyNetworkingFormFields = [
    {
        id: 'EmergencyNetworking',
        name: 'EmergencyNetworking',
        type: 'text',
        label: 'Emergency Networking',
        required: true
    },
];

const formFields = {
    "ESO": ESOFormFields,
    "ESODispatch": ESODispatchFormFields,
    "FirstArriving": FirstArrivingFormFields,
    "EmergencyNetworking": EmergencyNetworkingFormFields,
};

const initialIntegrations = {
    "ESO": {
        "ESOPrimarySubscription": null,
        "ESOSecondarySubscription": null
    },
    "ESODispatch": {
        "ESODispatch": null,
    },
    "FirstArriving": {
        "FirstArriving": null,
    },
    "EmergencyNetworking": {
        "EmergencyNetworking": null,
    },
};

const tryGetApikey = (apiKeys, name) => {
    const result = apiKeys.find(item => item.name === name);
    return result && result.value;
};

const normalize = (integrations) => {
    let apiKeys = {};
    Object.keys(integrations).forEach(integration => {
        apiKeys = {...apiKeys, ...integrations[integration]};
    });
    return apiKeys;
};

export const IntegrationsCard = (props) => {
    const apiKeys = useSelector(state => state.customer && state.customer.apiKeys);
    const [integrations, setIntegrations] = useState(initialIntegrations);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const toast = useToast();
    const dispatch = useDispatch();

    useEffect(() => {
        if (apiKeys) {
            const newIntegrations = {
                "ESO": {
                    "ESOPrimarySubscription": tryGetApikey(apiKeys, "ESOPrimarySubscription"),
                    "ESOSecondarySubscription": tryGetApikey(apiKeys, "ESOSecondarySubscription")
                },
                "ESODispatch": {
                    "ESODispatch": tryGetApikey(apiKeys, "ESODispatch"),
                },
                "FirstArriving": {
                    "FirstArriving": tryGetApikey(apiKeys, "FirstArriving"),
                },
                "EmergencyNetworking": {
                    "EmergencyNetworking": tryGetApikey(apiKeys, "EmergencyNetworking"),
                },
            };
            setIntegrations(newIntegrations);
        }
    }, [apiKeys]);

    const onSubmitIntegration = (newValues) => {
        const allValues = {...normalize(integrations), ...newValues};
        const apiKeysToSend = Object.keys(allValues).map(key => ({ name: key, value: allValues[key] }));
        const data = [];
        data.push({
            op: 'replace',
            path: '/apiKeys',
            value: apiKeysToSend
        });

        return new Promise((resolve, reject) => customerAPI.editCustomer(
            data,
            (msg) => {
                toast({
                    title: 'Success',
                    position: 'top',
                    description: msg,
                    status: 'success',
                    duration: 2500,
                    isClosable: true
                });
                setIsEditing(false);
                resolve();
            },
            (msg) => {
                toast({
                    title: 'Error',
                    position: 'top',
                    description: msg,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
                reject();
            },
            dispatch
        ));
    };

    return (
        <Box maxWidth="32rem" minWidth="24rem" bg={useColorModeValue('white', 'gray.900')} p="1rem">
            <Flex align="center" justify="space-between">
                <Heading as="h4" size="md">API Integrations</Heading>
                <LinkButton gap="2px" isDisabled={!selectedIntegration} onClick={() => setIsEditing(!isEditing)}><Icon as={EditIcon} />{isEditing ? 'Cancel' : 'Edit'}</LinkButton>
            </Flex>
            <Divider my="0.5rem" />
            <Select
                placeholder="Select..."
                onChange={(e) => {
                    setSelectedIntegration(e.target.value);
                }}
                isDisabled={isEditing}
            >
                {Object.keys(integrations).map(name => (
                    <option key={`${name}`} value={name}>{name}</option>
                ))}
            </Select>
            <Divider my="0.5rem" />
            {selectedIntegration && (
                isEditing ?
                    <IntegrationForm integration={integrations[selectedIntegration]} resolver={resolvers[selectedIntegration]} formFields={formFields[selectedIntegration]} onSubmitIntegration={onSubmitIntegration} />
                    : <IntegrationDetails integration={integrations[selectedIntegration]} fields={formFields[selectedIntegration]} />
            )}
        </Box>
    );
};

const IntegrationForm = ({ integration, resolver, formFields, onSubmitIntegration }) => {
    const methods = useForm({
        resolver: zodResolver(resolver)
    });

    const onSubmit = async (values) => {
        return onSubmitIntegration(values);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormComponent formFields={formFields} data={integration} />
                <SubmitButton isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
                    Save
                </SubmitButton>
            </form>
        </FormProvider>
    );
};

const IntegrationDetails = ({ integration, fields }) => {
    const labelStyle = {
        fontSize: 'xs',
        textTransform: 'uppercase',
        lineHeight: '1.25rem',
        color: 'gray.700'
    };

    return (
        <Box>
            <Stack spacing="0.5rem">
                {Object.keys(integration).map(key => (
                    <Stack spacing="0" key={key}>
                        <Text {...labelStyle}>{fields.find(f => f.id === key).label}</Text>
                        <Text fontWeight="600">{integration[key] || "--"}</Text>
                    </Stack>
                ))}
            </Stack>
        </Box>
    );
};
