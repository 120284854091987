import React from 'react';
import { Stack, AccordionItem, AccordionButton, AccordionPanel, Accordion, Flex, Text, Box, Divider } from '@chakra-ui/react';
import IconLegend from './IconLegendComponent';
import { useSelector } from 'react-redux';
import { selectAllHydrants } from '../../features/hydrants/hydrantsSlice';
import { HydrantInfoPanel } from './HydrantInfoPanel';
import ArrowUpIcon from '../FigmaExport/ArrowUpIcon';
import ArrowDownIcon from '../FigmaExport/ArrowDownIcon';


export const FlowData = (props) => {
  return (
    <Flex gap="12px" textAlign="center">
      <Stack bg="#2C62CB1A" borderWidth='1px' borderRadius='lg' w="100%" spacing="4px" p= "4px">
        <Text color="#707A86" fontSize="sm" fontWeight="400" textTransform="uppercase">Selected Hydrants</Text>
        <Text color="#222234" fontSize="lg" fontWeight="600">{props.hydrantsLength}</Text>
      </Stack>
      <Stack bg="#2C62CB1A" borderWidth='1px' borderRadius='lg' w="100%" spacing="4px" p= "4px">
        <Text color="#707A86" fontSize="sm" fontWeight="400" textTransform="uppercase">Flow Rate</Text>
        <Text color="#222234" fontSize="lg" fontWeight="600">{props.totalFlowRate ? props.totalFlowRate.toLocaleString() : '--'} gal/min</Text>
      </Stack>
    </Flex>
  );
};

export const HydrantsInfoPanel = () => {
  const { pinLegend } = useSelector(state => state.customer);
  const selectedHydrantIds = useSelector(state => state.hydrants.selectedHydrantIds);
  const hydrants = useSelector(selectAllHydrants).filter(hydrant => selectedHydrantIds && selectedHydrantIds.includes(hydrant.id));

  const getFlowDataProps = () => {
    let totalHoses = 0;
    let totalFlowRate = 0;
    for (let i = 0; i < hydrants.length; i++) {
      if (hydrants[i].hydrantSize) {
        totalHoses = totalHoses + parseInt(hydrants[i].hydrantSize);
      }
      const flow =
        hydrants[i].flow === null ||
          hydrants[i].flow === undefined
          ? 500
          : parseInt(hydrants[i].flow);
      totalFlowRate = totalFlowRate + flow;
    }
    return {
      hydrantsLength: hydrants.length,
      totalHoses: totalHoses,
      totalFlowRate: totalFlowRate
    };
  };

  return (
    <Accordion defaultIndex={0} allowToggle borderWidth='1px' borderRadius='lg' padding="12px" >
      <AccordionItem border={0}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton paddingLeft={0} paddingY={0}>
                <Box as='h2' fontSize="lg" fontWeight="600" flex='1' textAlign='left'>
                  Location Hydrants
                </Box>
                { isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon /> }
              </AccordionButton>
            </h2>
            <AccordionPanel padding={0}>
              <Divider marginY={1}/>
              <Stack spacing="1rem">
                <FlowData  {...getFlowDataProps()} />
                <Stack gap="6px">
                  {hydrants && hydrants.map((hydrant, index) => (
                    <HydrantInfoPanel key={index} hydrant={hydrant} index={index} />
                  ))}
                </Stack>
                {Object.keys(pinLegend).length !== 0 && (
                  <IconLegend pinLegend={pinLegend} />
                )}
              </Stack>
            </AccordionPanel>
            </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
