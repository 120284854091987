import React, { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import ArrowUpIcon from '../FigmaExport/ArrowUpIcon';
import ArrowDownIcon from '../FigmaExport/ArrowDownIcon';


export const ReadOnlyBanner = ({ name }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      p="12px"
      gap="8px"
      borderRadius="8px"
      textColor="black"
      backgroundColor="#EFB34A26"
      onClick={toggle}
      cursor="pointer"
    >
      <Flex alignItems="center" gap="4px">
        <Text flexGrow={1} fontSize="12px" fontWeight="500">This Pre-Plan is in Read-Only Mode.</Text>
        <Box>
          { isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon /> }
        </Box>
      </Flex>
      { isExpanded && 
        <Text fontSize="12px" fontWeight="400">
          All actions are disabled. To make changes, please visit {name}.
        </Text>
      }
    </Box>
  );
};
