import * as z from 'zod';


export const CreateUserComplete = z.object({
  email: z.string().nonempty('A valid email is required'),
  firstName: z.string().nonempty('A first name is required'),
  lastName: z.string().nonempty('A last name is required'),
  password: z.string().nonempty('Password is required').min(8, 'Password must be at least 8 characters'),
  confirmPassword: z.string().nonempty('Confirm password is required'),
}).refine((data) => data.password === data.confirmPassword, {
  message: 'Passwords do not match',
  path: ['confirmPassword']
});
