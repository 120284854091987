import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormComponent from './FormComponent';
import { Divider, HStack, Stack, useToast } from '@chakra-ui/react';
import { OutlineButton, SubmitButton } from './Button';
import { useDispatch } from 'react-redux';
import { editStructure } from '../../features/structures/structuresSlice';


const FLOOR_FORM_FIELDS = [
  {
    label: 'Floor Name',
    id: 'name',
    name: 'name',
    type: 'text',
    placeholder: '',
    required: true,
  },
  // {
  //   label: 'Note',
  //   id: 'note',
  //   name: 'note',
  //   type: 'textarea',
  //   placeholder: ''
  // },
];


export const AddFloorForm = ({
  selectedStructure,
  floors,
  closeModal,
}) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (floors.find(floor => floor.name === data.name)) {
      onError('Failed to add floor', `Floor with name ${data.name} already exists for this structure.`);
    } else {
      const floorAdd = {
        name: data.name,
      };
      // data.note && (floorAdd.note = data.note);
      const structurePatch = [
        {
          op: 'replace',
          path: '/floors',
          value: [floorAdd, ...floors.slice(1).map(f => ({ name: f.name }))],
        }
      ];
      const result = await dispatch(editStructure({ structureId: selectedStructure.id, structurePatch }));
      if (editStructure.fulfilled.match(result)) {
        onSuccess('Success', 'Floor added');
      } else {
        onError('Failed to add floor', 'Please check floor fields and try again.');
      }
    }
  };

  const onError = (...p) => onComplete('error', ...p);

  const onSuccess = (...p) => {
    closeModal();
    onComplete('success', ...p);
  };

  const onComplete = (type, title, body) => {
    toast({
      title: title,
      position: 'top',
      description: body,
      status: type,
      duration: 2500,
      isClosable: true
    });
  };

  const onCancel = () => {
    methods.reset();
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing="1rem">
          <FormComponent formFields={FLOOR_FORM_FIELDS} data={{}} />
          <Divider />
          <HStack justifyContent="end" spacing="1rem">
            <OutlineButton onClick={onCancel} width="8rem">Cancel</OutlineButton>
            <SubmitButton isLoading={methods.formState.isSubmitting} width="8rem">Add</SubmitButton>
          </HStack>
        </Stack>
      </form>
    </FormProvider>
  );
};
