// import * as AJAXUtil from '../../api/AJAXUtil';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
// Setting up according to redux-toolkit best practices
// Reference: https://redux-toolkit.js.org/usage/usage-guide

// interface ThunkAPI {
//   dispatch: Function
//   getState: Function
//   extra?: any
//   requestId: string
//   signal: AbortSignal
// }


// Adapter & State
export const locationsAdapter = createEntityAdapter();
const initialState = locationsAdapter.getInitialState({
  loading: 'idle',
  partnerLoading: 'idle',
  error: null,
  lastPartnerFetch: null,
});

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    locationsLoading: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    locationsReceived: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.entities = action.payload;
      }
    },
    resetLastPartnerFetch: (state, action) => {
      state.lastPartnerFetch = null;
    },
    filterPartnerLocations: (state, action) => {
      // given a new list of partner ids, filter locations to make sure they are in the list
      const excludedPartners = locationsAdapter.getSelectors().selectAll(state).filter(loc => !loc.isMine).filter(loc => !action.payload.includes(loc.customerId));
      locationsAdapter.removeMany(state, excludedPartners.map(loc => loc.id));
    }
  },
  extraReducers: (builder) => {
    // @todo consider using entityAdapter
    // Add reducers for additional action types here, and handle loading state as needed
  }
});


const { actions, reducer } = locationsSlice;

export const {
  resetLastPartnerFetch,
  filterPartnerLocations
} = actions;

export default reducer;


export const {
  selectById: selectLocationById,
  selectAll: selectAllLocations,
  selectTotal: selectTotalLocations,
} = locationsAdapter.getSelectors(state => state.locations);
