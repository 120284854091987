import React from 'react';
import { Stack, Text } from '@chakra-ui/react';


export const AddressStack = (props) => {

  const AddressLine = p => (
    <Text fontWeight="600" lineHeight="1.25rem">{p.children}</Text>
  );
  return (<Stack className="address" spacing="0">
    {props.address1 && <AddressLine>{props.address1}</AddressLine>}
    {props.address2 && <AddressLine>{props.address2}</AddressLine>}
    {props.city && <AddressLine>{props.city}, {props.state} {props.zip}</AddressLine>}
  </Stack>
  );
};
