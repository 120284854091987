import React, { useState, useRef } from 'react';
import * as HydrantAPI from '../api/HydrantAPI';
import * as LocationAPI from '../api/LocationAPI';
import * as UserAPI from '../api/UserAPI';
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from '../components/common/PrintFile';
import { FlexWithBackgroundTheme } from './ThemeContainer';
import { Box, Text, Divider, Heading, useColorModeValue, Flex, Stack, useToast, Button, FormControl, FormErrorMessage, Link, Icon } from '@chakra-ui/react';
import { LinkButton, OutlineButton, SubmitButton } from '../components/form/Button';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { RadioGroup } from '../components/form/FormWrapper';
import { FileUpload } from '../components/form/FileUploadComponent';
import { useSelector } from 'react-redux';
import AttachIcon from '../components/FigmaExport/AttachIcon';
import HydrantIcon from '../components/FigmaExport/HydrantIcon';
import DownloadIcon2 from '../components/FigmaExport/DownLoadIcon2';
import DescriptionIcon from '../components/FigmaExport/DescriptionIcon';
import UserIcon from '../components/FigmaExport/UserIcon';


const UploadDataContainer = (/*props*/) => {

  return (
    <FlexWithBackgroundTheme
      justifyContent="center"
      alignItems="center"
      height={'calc(100vh)'}
      overflow="auto"
      w="100%"
      flexDirection="column"
      paddingBottom="1.5rem"
      paddingX={'1.5rem'}
      fontFamily="Inter"
    >
      <UploadDataForm />
    </FlexWithBackgroundTheme>
  );

};

const UploadDataForm = (/*props*/) => {
  // const user = useSelector(state => state.user);
  const customer = useSelector(state => state.customer);
  const toast = useToast();
  const [uploadData, setUploadData] = useState([]);
  const { formState: { isSubmitting, errors }, ...methods } = useForm();
  // const labelStyle = {
  //   fontSize: 'xs',
  //   textTransform: 'uppercase',
  //   lineHeight: '1.25rem',
  //   color: 'gray.700'
  // };

  const dataOptions = {
    placeholder: 'Select a data type',
    name: 'uploadType',
    defaultValue: 'not-selected',
    options: [
      {
        value: 'hydrant',
        label: 'Hydrant'
      },
      {
        value: 'preplan',
        label: 'Preplan'
      },
      {
        value: 'user',
        label: 'User'
      }
    ]
  };

  const validateFiles = (value) => {
    if (value.length < 1) {
      return 'Files is required';
    }
    for (const file of Array.from(value)) {
      const fsMb = file.size / (1024 * 1024);
      const MAX_FILE_SIZE = 10;
      if (fsMb > MAX_FILE_SIZE) {
        return 'Max file size 10mb';
      }
    }
    return true;
  };

  const onProgress = (prog) => {
  };

  const onSubmit = (data) => {
    setUploadData([]);
    return new Promise(resolve => {
      const onSuccess = (message) => {
        setUploadData(message.split('\n'));
        toast({
          title: 'Success',
          position: 'top',
          description: 'Upload complete',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
        resolve();
      };
      const onError = (message) => {
        setUploadData(message.split('\n'));
        toast({
          title: 'Error',
          position: 'top',
          description: 'Upload failed.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
        resolve();
      };

      if (!data.uploadType) {
        onError('');
      }

      const formData = new FormData();
      if (data.file_[0]) {
        formData.append('file', data.file_[0]);
        if (data.uploadType === 'hydrant') {
          HydrantAPI.uploadHydrant(formData, onSuccess, onError, onProgress, customer.links && customer.links.find(x => x.rel === 'hydrants'));
        } else if (data.uploadType === 'preplan') {
          LocationAPI.uploadPrePlan(formData, onSuccess, onError, onProgress, customer.links && customer.links.find(x => x.rel === 'locations'));
        } else if (data.uploadType === 'user') {
          UserAPI.uploadUser(formData, onSuccess, onError, onProgress);
        }
      } else {
        resolve();
      }
    });

  };

  const background = useColorModeValue('white', 'gray.900');

  return (
    <Box>
      <Box marginTop="2rem" width="60rem" bg={useColorModeValue('white', 'gray.900')} p="1rem">
        <Flex align="center" justify="space-between">
          <Heading as="h4" size="md">Upload Data</Heading>
        </Flex>
        <Divider my="0.5rem" />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing="1rem">
              <Flex justifyContent="center" alignItems="center">
                <RadioGroup fontFamily="Inter" groupName={'Data Type'} direction={'row'} {...dataOptions} />
                <Box ml="auto">
                  <FormControl isInvalid={!!errors.file_} isRequired>
                    <FileUpload
                      accept={'.csv,text/csv'}
                      multiple // this is not really multiple. only processes the first file
                      register={methods.register('file_', { validate: validateFiles })}
                    >
                      <OutlineButton color="#2C62CB" borderColor="#2C62CB" leftIcon={<AttachIcon />}>
                        <FileName />
                      </OutlineButton>
                    </FileUpload>
                    <FormErrorMessage>
                      {errors.file_ && errors.file_.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Flex>
              <Divider />
              <UserGuides />

              <Flex justifyContent="space-between" alignItems="center">
                <SubmitButton isLoading={isSubmitting} bg="#2C62CB" type="submit">
                  Upload
                </SubmitButton>
                <LinkButton color="#2C62CB" onClick={() => methods.reset()}>Cancel</LinkButton>
              </Flex>
            </Stack>
          </form>
        </FormProvider>
      </Box>
      {uploadData.length > 0 && (
        <Box marginTop="2rem" width="60rem" bg={background} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
          <ResultsTable data={uploadData} header={'Results'} />
        </Box>
      )}
    </Box>
  );
};

const FileName = () => {
  const file = useWatch({ name: 'file_' });
  const selectedFile = file ? file[0] : null;
  return (
    <Text>{selectedFile ? selectedFile.name : 'Select File'}</Text>
  );
};

const UserGuides = (props) => {
  const uploadType = useWatch({ name: 'uploadType' });
  return (<React.Fragment>
    {uploadType === 'hydrant' &&
      <Flex spacing="0.25rem">
        <DownloadBox title="Sample Input File" text="Hydrant" href="/images/HydrantsSample.csv" icon={HydrantIcon} />
        <DownloadBox title="Sample Input File" text="Hydrant - with sample data" href="/images/HydrantsSampleData.csv" icon={HydrantIcon} />
        <DownloadBox title="Manual" text="Hydrant" href="/images/HydrantsManual.pdf" icon={HydrantIcon} />
      </Flex>
    }
    {uploadType === 'preplan' &&
      <Flex spacing="0.25rem">
        <DownloadBox title="Sample Input File" text="Preplan" href="/images/PreplanSample.csv" icon={DescriptionIcon} />
        <DownloadBox title="Sample Input File" text="Preplan - with sample data" href="/images/PreplanSampleData.csv" icon={DescriptionIcon} />
        <DownloadBox title="Manual" text="Preplan" href="/images/PreplanManual.pdf" icon={DescriptionIcon} />
      </Flex>
    }
    {uploadType === 'user' &&
      <Flex spacing="0.25rem">
        <DownloadBox title="Sample Input File" text="User" href="/images/UserSample.csv" icon={UserIcon} />
        <DownloadBox title="Sample Input File" text="User - with sample data" href="/images/UserSampleData.csv" icon={UserIcon} />
        <DownloadBox title="Manual" text="User" href="/images/UserManual.pdf" icon={UserIcon} />
      </Flex>
    }
  </React.Fragment>
  );
};

const DownloadBox = ({ title, text, href, icon }) => {
  return (
    <Stack alignItems="center" py="12px" px="16px" w="320px" gap="16px">
      <Stack alignItems="center" gap="4px">
        <Text fontWeight={700} fontSize="16px">{title}</Text>
        <Text fontWeight={400} fontSize="12px" textColor="#707A86"><Icon as={icon} fill="#707A86" mr="4px" />{text}</Text>
      </Stack>
      <Divider w="300px" />
      <Link display="flex" color="#2C62CB" fontWeight={600} alignItems="baseline" isExternal href={href}><Icon as={DownloadIcon2} mr="8px" />&nbsp;Download</Link>
    </Stack>
  );
};


const ResultsTable = (props) => {
  // const [ref, setRef] = useState(null);
  const contentRef = useRef();
  const handlePrint = useReactToPrint({ contentRef });

  return (
    <Box>
      {props.data &&
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4" size="sm">Log</Heading>
          <Button onClick={() => {
            // console.log({ contentRef, handlePrint });
            handlePrint();
          }} variant="text" color="blue.400" fontWeight="400">Print this out</Button>
        </Flex>
      }
      <ComponentToPrint
        ref={contentRef}
        data={props.data}
        header={props.header}
      />
    </Box>
  );
};

export default UploadDataContainer;
