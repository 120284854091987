import React from 'react';
import { userRoleSelector } from '../../features/user/userSlice';

import { Box, Tooltip, Menu, MenuList, MenuItem, MenuButton, Button, useToast, Flex } from '@chakra-ui/react';
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { useSelector } from 'react-redux';
import AddIcon from '../FigmaExport/AddIcon';
import ResizeIcon from '../FigmaExport/ResizeIcon';
import HydrantIcon from '../FigmaExport/HydrantIcon';
import { OutlineButton } from '../form/Button';

export const AddItem = (props) => {
  const userRole = useSelector(userRoleSelector);
  const toast = useToast();

  const addNewPrePlan = () => {
    props.handleContextMenuItemSelect({
      value: "PREPLAN_LOCATION"
    });
    toast({
      title: 'Draw Location',
      position: 'top',
      description: 'Tap or click to use the drawing tool to trace location area.',
      status: 'info',
      duration: 5000,
      isClosable: true
    });
  };

  const addNewHydrant = () => {
    props.setNewHydrant(true);
    toast({
      title: 'Place Hydrant',
      position: 'top',
      description: 'Tap or click on the map to place the hydrant location.',
      status: 'info',
      duration: 5000,
      isClosable: true
    });
  };

  const buttonProps = {
    background: "#fff",
    width: "141px",
    borderRadius: "6px",
    borderColor: "#2C62CB",
    borderWidth: "1px",
    justifyContent: "space-between"
  };

  return (
    (userRole === "ADMIN" || userRole === "PLANNER") &&
    <Box
      height="2.5rem"
    >
      {props.isInActionMode ? (
        <Flex gap="8px">
          { props.action !== 'REPLAN_STRUCTURE' && props.action !== 'ADD_STRUCTURE' &&
          <OutlineButton
            fontSize="sm"
            color="#2C62CB"
            borderColor="#2C62CB"
            onClick={() => props.onSubmitAction()}
            flexGrow={1}
          >
            Save
          </OutlineButton>
          }
          <OutlineButton
            fontSize="sm"
            color="red"
            borderColor="red"
            onClick={() => props.onCancelAction()}
            flexGrow={1}
            isDisabled={props.actionLoading}
          >
            Cancel
          </OutlineButton>
        </Flex>
      ) : (props.newHydrant) ? (
        <Button
          onClick={() => {
            props.setNewHydrant(false);
          }}
          color="red.400"
          rightIcon={<FaTimes />}
          {...buttonProps}
        >Cancel</Button>
      ) : (
        <Menu>
          <MenuButton
            as={Button}
            leftIcon={<AddIcon fill="currentcolor" />}
            {...buttonProps}
            textAlign="left"
            bg="#2C62CB"
            textColor="#fff"
            _hover={{ bg: '#fff', textColor: '#2C62CB' }}
            _active={{ bg: '#2C62CB', textColor: 'white' }}
          >
            <Tooltip
              placement="right"
              label="Add Pre-Plan or Hydrant"
            >
              Create New
            </Tooltip>
          </MenuButton>
          <MenuList minWidth="" padding="12px">
            <MenuItem
              key={"menu-preplan"}
              onClick={e => addNewPrePlan()}
              icon={<ResizeIcon />}
              textColor="#2C62CB"
              fontFamily="Inter"
              fontWeight="600"
              fontSize="sm"
              borderRadius="6px"
              borderWidth="1px"
              borderColor="#2C62CB"
              mb="12px"
            >
              Pre-Plan Location
            </MenuItem>
            <MenuItem
              key={"menu-hydrant"}
              onClick={addNewHydrant}
              icon={<HydrantIcon />}
              textColor="#2C62CB"
              fontFamily="Inter"
              fontWeight="600"
              fontSize="sm"
              borderRadius="6px"
              borderWidth="1px"
              borderColor="#2C62CB"
            >
              Add Hydrant
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Box>
  );
};
