import React from 'react';

import {
  Text,
  Stack,
  Divider,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Tooltip,
} from '@chakra-ui/react';
import OccupantInfoPanel from './OccupantInfoPanel';
import AddIcon from '../FigmaExport/AddIcon';
import ListIcon from '../FigmaExport/ListIcon';
import { OutlineButton } from '../form/Button';
import DeleteIcon from '../FigmaExport/DeleteIcon';
import ArrowUpIcon from '../FigmaExport/ArrowUpIcon';
import ArrowDownIcon from '../FigmaExport/ArrowDownIcon';


export const FloorComponent = ({
  floor,
  handleAddOccupant,
  selectedStructure,
  handleEditOccupant,
  handleDeleteOccupant,
  handleDeleteFloor,
  canEdit,
  reason,
}) => {
  return (
    <Accordion defaultIndex={0} allowToggle borderWidth='1px' borderRadius='lg' p="12px">
      <AccordionItem border={0}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton paddingLeft={0} paddingY={0}>
                <Flex fontSize="xl" fontWeight="600" flex='1' textAlign='left'>
                  <Text alignContent="center" color={!floor.name ? 'red' : null}>{floor.name || 'Not assigned'} ({floor.occupants.length})</Text>
                </Flex>
                { isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon /> }
              </AccordionButton>
            </h2>
            <AccordionPanel padding={0}>
              <Stack gap="10px">
                <Divider marginY={1}/>
                {floor.name && (<Flex gap="8px">
                  <OutlineButton
                    fontSize="sm"
                    color="#2C62CB"
                    borderColor="#2C62CB"
                    leftIcon={<ListIcon fill="#2C62CB" />}
                    iconSpacing="auto"
                    // onClick={() => handleReOrderOccupants(floor.name)}
                    isDisabled
                  />
                  <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
                    <Flex flexGrow={1}>
                      <OutlineButton
                        fontSize="sm"
                        color="#2C62CB"
                        borderColor="#2C62CB"
                        leftIcon={<AddIcon fill="#2C62CB" />}
                        onClick={() => handleAddOccupant(selectedStructure, false, floor.name)}
                        flexGrow={1}
                        isDisabled={!canEdit}
                      >
                        Add Occupant
                      </OutlineButton>
                    </Flex>
                  </Tooltip>
                  <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
                    <Flex flexGrow={1}>
                      <OutlineButton
                        fontSize="sm"
                        color="red"
                        borderColor="red"
                        leftIcon={<DeleteIcon fill="red" />}
                        onClick={() => handleDeleteFloor(floor.name)}
                        flexGrow={1}
                        isDisabled={!canEdit}
                      >
                        Delete Floor
                      </OutlineButton>
                    </Flex>
                  </Tooltip>
                </Flex>)}
                <Stack gap="12px">
                  {floor.occupants.length === 0 && <Text textAlign="center" py="8px" >No added occupants in this floor.</Text>}
                  {floor.occupants.map((occupant, index) => (
                    <OccupantInfoPanel
                      key={index}
                      occupant={occupant}
                      handleEditOccupant={handleEditOccupant}
                      handleDeleteOccupant={handleDeleteOccupant}
                      canEdit={canEdit}
                      reason={reason}
                    />
                  ))}
                </Stack>
              </Stack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}