import React, { useEffect } from 'react';
import Routes from '../routes';
import { ChakraProvider } from '@chakra-ui/react';
import flowTheme from '../styles/theme/default';
import { loginSuccess } from '../actions/session-actions';
import { useDispatch } from 'react-redux';
// import { BrowserRouter } from 'react-router-dom';


const App = ({ session }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (session && session.isLoggedIn) {
      dispatch(loginSuccess(session));
    }
  }, [session, dispatch]);

  return (
    <ChakraProvider theme={flowTheme}>
      {Routes}
    </ChakraProvider>
  );
}

export default App;
