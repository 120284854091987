import React, { useEffect } from 'react';
import Routes from '../routes';
import { ChakraProvider } from '@chakra-ui/react';
import flowTheme from '../styles/theme/default';
// import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';


const App = ({ setSession }) => {
  const session = useSelector(state => state.session);

  useEffect(() => {
      setSession(session);
  }, [session, setSession]);

  return (
    <ChakraProvider theme={flowTheme}>
      {Routes}
    </ChakraProvider>
  );
};

export default App;
