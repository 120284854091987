import React, { useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import FormComponent from './FormComponent';
import { Divider, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { OutlineButton, SubmitButton, SubmitButtonDanger } from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { addStructure, deleteStructure, editStructure, setSelectedStructureId } from '../../features/structures/structuresSlice';
import { setSelectedHydrants } from '../../features/hydrants/hydrantsSlice';


const STRUCTURE_FORM_FIELDS = [
  {
    label: 'Location Label',
    id: 'label',
    name: 'label',
    type: 'text',
    placeholder: '',
  },
  {
    label: 'Location Name',
    id: 'name',
    name: 'name',
    type: 'text',
    placeholder: '',
    required: false,
  },
  {
    label: 'Address 1',
    id: 'address1',
    name: 'address1',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Address 2',
    id: 'address2',
    name: 'address2',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Zip',
    id: 'zip',
    name: 'zip',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Occupancy Type',
    id: 'occupancyType',
    name: 'occupancyType',
    type: 'select',
    options: [
      {
        label: 'Select Occupancy Type',
        value: ''
      },
      {
        label: 'Assembly',
        value: 'Assembly'
      },
      {
        label: 'Board & Care',
        value: 'Board & Care'
      },
      {
        label: 'Business / Mercantile',
        value: 'Business / Mercantile'
      },
      {
        label: 'Day-Care',
        value: 'Day-Care'
      },
      {
        label: 'Detention & Correctional',
        value: 'Detention & Correctional'
      },
      {
        label: 'Educational',
        value: 'Educational'
      },
      {
        label: 'High Hazard',
        value: 'High Hazard'
      },
      {
        label: 'Industrial',
        value: 'Industrial'
      },
      {
        label: 'Medical Care / Institutional',
        value: 'Medical Care / Institutional'
      },
      {
        label: 'Mixed Business / Residential',
        value: 'Mixed Business / Residential'
      },
      {
        label: 'Multi-Family',
        value: 'Multi-Family'
      },
      {
        label: 'Residential',
        value: 'Residential'
      },
      {
        label: 'Special Structures',
        value: 'Special Structures'
      },
      {
        label: 'Storage',
        value: 'Storage'
      }
    ],
    required: false
  },
  {
    label: 'Construction Type',
    id: 'constructionType',
    name: 'constructionType',
    type: 'select',
    options: [
      {
        label: 'Select Construction Type',
        value: ''
      },
      {
        label: 'Not Classified',
        value: 'Not Classified'
      },
      {
        label: 'Type IA - Fire Resistive, Non-combustible',
        value: 'Type IA - Fire Resistive, Non-combustible'
      },
      {
        label: 'Type IB - Fire Resistive, Non-combustible',
        value: 'Type IB - Fire Resistive, Non-combustible'
      },
      {
        label: 'Type IIA - Protective, Non-combustible',
        value: 'Type IIA - Protective, Non-combustible'
      },
      {
        label: 'Type IIB - Unprotective, Non-combustible',
        value: 'Type IIB - Unprotective, Non-combustible'
      },
      {
        label: 'Type IIIA - Protected Ordinary',
        value: 'Type IIIA - Protected Ordinary'
      },
      {
        label: 'Type IIIB - Unprotected Ordinary',
        value: 'Type IIIB - Unprotected Ordinary'
      },
      {
        label: 'Type IV - Heavy Timber',
        value: 'Type IV - Heavy Timber'
      },
      {
        label: 'Type VA - Protected Combustible',
        value: 'Type VA - Protected Combustible'
      },
      {
        label: 'Type VB - Unprotected Combustible',
        value: 'Type VB - Unprotected Combustible'
      }
    ],
    required: false
  },
  {
    label: 'High Hazard',
    id: 'isHighHazard',
    name: 'isHighHazard',
    type: 'checkbox',
    description: 'Highlight as a high risk/hazardous location'
  },
  {
    label: 'High Hazard Description',
    id: 'highHazardDescription',
    name: 'highHazardDescription',
    type: 'text',
    description: 'Max 50 characters.',
    conditional: {
      name: 'isHighHazard',
      condition: (value) => value === 'true' || value === true
    }
  },
  {
    label: 'Commercial',
    id: 'commercialStructure',
    name: 'commercialStructure',
    type: 'checkbox',
    description: 'Define as Commercial Location for ISO'
  },
  {
    label: 'Do Not Share',
    id: 'doNotShare',
    name: 'doNotShare',
    type: 'checkbox',
    description: 'Do Not Share'
  },
  {
    label: 'Roof Type',
    id: 'roofType',
    name: 'roofType',
    type: 'select',
    options: [
      {
        label: 'Select Roof Type',
        value: ''
      },
      {
        label: 'Bonnet',
        value: 'Bonnet'
      },
      {
        label: 'Bowstring Truss',
        value: 'Bowstring Truss'
      },
      {
        label: 'Butterfly',
        value: 'Butterfly'
      },
      {
        label: 'Combination',
        value: 'Combination'
      },
      {
        label: 'Curved',
        value: 'Curved'
      },
      {
        label: 'Dome',
        value: 'Dome'
      },
      {
        label: 'Flat',
        value: 'Flat'
      },
      {
        label: 'Gable',
        value: 'Gable'
      },
      {
        label: 'Gambrel',
        value: 'Gambrel'
      },
      {
        label: 'Hip',
        value: 'Hip'
      },
      {
        label: 'Jerkinhead',
        value: 'Jerkinhead'
      },
      {
        label: 'Mansard',
        value: 'Mansard'
      },
      {
        label: 'Pyramid',
        value: 'Pyramid'
      },
      {
        label: 'Saltbox',
        value: 'Saltbox'
      },
      {
        label: 'Sawtooth',
        value: 'Sawtooth'
      },
      {
        label: 'Skillion',
        value: 'Skillion'
      }
    ],
    required: false
  },
  {
    label: 'Roof Construction',
    id: 'roofConstruction',
    name: 'roofConstruction',
    type: 'select',
    options: [
      {
        label: 'Select Roof Construction',
        value: ''
      },
      {
        label: 'Beam - Concrete',
        value: 'Beam - Concrete'
      },
      {
        label: 'Beam - Steel',
        value: 'Beam - Steel'
      },
      {
        label: 'Beam - Wood',
        value: 'Beam - Wood'
      },
      {
        label: 'Steel Truss - Open Web',
        value: 'Steel Truss - Open Web'
      },
      {
        label: 'Wood / Steel - Closed Web',
        value: 'Wood / Steel - Closed Web'
      },
      {
        label: 'Wood / Steel - Open Web',
        value: 'Wood / Steel - Open Web'
      },
      {
        label: 'Wood Truss - Closed Web',
        value: 'Wood Truss - Closed Web'
      },
      {
        label: 'Wood Truss - Open Web',
        value: 'Wood Truss - Open Web'
      }
    ],
    required: false
  },
  {
    label: 'Roof Material',
    id: 'roofMaterial',
    name: 'roofMaterial',
    type: 'select',
    options: [
      {
        label: 'Select Roof Material',
        value: ''
      },
      {
        label: 'Built-Up',
        value: 'Built-Up'
      },
      {
        label: 'Composition Shingles',
        value: 'Composition Shingles'
      },
      {
        label: 'Membrane - Plastic elastomer',
        value: 'Membrane - Plastic elastomer'
      },
      {
        label: 'Membrane - Synthetic elastomer',
        value: 'Membrane - Synthetic elastomer'
      },
      {
        label: 'Metal',
        value: 'Metal'
      },
      {
        label: 'Metal - Corrugated',
        value: 'Metal - Corrugated'
      },
      {
        label: 'Metal - Shake',
        value: 'Metal - Shake'
      },
      {
        label: 'Metal - Standing Seam',
        value: 'Metal - Standing Seam'
      },
      {
        label: 'Roof Covering Not Class',
        value: 'Roof Covering Not Class'
      },
      {
        label: 'Roof Covering Undetermined/Not Reported',
        value: 'Roof Covering Undetermined/Not Reported'
      },
      {
        label: 'Shingle - Asphalt / Composition',
        value: 'Shingle - Asphalt / Composition'
      },
      {
        label: 'Slate - Composition',
        value: 'Slate - Composition'
      },
      {
        label: 'Slate - Natural',
        value: 'Slate - Natural'
      },
      {
        label: 'Structure Without Roof',
        value: 'Structure Without Roof'
      },
      {
        label: 'Tile - Clay',
        value: 'Tile - Clay'
      },
      {
        label: 'Tile - Concrete',
        value: 'Tile - Concrete'
      },
      {
        label: 'Tile (clay, cement, slate, etc.)',
        value: 'Tile (clay, cement, slate, etc.)'
      },
      {
        label: 'Wood - Shingle/Shake',
        value: 'Wood - Shingle/Shake'
      },
      {
        label: 'Wood Shakes/Shingles (Treated)',
        value: 'Wood Shakes/Shingles (Treated)'
      },
      {
        label: 'Wood Shakes/Shingles (Untreated)',
        value: 'Wood Shakes/Shingles (Untreated)'
      }
    ],
    required: false
  },
  {
    label: 'Sprinklered',
    id: 'sprinklered',
    name: 'sprinklered',
    type: 'select',
    options: [
      {
        label: 'Select Sprinkler Type',
        value: ''
      },
      {
        label: 'Dry System',
        value: 'Dry System'
      },
      {
        label: 'Wet System',
        value: 'Wet System'
      },
      {
        label: 'Both',
        value: 'Both'
      },
      {
        label: 'None',
        value: 'None'
      }
    ],
    required: false
  },
  {
    label: 'Stand Pipe',
    id: 'standPipe',
    name: 'standPipe',
    type: 'select',
    options: [
      {
        label: 'Select Stand Pipe',
        value: ''
      },
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      }
    ],
    required: false
  },
  {
    label: 'Fire Alarm',
    id: 'fireAlarm',
    name: 'fireAlarm',
    type: 'select',
    options: [
      {
        label: 'Select Fire Alarm',
        value: ''
      },
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      }
    ],
    required: false
  },
  {
    label: 'Normal Population',
    id: 'normalPopulation',
    name: 'normalPopulation',
    type: 'select',
    options: [
      {
        label: 'Select Normal Population',
        value: ''
      },
      {
        label: 'Vacant',
        value: 'Vacant'
      },
      {
        label: '1 - 10',
        value: '1 - 10'
      },
      {
        label: '11 - 50',
        value: '11 - 50'
      },
      {
        label: '51 - 100',
        value: '51 - 100'
      },
      {
        label: '101 - 500',
        value: '101 - 500'
      },
      {
        label: '501 - 1000',
        value: '501 - 1000'
      }
    ],
    required: false
  },
  {
    label: 'Hours Of Operation',
    id: 'hoursOfOperation',
    name: 'hoursOfOperation',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Owner Contact',
    id: 'ownerContact',
    name: 'ownerContact',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Owner Phone',
    id: 'ownerPhone',
    name: 'ownerPhone',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Notes (Optional)',
    id: 'notes',
    name: 'notes',
    type: 'textarea',
    placeholder: ''
  },
];


export const StructureForm = ({
  structure,
  action,
  closeModal
}) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();
  const selectedHydrantIds = useSelector(state => state.hydrants.selectedHydrantIds);

  const dispatchAddStructure = async(structureAdd) => {
    const result = await dispatch(addStructure(structureAdd));
    if (addStructure.fulfilled.match(result)) {
      setSelectedStructureId(result.payload.id); // TODO this doesn't work
      setSelectedHydrants(result.payload.hydrants || []);
      onSuccess('Success', 'Struture added');
    } else {
      onError('Failed to add structure');
    }
  };

  const dispatchEditStructure = async(id, structurePatch) => {
    const result = await dispatch(editStructure({ structureId: id, structurePatch: structurePatch }));
    if (editStructure.fulfilled.match(result)) {
      onSuccess('Success', 'Location updated');
    } else {
      onError('Failed to update location', 'Please check location fields and try again.');
    }
  };

  const dispatchDeleteStructure = async(id) => {
    const result = await dispatch(deleteStructure(id));
    if (deleteStructure.fulfilled.match(result)) {
      onSuccess('Success', 'Location deleted');
    } else {
      onError('Cannot Delete Location');
    }
  };

  const onSubmit = (data) => {
    if (action === 'ADD_STRUCTURE') {
      const structureAdd = {
        geoOutline: structure.geoOutline,
        roofArea: structure.roofArea,
        requiredFlow: structure.requiredFlow,
        hydrants: structure.hydrants,
      };
      data.label && (structureAdd.label = data.label);
      data.name && (structureAdd.name = data.name);
      if (
        data.address1 ||
        data.address2 ||
        data.city ||
        data.state ||
        data.zip
      ) {
        structureAdd.address = {};
        data.address1 && (structureAdd.address.address1 = data.address1);
        data.address2 && (structureAdd.address.address2 = data.address2);
        data.city && (structureAdd.address.city = data.city);
        data.state && (structureAdd.address.state = data.state);
        data.zip && (structureAdd.address.zip = data.zip);
      }
      data.isisHighHazard && (structureAdd.isHighHazard = data.isHighHazard);
      data.highHazardDescription && (structureAdd.highHazardDescription = data.highHazardDescription);
      data.commercialStructure && (structureAdd.commercialStructure = data.commercialStructure);
      data.doNotShare && (structureAdd.doNotShare = data.doNotShare);
      data.notes && (structureAdd.notes = data.notes);
      if (
        data.occupancyType ||
        data.constructionType ||
        data.roofType ||
        data.roofConstruction ||
        data.roofMaterial ||
        data.sprinklered ||
        data.standPipe ||
        data.fireAlarm ||
        data.normalPopulation ||
        data.hoursOfOperation ||
        data.ownerContact ||
        data.ownerPhone
      ) {
        structureAdd.building = {};
        data.occupancyType && (structureAdd.building.occupancyType = data.occupancyType);
        data.constructionType && (structureAdd.building.constructionType = data.constructionType);
        data.roofType && (structureAdd.building.roofType = data.roofType);
        data.roofConstruction && (structureAdd.building.roofConstruction = data.roofConstruction);
        data.roofMaterial && (structureAdd.building.roofMaterial = data.roofMaterial);
        data.sprinklered && (structureAdd.building.sprinklered = data.sprinklered);
        data.standPipe && (structureAdd.building.standPipe = data.standPipe);
        data.fireAlarm && (structureAdd.building.fireAlarm = data.fireAlarm);
        data.normalPopulation && (structureAdd.building.normalPopulation = data.normalPopulation);
        data.hoursOfOperation && (structureAdd.building.hoursOfOperation = data.hoursOfOperation);
        data.ownerContact && (structureAdd.building.ownerContact = data.ownerContact);
        data.ownerPhone && (structureAdd.building.ownerPhone = data.ownerPhone);
      }

      return dispatchAddStructure(structureAdd);
    } else if (action === 'EDIT_STRUCTURE') {
      const structurePatch = [
        {
          op: 'replace',
          path: '/hydrants',
          value: selectedHydrantIds
        },
      ];
      (data.label || structure.label) && structurePatch.push({
        op: 'replace',
        path: '/label',
        value: data.label
      });
      (data.name || structure.name) && structurePatch.push({
        op: 'replace',
        path: '/name',
        value: data.name
      });
      (data.address1 || structure.address?.address1) && structurePatch.push({
        op: 'replace',
        path: '/address/address1',
        value: data.address1
      });
      (data.address2 || structure.address?.address2) && structurePatch.push({
        op: 'replace',
        path: '/address/address2',
        value: data.address2
      });
      (data.city || structure.address?.city) && structurePatch.push({
        op: 'replace',
        path: '/address/city',
        value: data.city
      });
      (data.state || structure.address?.state) && structurePatch.push({
        op: 'replace',
        path: '/address/state',
        value: data.state
      });
      (data.zip || structure.address?.zip) && structurePatch.push({
        op: 'replace',
        path: '/address/zip',
        value: data.zip
      });
      (data.occupancyType || structure.building?.occupancyType) && structurePatch.push({
        op: 'replace',
        path: '/building/occupancyType',
        value: data.occupancyType
      });
      (data.constructionType || structure.building?.constructionType) && structurePatch.push({
        op: 'replace',
        path: '/building/constructionType',
        value: data.constructionType
      });
      (data.isHighHazard || structure.isHighHazard) && structurePatch.push({
        op: 'replace',
        path: '/isHighHazard',
        value: data.isHighHazard || false,
      });
      (data.highHazardDescription || structure.highHazardDescription) && structurePatch.push({
        op: 'replace',
        path: '/highHazardDescription',
        value: data.highHazardDescription,
      });
      (data.commercialStructure || structure.commercialStructure) && structurePatch.push({
        op: 'replace',
        path: '/commercialStructure',
        value: data.commercialStructure || false,
      });
      (data.doNotShare || structure.doNotShare) && structurePatch.push({
        op: 'replace',
        path: '/doNotShare',
        value: data.doNotShare || false,
      });
      (data.roofType || structure.building?.roofType) && structurePatch.push({
        op: 'replace',
        path: '/building/roofType',
        value: data.roofType
      });
      (data.roofConstruction || structure.building?.roofConstruction) && structurePatch.push({
        op: 'replace',
        path: '/building/roofConstruction',
        value: data.roofConstruction
      });
      (data.roofMaterial || structure.building?.roofMaterial) && structurePatch.push({
        op: 'replace',
        path: '/building/roofMaterial',
        value: data.roofMaterial
      });
      (data.sprinklered || structure.building?.sprinklered) && structurePatch.push({
        op: 'replace',
        path: '/building/sprinklered',
        value: data.sprinklered
      });
      (data.standPipe || structure.building?.standPipe) && structurePatch.push({
        op: 'replace',
        path: '/building/standPipe',
        value: data.standPipe
      });
      (data.fireAlarm || structure.building?.fireAlarm) && structurePatch.push({
        op: 'replace',
        path: '/building/fireAlarm',
        value: data.fireAlarm
      });
      (data.normalPopulation || structure.building?.normalPopulation) && structurePatch.push({
        op: 'replace',
        path: '/building/normalPopulation',
        value: data.normalPopulation
      });
      (data.hoursOfOperation || structure.building?.hoursOfOperation) && structurePatch.push({
        op: 'replace',
        path: '/building/hoursOfOperation',
        value: data.hoursOfOperation
      });
      (data.ownerContact || structure.building?.ownerContact) && structurePatch.push({
        op: 'replace',
        path: '/building/ownerContact',
        value: data.ownerContact
      });
      (data.ownerPhone || structure.building?.ownerPhone) && structurePatch.push({
        op: 'replace',
        path: '/building/ownerPhone',
        value: data.ownerPhone
      });
      (data.notes || structure.notes) && structurePatch.push({
        op: 'replace',
        path: '/notes',
        value: data.notes
      });
      return dispatchEditStructure(structure.id, structurePatch);
    } else if (action === 'DELETE_STRUCTURE') {
      return dispatchDeleteStructure(structure.id);
    }
    return Promise.resolve();
  };

  const onError = (...p) => onComplete('error', ...p);

  const onSuccess = (...p) => {
    closeModal();
    onComplete('success', ...p);
  };

  const onComplete = (type, title, body) => {
    toast({
      title: title,
      position: 'top',
      description: body,
      status: type,
      duration: 2500,
      isClosable: true
    });
  };

  const onCancel = () => {
    methods.reset();
    closeModal();
  };

  const editData = useMemo(() => {
    if (!structure) return {};
    if (action === "ADD_STRUCTURE") {
      const data = {
      };
      if (structure.address) {
        data.address1 = structure.address.address1;
        data.address2 = structure.address.address2;
        data.city = structure.address.city;
        data.state = structure.address.state;
        data.zip = structure.address.zip;
      }
      return data;
    } else {
      const data = {
        label: structure.label,
        name: structure.name,
        isHighHazard: structure.isHighHazard,
        highHazardDescription: structure.highHazardDescription,
        commercialStructure: structure.commercialStructure,
        doNotShare: structure.doNotShare,
        notes: structure.notes,
      };
      if (structure.address) {
        data.address1 = structure.address.address1;
        data.address2 = structure.address.address2;
        data.city = structure.address.city;
        data.state = structure.address.state;
        data.zip = structure.address.zip;
      }
      if (structure.building) {
        data.occupancyType = structure.building.occupancyType;
        data.constructionType = structure.building.constructionType;
        data.roofType = structure.building.roofType;
        data.roofConstruction = structure.building.roofConstruction;
        data.roofMaterial = structure.building.roofMaterial;
        data.sprinklered = structure.building.sprinklered;
        data.standPipe = structure.building.standPipe;
        data.fireAlarm = structure.building.fireAlarm;
        data.normalPopulation = structure.building.normalPopulation;
        data.hoursOfOperation = structure.building.hoursOfOperation;
        data.ownerContact = structure.building.ownerContact;
        data.ownerPhone = structure.building.ownerPhone;
      }
      return data;
    }  
  }, [structure, action]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {(action === 'DELETE_STRUCTURE') ? (
          <DeleteForm name={structure?.name} onCancel={onCancel} />
        ) : (
          <EditForm data={editData} onCancel={onCancel} />
        )}
      </form>
    </FormProvider>
  );
};

const DeleteForm = (props) => {
  const methods = useFormContext();

  return (
    <Stack spacing="1rem">
      <Text>Are you sure you want to delete <strong>{ props.name || "this location"}</strong>?</Text>
      <Text>All occupants in this Pre-Plan will be deleted and this action can’t be undone.</Text>
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={props.onCancel} width="8rem">No</OutlineButton>
        <SubmitButtonDanger isLoading={methods.formState.isSubmitting} width="8rem">Delete</SubmitButtonDanger>
      </HStack>
    </Stack>
  );
};

const EditForm = ({
  onCancel,
  data
}) => {
  const methods = useFormContext();

  return (
    <Stack spacing="1rem">
      <FormComponent formFields={STRUCTURE_FORM_FIELDS} data={data} />
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={onCancel} width="8rem">Cancel</OutlineButton>
        <SubmitButton isLoading={methods.formState.isSubmitting} width="8rem">Save</SubmitButton>
      </HStack>
    </Stack>
  );

};
