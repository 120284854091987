import axios from "axios";
import config from "../../config.js";
import { processLoginUserData } from "./SessionAPI";
import { OAuthError } from "../helpers/Oidc";

/**
 * @typedef {Object} CustomerOidc
 * @property {string} issuer
 * @property {string} clientId
 * @property {string} [redirectUri]
 * @property {string} [authorizationEndpoint]
 * @property {string} [tokenEndpoint]
 */

/**
 * @param {string} slug
 * @returns {Promise<{ issuer?: string; clientId?: string; redirectUri?: string; authorizationEndpoint?: string; tokenEndpoint?: string;} | undefined>}
 */
export async function getDepartmentOidc(slug) {
  if (!slug) {
    return undefined;
  }

  let data;
  try {
    const response = await axios.get(`${config.API_BASE_URL}/api/${encodeURIComponent(slug)}/oidc`);
    data = response.data;
  } catch (err) {
    console.error("Failed to get OIDC settings", err);
    data = undefined;
  }
  if (!data) {
    return undefined;
  }
  return {
    issuer: data.issuer,
    clientId: data.clientId,
    redirectUri: data.redirectUri,
    authorizationEndpoint: data.authorizationEndpoint,
    tokenEndpoint: data.tokenEndpoint,
  };
}

/**
 * @param {Function} dispatch
 * @param {string} slug
 * @param {string} idToken
 * @param {{persistLogin?: boolean}} options
 */
export async function loginUserByIdToken(dispatch, slug, idToken, options) {
  const requestData = new URLSearchParams({
    grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
    subject_token_type: "urn:ietf:params:oauth:token-type:id_token",
    subject_token: idToken,
    scope: "offline_access",
  });

  /** @type {string} */
  let accessToken;
  /** @type {any[]} */
  let links;
  /** @type {string} */
  let dataFormat;
  try {
    const response = await axios({
      method: "post",
      url: `${config.API_BASE_URL}/api/${encodeURIComponent(slug)}/auth/token`,
      data: requestData,
    });
    if (response.data?.error || response.data?.error_description || response.status < 200 || response.status > 299) {
      const message = response.data?.error_description ?? "Something went wrong. Please try again.";
      throw new OAuthError(message, response.error);
    }
    accessToken = response.data.access_token;
    links = response.data.links;
    dataFormat = response.data.dataFormat;
  } catch (err) {
    const message =
      err.response && err.response.data ? err.response.data.message ?? err.response.data.error_description : "Something went wrong. Please try again.";
    throw new OAuthError(message, err.response?.data?.error);
  }

  const promise = new Promise((resolve, reject) => {
    processLoginUserData(dispatch, { dataFormat, accessToken, tokenType: "Bearer", links }, options, resolve, reject);
  });
  await promise;
}

/**
 * @param {{ email: string; firstName: string; lastName: string }} data
 * @param {string} slug
 * @param {Function} [onSuccess]
 * @param {Function} [onError]
 */
export async function submitOidcSignUp(data, slug, onSuccess, onError) {
  try {
    const response = await axios.post(`${config.API_BASE_URL}/api/${encodeURIComponent(slug)}/oidc/registerUser`, data, {});
    if (response.data?.error || response.data?.error_description || response.status < 200 || response.status > 299) {
      const message = response.data?.error_description ?? "Something went wrong. Please try again.";
      throw new OAuthError(message, response.error);
    }
    if (onSuccess) onSuccess();
  } catch (err) {
    /** @type {OAuthError} */
    let oauthErr;
    if (OAuthError.isOAuthError(err)) {
      oauthErr = err;
    } else {
      const message =
        err.response && err.response.data ? err.response.data.message ?? err.response.data.error_description : "Something went wrong. Please try again.";
      oauthErr = new OAuthError(message, err.response?.data?.error);
    }
    if (onError) {
      onError(oauthErr);
    } else {
      throw oauthErr;
    }
  }
}

/**
 * @param {{email: string; password: string}} data
 * @param {string} slug
 * @param {Function} [onSuccess]
 * @param {Function} [onError]
 */
export async function submitOidcLinkUser(data, slug, onSuccess, onError) {
  try {
    const { password, ...otherData } = data;
    const credString = btoa(`${data.email}:${password}`);
    const tokenResponse = await axios.post(
      `${config.API_BASE_URL}/api/auth/token`,
      {},
      {
        headers: { Authorization: `Basic ${credString}` },
      }
    );
    const accessToken = tokenResponse.data?.accessToken;
    const errMsg = tokenResponse.data?.links?.find((obj) => obj.rel === "msg")?.href;
    if (!accessToken || errMsg) {
      throw new OAuthError(errMsg ?? "Authentication failed");
    }

    const response = await axios.post(`${config.API_BASE_URL}/api/${encodeURIComponent(slug)}/oidc/linkUser`, otherData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-FlowMSP-Source": "Web",
        "X-FlowMSP-Version": process.env.REACT_APP_VERSION,
      },
    });
    if (response.data?.error || response.data?.error_description || response.status < 200 || response.status > 299) {
      const message = response.data?.error_description ?? "Something went wrong. Please try again.";
      throw new OAuthError(message, response.error);
    }

    if (onSuccess) onSuccess();
  } catch (err) {
    /** @type {OAuthError} */
    let oauthErr;
    if (OAuthError.isOAuthError(err)) {
      oauthErr = err;
    } else {
      const message =
        err.response && err.response.data ? err.response.data.message ?? err.response.data.error_description : "Something went wrong. Please try again.";
      oauthErr = new OAuthError(message, err.response?.data?.error);
    }
    if (onError) {
      onError(oauthErr);
    } else {
      throw oauthErr;
    }
  }
}
