import React, { useMemo, useState } from 'react';
import {
  Flex,
  Stack,
  Text,
  Box,
  Heading,
  Divider,
  useToast,
  useColorModeValue,
  Switch
} from '@chakra-ui/react';


export const NotificationSettingsCard = ({ config, onSubmit, forAccount }) => {
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.25rem',
    color: 'gray.700'
  };
  const toast = useToast();

  const emailSelected = useMemo(() => (
    config?.assignmentDeliveryEmail === 'true'
  ), [config]);
  const pushSelected = useMemo(() => (
    config?.assignmentDeliveryPushNotification === 'true'
  ), [config]);

  const [loading, setLoading] = useState(false);

  const toggleEmail = () => {
    setLoading(true);
    submit(!emailSelected, pushSelected);
  };
  const togglePush = () => {
    setLoading(true);
    submit(emailSelected, !pushSelected);
  };

  const submit = async(email, push) => {
    const data = {
      assignmentDeliveryEmail: email ? 'true' : 'false',
      assignmentDeliveryPushNotification: push ? 'true' : 'false',
    };
    
    const result = await onSubmit(data);

    if (result) {
      toast({
        title: 'Success',
        position: 'top',
        description: 'Notification settings updated.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: 'Failed to update notification settings. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoading(false);
  };

  return (
    <Box maxWidth="32rem" bg={useColorModeValue('white', 'gray.900')} p="1rem">
      <Flex align="center" justify="space-between">
        <Heading as="h4" size="md">Assignments Notification Settings</Heading>
      </Flex>
      <Divider my="0.5rem" />
      {!config && <Text>Loading...</Text>}
      {config && (
        <Box>
          <Stack spacing="0.5rem">
            <Flex justifyContent="space-between">
              <Text {...labelStyle}>Receive Email{ forAccount ? " (Applies to All Users)" : ""}</Text>
              <Switch isDisabled={loading} isChecked={emailSelected} onChange={toggleEmail} />
            </Flex>
            <Divider />
            <Flex justifyContent="space-between">
              <Text {...labelStyle}>Receive Push Notification{ forAccount ? " (Applies to All Users)" : ""}</Text>
              <Switch isDisabled={loading} isChecked={pushSelected} onChange={togglePush} />
            </Flex>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
