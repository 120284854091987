import React, { useMemo, useState } from 'react';

import {
  Flex,
  Icon,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { OutlineButton } from '../form/Button';
import AddIcon from '../FigmaExport/AddIcon';
import { FloorComponent } from './FloorComponent';
import ModalComponent from './ModalComponent';
import { OccupantForm } from '../form/OccupantForm';
import { AddFloorForm } from '../form/AddFloorForm';
import ListIcon from '../FigmaExport/ListIcon';
import { DebouncedInput } from './DebouncedInput';
import { DeleteFloorForm } from '../form/DeleteFloorForm';
import { ReOrderFloorsForm } from '../form/ReOrderFloorsForm';
import { useSelector } from 'react-redux';
import { userRoleSelector } from '../../features/user/userSlice';
import { ReadOnlyBanner } from './ReadOnlyBanner';
import DeleteIcon from '../FigmaExport/DeleteIcon';


const OccupantsPanel = ({
  selectedStructure,
  floors,
}) => {
  const DEFAULT_MODAL_STATE = {
    showModal: false,
    modal: {
      heading: null,
      body: null,
    },
  };
  const [modalState, setModalState] = useState(DEFAULT_MODAL_STATE);

  const closeModal = () => {
    setModalState(DEFAULT_MODAL_STATE);
  };

  const userRole = useSelector(userRoleSelector);

  const canEdit = useMemo(() => {
    return (userRole === "ADMIN" || userRole === "PLANNER") &&
      (!selectedStructure?.readOnly) &&
      (selectedStructure?.isMine);
  }, [userRole, selectedStructure]);

  const reason = useMemo(() => {
    if (selectedStructure?.readOnly) {
      return "Editing is disabled. Visit Elite to change.";
    }
    if (selectedStructure && !selectedStructure.isMine) {
      return "Partner Data can't be edited.";
    }
    if (!(userRole === "ADMIN" || userRole === "PLANNER")) {
      return "Only ADMIN or PLANNER users can edit.";
    }
    return null;
  }, [userRole, selectedStructure]);

  const handleAddOccupant = (structure, convert=false, floor=null) => {
    const occupant = {
      name: convert ? structure.name : null,
      structureId: structure.id,
      address1: convert ? structure.address.address1 : null,
      address2: convert ? structure.address.address2 : null,
      city: convert ? structure.address.city : null,
      state: convert ? structure.address.state : null,
      zip: convert ? structure.address.zip : null,
      occupancyType: convert ? structure.occupancyType : null,
      sprinklered: convert ? structure.sprinklered : null,
      normalPopulation: convert ? structure.normalPopulation : null,
      ownerContact: convert ? structure.ownerContact : null,
      ownerPhone: convert ? structure.ownerPhone : null,
      notes: convert ? structure.notes : null,
      floor: floor || null,
    };

    setModalState({
      showModal: true,
      modal: {
        heading: 'Add Occupant',
        body: (
          <OccupantForm
            occupant={occupant}
            action="ADD_OCCUPANT"
            closeModal={closeModal}
            floors={floors}
          />
        )
      },
    });
  };

  const handleEditOccupant = (occupant) => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Edit Occupant',
        body: (
          <OccupantForm
            occupant={occupant}
            action="EDIT_OCCUPANT"
            closeModal={closeModal}
            floors={floors}
          />
        )
      },
    });
  };

  const handleDeleteOccupant = (occupant) => {
    setModalState({
      showModal: true,
      modal: {
        heading: <Text><Icon as={DeleteIcon} fill="black" mr="4px" />Delete Occupant</Text>,
        body: (
          <OccupantForm
            occupant={occupant}
            action="DELETE_OCCUPANT"
            closeModal={closeModal}
            floors={floors}
          />
        )
      },
    });
  };

  const handleAddFloor = () => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Add Floor',
        body: (
          <AddFloorForm
            selectedStructure={selectedStructure}
            floors={floors}
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleDeleteFloor = (name) => {
    setModalState({
      showModal: true,
      modal: {
        heading: <Text><Icon as={DeleteIcon} fill="black" mr="4px" />Delete Floor</Text>,
        body: (
          <DeleteFloorForm
            selectedStructure={selectedStructure}
            floors={floors}
            name={name}
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleReOrderFloors = () => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Reorder Floors',
        body: (
          <ReOrderFloorsForm
            selectedStructure={selectedStructure}
            floors={floors}
            closeModal={closeModal}
          />
        )
      },
    });
  };

  return (selectedStructure && (
    <Stack width="100%" overflow='hidden'>
      <ModalComponent
        showModal={modalState.showModal}
        toggleModal={closeModal}
        modal={modalState.modal}
      />
      { selectedStructure?.readOnly && <ReadOnlyBanner name="Elite" /> }
      <Flex gap="8px" >
        <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
          <Flex>
            <OutlineButton
              fontSize="sm"
              color="#2C62CB"
              borderColor="#2C62CB"
              leftIcon={<ListIcon fill="#2C62CB" />}
              iconSpacing="auto"
              onClick={() => handleReOrderFloors()}
              isDisabled={!canEdit}
            />
          </Flex>
        </Tooltip>
        <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
          <Flex flexGrow={1}>
            <OutlineButton
              fontSize="sm"
              color="#2C62CB"
              borderColor="#2C62CB"
              leftIcon={<AddIcon fill="#2C62CB" />}
              onClick={() => handleAddOccupant(selectedStructure, false)}
              flexGrow={1}
              isDisabled={!canEdit}
            >
              Add Occupant
            </OutlineButton>
          </Flex>
        </Tooltip>
        <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
          <Flex flexGrow={1}>
            <OutlineButton
              fontSize="sm"
              color="#2C62CB"
              borderColor="#2C62CB"
              leftIcon={<AddIcon fill="#2C62CB" />}
              onClick={() => handleAddFloor(selectedStructure)}
              flexGrow={1}
              isDisabled={!canEdit}
            >
              Add Floor
            </OutlineButton>
          </Flex>
        </Tooltip>
      </Flex>
      <DebouncedInput
        value={''}
        onChange={value => {}}
        placeholder="Search"
        isDisabled
      />
      {floors.map((floor, index) => (
        (floor.name || floor.occupants?.length) && <FloorComponent
          key={index}
          floor={floor}
          handleAddOccupant={handleAddOccupant}
          selectedStructure={selectedStructure}
          handleEditOccupant={handleEditOccupant}
          handleDeleteOccupant={handleDeleteOccupant}
          handleDeleteFloor={handleDeleteFloor}
          canEdit={canEdit}
          reason={reason}
        />
      ))}
      {floors.length < 2 && !floors?.[0].occupants?.length && <Text textAlign="center">
        No occupants added.
      </Text>}
    </Stack>
  ));
};


export default OccupantsPanel;
