import {
  debouncedSyncDashboardFilterValues,
  debouncedSyncDashboardColumnsVisibility,
  debouncedSyncFiltersAndColumns
} from '../../features/user/userSlice';

export const userConfigSyncMiddleware = ({ dispatch }) => next => action => {
  if (action.type === 'user/setDashboardFilterValues') {
    debouncedSyncDashboardFilterValues(dispatch);
  } else if (action.type === 'user/setDashboardColumnsVisibility') {
    debouncedSyncDashboardColumnsVisibility(dispatch);
  } else if (action.type === 'user/resetFilters' || action.type === 'user/resetColumns') {
    debouncedSyncFiltersAndColumns(dispatch);
  }

  return next(action);
};
