import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as propertyAPI from '../../api/PropertyAPI';

// Thunks
export const getAllProperties = createAsyncThunk(
  'properties/getAllProperties',
  async(data, thunkAPI) => {
    const response = await propertyAPI.getAllProperties();
    return response.data;
  }
);

export const getPropertyById = createAsyncThunk(
  'properties/getPropertyById',
  async(data, thunkAPI) => {
    const response = await propertyAPI.getPropertyById(data);
    return response.data;
  }
);

export const addProperty = createAsyncThunk(
  'properties/addProperty',
  async(data, thunkAPI) => {
    const response = await propertyAPI.addProperty(data);
    return response.data;
  }
);

export const editProperty = createAsyncThunk(
  'properties/editProperty',
  async(data, thunkAPI) => {
    const response = await propertyAPI.editProperty(data.propertyId, data.propertyPatch);
    return response.data;
  }
);

export const deleteProperty = createAsyncThunk(
  'properties/deleteProperty',
  async(data, thunkAPI) => {
    const response = await propertyAPI.deleteProperty(data);
    return { ...response.data, id: data };
  }
);

export const addImageToProperty = createAsyncThunk(
  'properties/addImageToProperty',
  async(data, thunkAPI) => {
    const response = await propertyAPI.addImageToProperty(data.propertyId, data.imageId);
    return response.data;
  }
);

export const removeImageFromProperty = createAsyncThunk(
  'properties/removeImageFromProperty',
  async(data, thunkAPI) => {
    const response = await propertyAPI.removeImageFromProperty(data.propertyId, data.imageId);
    return response.data;
  }
);

export const fetchPartnerProperties = createAsyncThunk(
  'properties/fetchPartnerProperties',
  async(partnerId, thunkAPI) => {
    const response = await propertyAPI.fetchPartnerProperties(partnerId);
    return response.data;
  }
);


// Adapter & State
export const propertiesAdapter = createEntityAdapter();
const initialState = propertiesAdapter.getInitialState({
  loading: 'idle',
  error: null,
  lastFetch: null,
  selectedPropertyId: null,
  filters: [],
  filteredPropertyIds: [],
  partnerLoading: 'idle',
  lastPartnerFetch: null,
});

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    // propertiesLoading: (state, action) => {
    //   if (state.loading === 'idle') {
    //     state.loading = 'pending';
    //   }
    // },
    // propertiesReceived: (state, action) => {
    //   if (state.loading === 'pending') {
    //     state.loading = 'idle';
    //     state.entities = action.payload;
    //   }
    // },
    setSelectedPropertyId: (state, action) => {
      state.selectedPropertyId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getAllProperties
    builder
      .addCase(getAllProperties.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(getAllProperties.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.lastFetch = Date.now();
        if (action.payload && action.payload.length > 0) {
          propertiesAdapter.setAll(state, action.payload.map(property => ({ ...property, isMine: true })));
        }
      })
      .addCase(getAllProperties.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.lastFetch = Date.now();
        }
      });
    // getPropertyById
    builder
      .addCase(getPropertyById.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(getPropertyById.fulfilled, (state, action) => {
        state.loading = 'idle';
        propertiesAdapter.setOne(state, { ...action.payload, isMine: true });
      })
      .addCase(getPropertyById.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // addProperty
    builder
      .addCase(addProperty.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(addProperty.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          propertiesAdapter.addOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(addProperty.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // editProperty
    builder
      .addCase(editProperty.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(editProperty.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          propertiesAdapter.setOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(editProperty.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // deleteProperty
    builder
      .addCase(deleteProperty.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          propertiesAdapter.removeOne(state, action.payload.id);
        }
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // addImageToProperty
    builder
      .addCase(addImageToProperty.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(addImageToProperty.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          propertiesAdapter.setOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(addImageToProperty.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // removeImageFromProperty
    builder
      .addCase(removeImageFromProperty.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(removeImageFromProperty.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          propertiesAdapter.setOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(removeImageFromProperty.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // fetchPartnerProperties
    builder
      .addCase(fetchPartnerProperties.pending, (state, action) => {
        if (state.partnerLoading === 'idle') {
          state.partnerLoading = 'pending';
        }
      })
      .addCase(fetchPartnerProperties.fulfilled, (state, action) => {
          state.partnerLoading = 'idle';
        if (action.payload.data) {
          propertiesAdapter.upsertMany(state, action.payload.data.map(property => ({ ...property, isMine: false })));
          state.lastPartnerFetch = Date.now();
        }
      })
      .addCase(fetchPartnerProperties.rejected, (state, action) => {
        if (
          state.partnerLoading === 'pending'
        ) {
          state.partnerLoading = 'idle';
          state.error = action.error;
        }
      });
  }
});


const { actions, reducer } = propertiesSlice;

export const {
  setSelectedPropertyId
} = actions;

export default reducer;


export const {
  selectById: selectPropertyById,
  selectAll: selectAllProperties,
  selectTotal: selectTotalProperties,
} = propertiesAdapter.getSelectors(state => state.properties);
