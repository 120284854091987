import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setSelectedStructureId } from "../features/structures/structuresSlice";

export default function StructureContainer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    // Based on the "View on map" link from PrePlanTableColumns.jsx.
    navigate("/", { replace: true });
    if (id) {
      // @todo Not like this: this is a hacky way to make sure map re-centers on location
      setTimeout(() => {
        dispatch(setSelectedStructureId(id));
      }, 100);
    }
  }, [id, navigate, dispatch]);
  return null;
}
