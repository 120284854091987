import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Box,
  Divider,
  Stack,
  Heading,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
} from '@chakra-ui/react';
import { Checkbox, RadioGroup } from '../form/FormWrapper';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const FilterOptions = z.object({
  excludePartner: z.boolean().default(false),
  selectBuildingInfo: z.string().default(''),
  commercial: z.boolean().default(false),
  vacant: z.boolean().default(false),
  sprinklered: z.boolean().default(false),
  nonSprinklered: z.boolean().default(false),
  withPictures: z.boolean().default(false),
  withoutPictures: z.boolean().default(false),
  trussRoof: z.boolean().default(false),
  standpipes: z.boolean().default(false),
  fireAlarm: z.boolean().default(false),
  multiFamily: z.boolean().default(false),
  special: z.boolean().default(false),
  selectPreplanAge: z.string().default(''),
  roofAreaMin: z.string().default(''),
  roofAreaMax: z.string().default('')
});

const buildingInfoSelect = {
  placeholder: 'Select Building Info',
  name: 'selectBuildingInfo',
  options: [
    {
      value: 'present',
      label: 'Present'
    },
    {
      value: 'notPresent',
      label: 'Not Present'
    }
  ]
};

const preplanAgeSelect = {
  placeholder: 'Select Preplan Age',
  name: 'selectPreplanAge',
  options: [
    {
      value: 'withinThirtyDays',
      label: 'Within 30 Days'
    },
    {
      value: 'olderThanAYear',
      label: 'Older than 1 Year'
    }
  ]
};

const buildingData = [
  {
    label: 'Commercial',
    value: 'commercial'
  },
  {
    label: 'Vacant',
    value: 'vacant'
  },
  {
    label: 'Sprinklered',
    value: 'sprinklered'
  },
  {
    label: 'Non-Sprinklered',
    value: 'nonSprinklered'
  },
  {
    label: 'With Pictures',
    value: 'withPictures'
  },
  {
    label: 'Without Pictures',
    value: 'withoutPictures'
  }
];

const buildingIncludes = [
  {
    label: 'Truss Roof',
    value: 'trussRoof'
  },
  {
    label: 'Standpipes',
    value: 'standpipes'
  },
  {
    label: 'Fire Alarms',
    value: 'fireAlarm'
  }
];

const structures = [
  {
    label: 'Multi-Family',
    value: 'multiFamily'
  },
  {
    label: 'Special',
    value: 'special'
  }
];

export const MapFilters = ({ isOpen, onClose, onFilterChange }) => {
  const methods = useForm({
    resolver: zodResolver(FilterOptions),
    defaultValues: FilterOptions.parse({})
  });

  const updateFilters = (data) => {
    const splitData = Object.keys(data).map(key => ({
      field: key,
      value: data[key]
    })).filter(item => (item.value && (item.value !== null) && (item.value !== undefined) && (item.value !== '')));
    onFilterChange(splitData);
    onClose();
  };

  const resetFilters = () => {
    methods.reset();
    onFilterChange([]);
    onClose();
  };


  return isOpen && (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={null}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          Data Filters
        </DrawerHeader>
        <DrawerBody>
          <Box>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(updateFilters)}>
                <Stack spacing="1.5rem">
                  <Checkbox
                    label="Exclude Partner Locations and Hydrants"
                    name="excludePartner"
                  />
                  <RadioGroup groupName={'Building Info'} direction={'row'} {...buildingInfoSelect} />
                  <Stack spacing="0.5rem">
                    <Heading as="h5" size="sm">Building Data</Heading>
                    {buildingData.map(item => (
                      <Checkbox key={`checkbox-${item.value}`} name={item.value} {...item} />
                    ))}
                  </Stack>
                  <Stack spacing="0.5rem">
                    <Heading as="h5" size="sm">Building Includes</Heading>
                    {buildingIncludes.map(item => (
                      <Checkbox key={`checkbox-${item.value}`} name={item.value} {...item} />
                    ))}
                  </Stack>
                  <Stack spacing="0.5rem">
                    <Heading as="h5" size="sm">Locations</Heading>
                    {structures.map(item => (
                      <Checkbox key={`checkbox-${item.value}`} name={item.value} {...item} />
                    ))}
                  </Stack>
                  <Stack spacing="0.5rem">
                    <Heading as="h5" size="sm">Roof Area (ft&sup2;)</Heading>
                    <Stack direction="row">
                      <Text fontSize="sm">Min</Text>
                      <NumberInput size="sm" >
                        <NumberInputField {...methods.register('roofAreaMin')} />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <Text fontSize="sm">Max</Text>
                      <NumberInput size="sm" >
                        <NumberInputField {...methods.register('roofAreaMax')} />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Stack>
                  </Stack>
                  <RadioGroup direction="row" groupName="Preplan Age" {...preplanAgeSelect} />
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Button
                      width="8rem"
                      bg={'gray.400'}
                      color={'white'}
                      _hover={{
                        bg: 'gray.500',
                      }}
                      isLoading={methods.formState.isSubmitting}
                      onClick={resetFilters}
                    >
                      Clear
                    </Button>
                    <Button
                      width="8rem"
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      type="submit"
                      isLoading={methods.formState.isSubmitting}
                    >
                      Apply
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </FormProvider>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
