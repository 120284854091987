import React, { useState, useEffect, useMemo } from 'react';

import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
  Tab,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { editStructure, selectStructureById } from '../../features/structures/structuresSlice';
import { selectAllOccupants } from '../../features/occupants/occupantsSlice';
import { selectAllProperties } from '../../features/properties/propertiesSlice';
import StructureDataPanel from './StructureDataPanel';
import OccupantsPanel from './OccupantsPanel';


export const PrePlanPanel = (props) => {
  const { selectedStructureId } = useSelector(v => v.structures);
  const selectedStructure = useSelector(state => selectStructureById(state, selectedStructureId));
  const allProperties = useSelector(selectAllProperties);
  const allOccupants = useSelector(selectAllOccupants);
  const [property, setProperty] = useState(null);
  const [occupants, setOccupants] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedStructureId || !selectedStructure || !selectedStructure.propertyId || !allProperties) {
      setProperty(null);
      return;
    }
    const newProperty = allProperties.find(p => p.id === selectedStructure.propertyId);
    setProperty(newProperty);
  }, [selectedStructureId, selectedStructure, allProperties]);

  useEffect(() => {
    if (!allOccupants || !selectedStructureId) {
      setOccupants([]);
      return;
    }
    const newOccupants = allOccupants.filter(o => o.structureId === selectedStructureId);
    setOccupants(newOccupants);
  }, [selectedStructureId, allOccupants]);

  const combinedFloors = useMemo(() => {
    const sf = [...(selectedStructure?.floors || [])];
    const of = [];
    for (const occupant of occupants) {
      if (occupant.floor && !sf.find(floor => floor.name === occupant.floor)) {
        of.push(occupant.floor);
      }
    }
    of.sort();
    const result = [...of.map(name => ({ name })), ...sf];
    return result;
  }, [selectedStructure, occupants]);

  const floors = useMemo(() => {
    return [
      {
        name: null,
        occupants: occupants.filter(occupant => !occupant.floor)
      },
      ...combinedFloors.map(floor => ({
        name: floor.name,
        occupants: occupants.filter(occupant => occupant.floor === floor.name)
      }))
    ];
  }, [combinedFloors, occupants]);

  useEffect(() => {
    if (true) return; // TODO bring it back if needed
    if (!combinedFloors || !occupants) return;

    let needSync = false;
    for (const occupant of occupants) {
      if (occupant.floor && !combinedFloors.find(floor => floor.name === occupant.floor)) {
        needSync = true;
      }
    }

    if (needSync) {
      const structurePatch = [
        {
          op: 'replace',
          path: '/floors',
          value: combinedFloors
        }
      ];
      dispatch(editStructure({ structureId: selectedStructure.id, structurePatch }));
    }
  }, [selectedStructure, occupants, combinedFloors, dispatch]);

  return (
    <Box fontFamily="Inter">
      <Box boxShadow="sm">
        <Tabs
          variant="soft-rounded"
          colorScheme="blue"
        >
          <TabList flexWrap="wrap">
            <Tab fontSize="sm">Location Data</Tab>
            <Tab fontSize="sm">Occupants ({ occupants ? occupants.length : 0 })</Tab>
          </TabList>
          <TabPanels>
            <TabPanel paddingX={0}>
              <StructureDataPanel
                selectedStructure={selectedStructure}
                property={property}
                handleConnectPropertyStructures={props.handleConnectPropertyStructures}
                handleRePlanStart={props.handleRePlanStart}
                handleMoveStart={props.handleMoveStart}
                handleEditLocationSuccess={props.handleEditLocationSuccess}
              />
            </TabPanel>
            <TabPanel px="0">
              <OccupantsPanel
                selectedStructure={selectedStructure}
                floors={floors}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
