// import { connectRouter } from 'connected-react-router';
import { combineReducers } from '@reduxjs/toolkit';
import session from './SessionReducer';
import customer from './CustomerReducer';
import locationsReducer from '../features/locations/locationsSlice';
import hydrantsReducer from '../features/hydrants/hydrantsSlice';
import userReducer from '../features/user/userSlice';
import assignmentsReducer from '../features/assignments/assignmentsSlice';
import dispatchReducer from '../features/dispatch/dispatchSlice';
import departmentUnitsReducer from '../features/departmentUnits/departmentUnitsSlice';
import propertiesReducer from '../features/properties/propertiesSlice';
import structuresReducer from '../features/structures/structuresSlice';
import occupantsReducer from '../features/occupants/occupantsSlice';
import imagesReducer from '../features/images/imagesSlice';


const rootReducer = (history) => combineReducers({
    // router: connectRouter(history),
    session,
    customer,
    // user,
    locations: locationsReducer,
    hydrants: hydrantsReducer,
    user: userReducer,
    dispatch: dispatchReducer,
    assignments: assignmentsReducer,
    departmentUnits: departmentUnitsReducer,
    properties: propertiesReducer,
    structures: structuresReducer,
    occupants: occupantsReducer,
    images: imagesReducer,
});

export default rootReducer;
