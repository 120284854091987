import * as AJAXUtil from './AJAXUtil';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '../../config';
import { store } from '../index';

axiosRetry(axios, {
  retries: 50,
  retryCondition: err => {
    console.warn('Fetch failed, response:', err.response); // eslint-disable-line no-console
    return (err.config && err.config.method === 'GET') && err.response && (err.response.status === 404 || err.response.status === 403);
  },
  retryDelay: () => 500
});

export function getPreplanByLocation(location, successCallback, locationsLink) {
  AJAXUtil.AJAX({
    method: 'GET',
    url: `${locationsLink.href}/${location.id}/preplan`
  }).then(res => {
    if (typeof successCallback === 'function') {
      successCallback(location, res.data);
    }
  });
}

export function getISOReportCSV(slug) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/${slug}/location/isoreport`
  });
}

export function getHydrantReportCSV(slug) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/${slug}/hydrant/isoreport`
  });
}

export function uploadPrePlan(data, successCallback, errorCallback, uploadConfig, locationsLink) {
  const state = store.getState();
  const jwt = state && state.session && state.session.jwt;
  const tokenType = state && state.session && state.session.tokenType;

  const req = {
    headers: {
      Authorization: `${tokenType} ${jwt}`,
      'Content-Type': 'multipart/form-data',
      'X-FlowMSP-Source': 'Web',
      'X-FlowMSP-Version': '2.40.0'
    }, // TODO: Replace with localStorage config or something
    onUploadProgress: function(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (typeof uploadConfig === 'function') {
        uploadConfig(percentCompleted);
      }
    }
  };
  const url = `${locationsLink.href}/upload`;
  axios.put(url, data, req).then((res) => {
    if (res.data) {
      if (res.data.successFlag === 0) {
        if (typeof successCallback === 'function') {
          const msg = res.data.log;
          successCallback(msg);
        }
      } else {
        if (typeof errorCallback === 'function') {
          const msg = res.data.log;
          errorCallback(msg);
        }
      }
    } else {
      if (typeof errorCallback === 'function') {
        errorCallback('Error processing file');
      }
    }
  }).catch(function(/*error*/) {
    if (typeof errorCallback === 'function') {
      errorCallback('Error while uploading file');
    }
  });
}
