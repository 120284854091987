import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as imageAPI from '../../api/ImageAPI';

// Thunks
export const getAllImages = createAsyncThunk(
  'images/getAllImages',
  async(data, thunkAPI) => {
    const response = await imageAPI.getAllImages();
    return response.data;
  }
);

export const uploadImage = createAsyncThunk(
  'images/uploadImage',
  async(data, thunkAPI) => {
    const response = await imageAPI.uploadImage(data);
    return response.data;
  }
);

export const getImageById = createAsyncThunk(
  'images/getImageById',
  async(data, thunkAPI) => {
    const response = await imageAPI.getImageById(data);
    return response.data;
  }
);

export const deleteImage = createAsyncThunk(
  'images/deleteImage',
  async(data, thunkAPI) => {
    const response = await imageAPI.deleteImage(data);
    return { ...response.data, id: data };
  }
);

export const setTags = createAsyncThunk(
  'images/setTags',
  async(data, thunkAPI) => {
    const response = await imageAPI.setTags(data.imageId, data.tags);
    return response.data;
  }
);

export const setAnnotation = createAsyncThunk(
  'images/setAnnotation',
  async(data, thunkAPI) => {
    const response = await imageAPI.setAnnotation(data.imageId, data.annotationJson, data.annotationSVG);
    return response.data;
  }
);


// Adapter & State
export const imagesAdapter = createEntityAdapter();
const initialState = imagesAdapter.getInitialState({
  loading: 'idle',
  error: null,
  lastFetch: null,
  selectedImageId: null,
  filters: [],
  filteredImageIds: []
});

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setSelectedImageId: (state, action) => {
      state.selectedImageId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getAllImages
    builder
      .addCase(getAllImages.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(getAllImages.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.lastFetch = Date.now();
        if (action.payload && action.payload.length > 0) {
          imagesAdapter.setAll(state, action.payload);
        }
      })
      .addCase(getAllImages.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.lastFetch = Date.now();
        }
      });
    // getImageById
    builder
      .addCase(getImageById.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(getImageById.fulfilled, (state, action) => {
        state.loading = 'idle';
        imagesAdapter.setOne(state, action.payload);
      })
      .addCase(getImageById.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // uploadImage
    builder
      .addCase(uploadImage.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          imagesAdapter.addOne(state, action.payload);
        }
      })
      .addCase(uploadImage.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // deleteImage
    builder
      .addCase(deleteImage.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          imagesAdapter.removeOne(state, action.payload.id);
        }
      })
      .addCase(deleteImage.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // setTags
    builder
      .addCase(setTags.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(setTags.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          imagesAdapter.setOne(state, action.payload);
        }
      })
      .addCase(setTags.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // setAnnotation
    builder
      .addCase(setAnnotation.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(setAnnotation.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          imagesAdapter.setOne(state, action.payload);
        }
      })
      .addCase(setAnnotation.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
  }
});


const { actions, reducer } = imagesSlice;

export const {
  setSelectedImageId
} = actions;

export default reducer;


export const {
  selectById: selectImageById,
  selectAll: selectAllImages,
  selectTotal: selectTotalImages,
} = imagesAdapter.getSelectors(state => state.images);
