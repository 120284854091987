// Session_
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const INVALID_CREDENTIAL = 'INVALID_CREDENTIAL';

// Customer
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const SET_CUSTOMER_RADIUS_LIST = 'SET_CUSTOMER_RADIUS_LIST';
export const SET_CUSTOMER_PARTNERS_LIST = 'SET_CUSTOMER_PARTNERS_LIST';
export const SET_CUSTOMER_CONFIG = 'SET_CUSTOMER_CONFIG';
export const SET_CUSTOMER_UI_CONFIG = 'SET_CUSTOMER_UI_CONFIG';
export const SET_CUSTOMER_MAP_FILTER = 'SET_CUSTOMER_MAP_FILTER';

export const SET_CUSTOMER_DEFAULT_STATE = 'SET_CUSTOMER_DEFAULT_STATE';
export const SET_CUSTOMER_USERS = 'SET_CUSTOMER_USERS';
export const SET_CUSTOMER_LOGOUT = 'SET_CUSTOMER_LOGOUT';

// USER
export const SET_USER = 'SET_USER';

// SMS
export const SET_CUSTOMER_SMS = 'SET_CUSTOMER_SMS';
export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
export const SET_BACKLOG_MESSAGE = 'SET_BACKLOG_MESSAGE';
