import React from 'react';
import { Flex, Text, IconButton, Image, Stack, Grid, GridItem, Badge } from '@chakra-ui/react';
import { setHydrantIdToLocate } from '../../features/hydrants/hydrantsSlice';
import LocationIcon from '../FigmaExport/LocationIcon';
import * as util from '../../helpers/Util';
import { useDispatch } from 'react-redux';


export const HydrantInfoPanel = ({ hydrant, index }) => {
  const dispatch = useDispatch();
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  return (
    <Stack p="12px" borderWidth='1px' borderRadius='lg'>
      <Flex gap="4px" textAlign="left">
        <Image h="30px" key={`h-img-${index}`} src={util.getMarkerIcon(hydrant.isMine, hydrant.dryHydrant, hydrant.inService, hydrant.flowRange && hydrant.flowRange.pinColor ? hydrant.flowRange.pinColor : null, true)} />
        <Text w="100%" margin="auto" fontSize="md" fontWeight="500" textColor="#222234">
          {hydrant.flow ? `${hydrant.flow.toLocaleString()} gpm` : '--'}
          <Badge colorScheme={hydrant.inService ? "green" : "red"} ml="8px">
            {hydrant.inService ? "In Service" : "Out of Service"}
          </Badge>
        </Text>
        <IconButton h="24px" bg="white" icon={<LocationIcon />} onClick={() => dispatch(setHydrantIdToLocate(hydrant.id))}/>
      </Flex>
      <Stack spacing="">
        <Grid templateColumns='2fr 3fr'>
          <GridItem><Text {...labelStyle}>Address:</Text></GridItem>
          <GridItem><Text fontWeight="600" fontSize="sm">{hydrant.streetAddress}</Text></GridItem>
          </Grid>
        <Grid templateColumns='2fr 3fr'>
          <GridItem><Text {...labelStyle}>Notes:</Text></GridItem>
          <GridItem><Text fontWeight="600" fontSize="sm">{hydrant.notes}</Text></GridItem>
        </Grid>
        <Grid templateColumns='2fr 3fr'>
          <GridItem><Text {...labelStyle}>External ID:</Text></GridItem>
          <GridItem><Text fontWeight="600" fontSize="sm">{hydrant.hydrantId}</Text></GridItem>
        </Grid>
      </Stack>
    </Stack>
  );
};
