import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Text,
  Box,
  Button,
  Flex,
  Divider,
  Checkbox,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Wrap,
  Spacer,
} from '@chakra-ui/react';
import { setDashboardColumnsVisibility } from '../../features/user/userSlice';
import { allColumns, allColumnIds, hiddenColumnIds } from './PrePlanTableColumns';
import { LinkButton } from '../../components/form/Button';

const DEFAULT_PREFERENCES = {};
allColumnIds.forEach(id => {
  DEFAULT_PREFERENCES[id] = !hiddenColumnIds.includes(id);
});

export const ColumnsPreferencesDrawer = ({ isOpen, onClose }) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (!user || !user.uiConfig) return;
    setPreferences(user.uiConfig.dashboardColumnsVisibility);
  }, [user]);

  const toggleValue = (columnId) => {
    setPreferences((prvPreferences) => ({ ...prvPreferences, [columnId]: !prvPreferences[columnId]}));
  };

  const setGroup = (colGroup, value) => {
    setPreferences((prvPreferences) => {
      const newPreferences = { ...prvPreferences };
      for (const column of colGroup.columns) {
        newPreferences[column.id] = value;
      }
      return newPreferences;
    });
  };

  const onApply = async () => {
    setLoading(true);
    await dispatch(setDashboardColumnsVisibility(preferences));
    setLoading(false);
    onClose();
  };

  return isOpen && (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={null}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent p="0">
        <DrawerCloseButton color="#2C62CB" />
        <DrawerHeader p="6px">
          Columns
        </DrawerHeader>
        <Divider />
        <DrawerBody p="12px">
          <Box>
            <Flex justifyContent="space-between" flexWrap="wrap" gap="12px">
              {allColumns.map((colGroup, i) => (
                <ColumnGroupCheckbox key={`column-group-${i}`} colGroup={colGroup} values={preferences} toggleValue={toggleValue} setGroup={setGroup} />
              ))}
              <Divider mb="2" />
              <Flex w="100%">
                <Button
                  width="8rem"
                  bg={'white'}
                  color={'#2C62CB'}
                  borderColor={'#2C62CB'}
                  borderWidth={2}
                  _hover={{
                    bg: 'white',
                  }}
                  isLoading={loading}
                  onClick={() => setPreferences(DEFAULT_PREFERENCES)}
                >
                  Reset to default
                </Button>
                <Spacer />
                <Button
                  width="8rem"
                  bg={'#2C62CB'}
                  color={'white'}
                  _hover={{
                    bg: '#2C62CB',
                  }}
                  isLoading={loading}
                  onClick={onApply}
                >
                  Apply
                </Button>
              </Flex>
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const ColumnGroupCheckbox = ({ colGroup, values, toggleValue, setGroup }) => {
  // @todo to save my sanity. need to tie this to other property.
  // const restrictedColumns = ['id', 'lastReviewedBy'];

  const [allChecked, setAllChecked] = useState();
  const [isIndeterminate, setIsIndeterminate] = useState();

  useEffect(() => {
    const checkedItems = colGroup.columns.map(c => values[c.id]);
    setAllChecked(checkedItems.every(Boolean));
    setIsIndeterminate(checkedItems.some(Boolean) && !checkedItems.every(Boolean));
  }, [values, colGroup]);

  return (
    <Box borderWidth='1px' borderRadius='lg' w="100%" p="6px">
      <Flex>
        <Checkbox
          isIndeterminate={isIndeterminate}
          onChange={(e) => setGroup(colGroup, e.target.checked)}
          isChecked={allChecked}
          size="sm"
        >
          {colGroup.header}
        </Checkbox>
        <Spacer />
        <LinkButton marginLeft={'auto'} size="xs" color="#2C62CB" onClick={() => setGroup(colGroup, false)}>Clear</LinkButton>
      </Flex>
      <Divider my="6px" />
      <Wrap>
        {colGroup.columns.map((column, i) => (
          <Checkbox key={`cb-${i}`} onChange={(e) => toggleValue(column.id)} size="sm" isChecked={values[column.id]}>
            <Text fontSize="sm">{column.header}</Text>
          </Checkbox>
        ))}
      </Wrap>
    </Box>
  );
};
