import React from 'react';
import BuildingForm from '../form/BuildingDataFormComponent';
import { Box, Stack, HStack, Text, Divider } from '@chakra-ui/react';
import { OutlineButton } from '../form/Button';
import { CommercialTag } from './CommercialTag';

export const buildingDataFields = [
  {
    label: 'Occupancy Type',
    name: 'occupancyType'
  },
  {
    label: 'Construction Type',
    name: 'constructionType'
  },
  {
    label: 'Roof Type',
    name: 'roofType'
  },
  {
    label: 'Roof Construction',
    name: 'roofConstruction'
  },
  {
    label: 'Roof Material',
    name: 'roofMaterial'
  },
  {
    label: 'Sprinklered',
    name: 'sprinklered'
  },
  {
    label: 'Stand Pipe',
    name: 'standPipe'
  },
  {
    label: 'Fire Alarm',
    name: 'fireAlarm'
  },
  {
    label: 'Normal Population',
    name: 'normalPopulation'
  },
  {
    label: 'Hours of Operation',
    name: 'hoursOfOperation'
  },
  {
    label: 'Owner Contact',
    name: 'ownerContact'
  },
  {
    label: 'Owner Phone',
    name: 'ownerPhone'
  },
  {
    label: 'Original Pre-Plan',
    name: 'originalPrePlan'
  },
  {
    label: 'Last Reviewed On',
    name: 'lastReviewedOn'
  },
  {
    label: 'Last Reviewed By',
    name: 'lastReviewedBy'
  },
  {
    label: 'Notes',
    name: 'notes'
  },
  {
    label: 'Third-Party Notes',
    name: 'partnerNotes',
    hideIfEmpty: true
  }
];


export const BuildingData = (props) => {
  const building = props.location && props.location.building && (typeof (props.location.building) !== 'undefined') ? props.location.building : null;

  return (
    <Box>
      {!props.isPrePlanningMode && building && (
        <Stack spacing="0.25rem">
          <HStack align="strech">
            {building && building.commercialStructure && (
              <CommercialTag />
            )}
          </HStack>
          {buildingDataFields.map((field, index) => (!field.hideIfEmpty || building[field.name]) && (
          <React.Fragment key={`${field.name}-${index}-frag`}>
            <Stack key={`${field.name}-${index}-stack`} spacing="0">
                <Text key={`${field.name}-${index}-label`} fontSize="xs" textTransform="uppercase">
                  {field.label}
                </Text>
                <Text key={`${field.name}-${index}-value`} fontSize="sm" fontWeight="600" as="span" whiteSpace="pre-wrap">{building[field.name] ? building[field.name] : '--'}</Text>
            </Stack>
            <Divider key={`${field.name}-${index}-divider`}/>
          </React.Fragment>
          ))}
        </Stack>
      )}
      {!props.isPrePlanningMode && !building && (
        <Text size="sm">No building info.</Text>
      )}
      {props.isPrePlanningMode && props.buildingDataForm && props.buildingDataForm.action && (
        <div className="preplanning-content">
          {props.isGettingPreplan ? (
            <div className="processing">
              <img src="/images/processing.gif" alt="" />
            </div>
          ) : (
            <div>
              {props.isPolygonDrawn ? (
                <BuildingForm {...{
                  handleFormChange: props.onBuildingFormChange,
                  onCancel: props.onCancelPrePlan,
                  buildingDataForm: props.buildingDataForm
                }} />
              ) : (
                <div>
                  <Box>
                    <strong>To continue:</strong> Select the edges of the location on the map using drawing tools.
                  </Box>
                  <Box className="preplanning-actions">
                    <OutlineButton onClick={props.onCancelPrePlan}>Cancel</OutlineButton>
                  </Box>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default BuildingData;
