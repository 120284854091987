import * as AJAXUtil from './AJAXUtil';
import config from '../../config.js';


export function createCustomer(form, successCallback, errorCallback) {
  AJAXUtil.AJAX({
    method: 'POST',
    data: form,
    url: `${config.API_BASE_URL}/api/signup`
  }).then((res) => {
    if (typeof successCallback === 'function') {
      successCallback();
    }
  }).catch(function(error) {
    if (typeof errorCallback === 'function') {
      errorCallback();
    }
  });
}
