import * as types from './types';

export function setCustomerLogOut(customer) {
	  return {
	    type: types.SET_CUSTOMER_LOGOUT,
	    customer
	  };
}

export function setCustomer(customer) {
  return {
    type: types.SET_CUSTOMER,
    customer
  };
}

export function setCustomerList(customers) {
	  return {
	    type: types.SET_CUSTOMER_LIST,
	    customers: customers.data
	  };
}

export function setCustomerRadiusList(customers) {
	  return {
	    type: types.SET_CUSTOMER_RADIUS_LIST,
	    customers: customers.data
	  };
}

export function setCustomerPartnersList(partners) {
	  return {
		    type: types.SET_CUSTOMER_PARTNERS_LIST,
		    partners: partners.data
		  };
}

export function setCustomerConfig(config) {
  return {
    type: types.SET_CUSTOMER_CONFIG,
    config
  };
}

export function setCustomerUiConfig(uiConfig) {
  return {
    type: types.SET_CUSTOMER_UI_CONFIG,
    uiConfig
  };
}

export function setCustomerDefaultState() {
  return {
    type: types.SET_CUSTOMER_DEFAULT_STATE
  };
}

export function setCustomerUsers(users) {
  return {
    type: types.SET_CUSTOMER_USERS,
    users: users
  };
}

//SMS
export function setCustomerSMS(smsList) {
	  return {
	    type: types.SET_CUSTOMER_SMS,
	    smsList: smsList
	  };
}

export function setNewMessage(newSMS, customerId) {
	  return {
	    type: types.SET_NEW_MESSAGE,
	    newSMS: newSMS,
	    customerId: customerId
	  };
}

export function setBackLogMessage(newSMS, customerId) {
	  return {
	    type: types.SET_BACKLOG_MESSAGE,
	    newSMS: newSMS,
	    customerId: customerId
	  };
}

export function setCustomerMapFilter(mapFilter) {
  return {
    type: types.SET_CUSTOMER_MAP_FILTER,
    mapFilter: mapFilter
  };
}
