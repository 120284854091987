import React, { useEffect, useMemo, useState } from 'react';
import { OutlineButton } from '../form/Button';
import EditIcon from '../FigmaExport/EditIcon';
import DeleteIcon from '../FigmaExport/DeleteIcon';
import { StructureForm } from '../form/StructureForm';
import ModalComponent from './ModalComponent';
import { MoreableText } from './MoreableText';
import { useDispatch, useSelector } from 'react-redux';
import {
  Stack,
  Text,
  GridItem,
  Grid,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { userRoleSelector } from '../../features/user/userSlice';
import { NavLink as RouterLink } from 'react-router-dom';
import PDFIcon from '../FigmaExport/PDFIcon';
import HighHazardIcon from '../FigmaExport/HighHazardIcon';
import CommercialIcon from '../FigmaExport/CommercialIcon';
import DoNotShareIcon from '../FigmaExport/DoNotShareIcon';
import { AddressStack } from './AddressStack';
import MoreIcon from '../FigmaExport/MoreIcon';
import RestructureIcon from '../FigmaExport/RestructureIcon';
import MoveIcon from '../FigmaExport/MoveIcon';
import { setStructureToEdit } from '../../features/structures/structuresSlice';
import { HighHazardBanner } from './HighHazardBanner';


export const STRUCTURE_FIELDS = [
  {
    label: 'Location Label',
    of: (structure) => structure.label,
  },
  {
    label: 'Location Name',
    of: (structure) => structure.name,
  },
  {
    label: 'Address',
    of: (structure) => structure.address,
  },
  {
    label: 'Occupancy Type',
    of: (structure) => structure.building.occupancyType,
  },
  {
    label: 'Construction Type',
    of: (structure) => structure.building.constructionType,
  },
  {
    label: 'High Hazard',
    of: (structure) => structure.isHighHazard ? 'Yes' : 'No',
    iconOf: (structure) => structure.isHighHazard ? <HighHazardIcon /> : null,
  },
  {
    label: 'Commercial',
    of: (structure) => structure.commercialStructure ? 'Yes' : 'No',
    iconOf: (structure) => structure.commercialStructure ? <CommercialIcon /> : null,
  },
  {
    label: 'Do Not Share',
    of: (structure) => structure.doNotShare ? 'Yes' : 'No',
    iconOf: (structure) => structure.doNotShare ? <DoNotShareIcon /> : null,
  },
  {
    label: 'Roof Type',
    of: (structure) => structure.building.roofType,
  },
  {
    label: 'Roof Construction',
    of: (structure) => structure.building.roofConstruction,
  },
  {
    label: 'Roof Material',
    of: (structure) => structure.building.roofMaterial,
  },
  {
    label: 'Sprinklered',
    of: (structure) => structure.building.sprinklered,
  },
  {
    label: 'Stand Pipe',
    of: (structure) => structure.building.standPipe,
  },
  {
    label: 'Fire Alarm',
    of: (structure) => structure.building.fireAlarm,
  },
  {
    label: 'Normal Population',
    of: (structure) => structure.building.normalPopulation,
  },
  {
    label: 'Hours of Operation',
    canBeLong: true,
    of: (structure) => structure.building.hoursOfOperation,
  },
  {
    label: 'Owner Contact',
    of: (structure) => structure.building.ownerContact,
  },
  {
    label: 'Owner Phone',
    of: (structure) => structure.building.ownerPhone,
  },
  {
    label: 'PrePlan Created Date',
    of: (structure) => structure.building.originalPrePlan,
  },
  {
    label: 'Last Reviewed Date',
    of: (structure) => structure.building.lastReviewedOn,
  },
  {
    label: 'Last Reviewed By',
    of: (structure) => structure.building.lastReviewedBy,
  },
  {
    label: 'Notes',
    canBeLong: true,
    of: (structure) => structure.notes,
  },
  {
    label: 'Third-Party Notes',
    canBeLong: true,
    of: (structure) => structure.building.partnerNotes,
    hideIfEmpty: true
  }
];


const StructureInfoPanel = ({
  structure,
  handleRePlanStart,
  handleMoveStart,
  handleEditLocationSuccess
}) => {
  const DEFAULT_MODAL_STATE = {
    showModal: false,
    modal: {
      heading: null,
      body: null,
    },
  };
  const [modalState, setModalState] = useState(DEFAULT_MODAL_STATE);
  const userRole = useSelector(userRoleSelector);

  const canEdit = useMemo(() => {
    return (userRole === "ADMIN" || userRole === "PLANNER") &&
      (!structure?.readOnly) &&
      (structure?.isMine);
  }, [userRole, structure]);

  const reason = useMemo(() => {
    if (structure?.readOnly) {
      return "Editing is disabled. Visit Elite to change.";
    }
    if (structure && !structure.isMine) {
      return "Partner Data can't be edited.";
    }
    if (!(userRole === "ADMIN" || userRole === "PLANNER")) {
      return "Only ADMIN or PLANNER users can edit.";
    }
    return null;
  }, [userRole, structure]);

  const closeModal = () => {
    setModalState(DEFAULT_MODAL_STATE);
  };

  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  const { structureToEdit } = useSelector(store => store.structures);
  const [action, setAction] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!structureToEdit) {
      setAction(null);
    }
    else if (structureToEdit.id) {
      setAction("EDIT_STRUCTURE");
    } else {
      setAction("ADD_STRUCTURE");
    }
  }, [structureToEdit]);

  const onCloseAction = () => {
    dispatch(setStructureToEdit(null));
    handleEditLocationSuccess();
  };

  const handleEditStructure = (structure) => {
    dispatch(setStructureToEdit(structure));
    // setModalState({
    //   showModal: true,
    //   modal: {
    //     heading: 'Edit Structure',
    //     body: (
    //       <StructureForm
    //         structure={structure}
    //         action="EDIT_STRUCTURE"
    //         closeModal={closeModal}
    //       />
    //     )
    //   },
    // });
  };

  const handleDeleteStructure = (structure) => {
    setModalState({
      showModal: true,
      modal: {
        heading: <Text><Icon as={DeleteIcon} fill="black" mr="4px" />Permanently delete Pre-Plan</Text>,
        body: (
          <StructureForm
            structure={structure}
            action="DELETE_STRUCTURE"
            closeModal={closeModal}
          />
        )
      },
    });
  };

  return (action ? (
    <Stack width="100%" padding="12px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <StructureForm
        structure={structureToEdit}
        action={action}
        closeModal={onCloseAction}
      />
    </Stack>
  ) : structure ? (
    <Stack width="100%" padding="12px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <ModalComponent
        showModal={modalState.showModal}
        toggleModal={closeModal}
        modal={modalState.modal}
      />
      <Flex gap="8px">
        <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
          <Flex flexGrow={1}>
            <OutlineButton
              fontSize="sm"
              color="#2C62CB"
              borderColor="#2C62CB"
              leftIcon={<EditIcon fill="#2C62CB" />}
              onClick={() => handleEditStructure(structure)}
              flexGrow={1}
              isDisabled={!canEdit}
            >
              Edit Location
            </OutlineButton>
          </Flex>
        </Tooltip>
        <Menu>
          <MenuButton 
            fontSize="sm"
            color="#2C62CB"
            borderColor="#2C62CB"
            as={Button}
            variant={'outline'}
          >
            <MoreIcon />
          </MenuButton>
          <MenuList>
            <MenuItem
              key={"menu-export"}
              fontSize="sm"
              color="#2C62CB"
              as={RouterLink}
              end
              to={`/export/${structure.id}`}
              icon={<PDFIcon />}
              bg={'white'}
            >
              Export to PDF
            </MenuItem>
            <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
              <MenuItem
                key={"menu-replan"}
                color="#2C62CB"
                icon={<RestructureIcon />}
                onClick={() => handleRePlanStart(structure)}
                isDisabled={!canEdit}
              >
                Re-Plan Location
              </MenuItem>
            </Tooltip>
            <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
              <MenuItem
                key={"menu-move"}
                color="#2C62CB"
                icon={<MoveIcon />}
                onClick={() => handleMoveStart(structure)}
                isDisabled={!canEdit}
              >
                Move Location
              </MenuItem>
            </Tooltip>
            {/* <MenuItem
              key={"menu-convert"}
              color="#2C62CB"
              icon={<EditIcon />}
              // onClick={() => {}}
              isDisabled
            >
              Convert To Occupant
            </MenuItem> */}
            <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
              <MenuItem
                key={"menu-delete"}
                color={'red'}
                icon={<DeleteIcon />}
                onClick={() => handleDeleteStructure(structure)}
                isDisabled={!canEdit}
              >
                Delete Location
              </MenuItem>
            </Tooltip>
          </MenuList>
        </Menu>
      </Flex>
      {structure.isHighHazard && (
        <HighHazardBanner highHazardDescription={structure.highHazardDescription} />
      )}
      <Stack spacing="">
        {STRUCTURE_FIELDS.map((field, index) => (!field.hideIfEmpty || field.of(structure)) && (
          <Grid key={index} templateColumns='2fr 3fr'>
            <GridItem><Text {...labelStyle}>{field.label}</Text></GridItem>
            <GridItem>
              {field.label === "Address" ?
                <AddressStack {...structure.address} />
                : field.canBeLong ?
                  <MoreableText text={field.of(structure)} header={field.label} noOfLines={2} fontWeight="600" fontSize="sm" />
                  :
                  <Flex gap={2} alignItems="center">
                    <Text fontWeight="600" fontSize="sm">
                      {field.of(structure)}
                    </Text>
                    {field.iconOf?.(structure)}
                  </Flex>
              }
            </GridItem>
          </Grid>
        ))}
      </Stack>
      <Flex gap="12px" textAlign="center">
        <Stack bg="#2C62CB1A" borderWidth='1px' borderRadius='lg' w="100%" spacing="4px" p= "4px">
          <Text color="#707A86" fontSize="sm" fontWeight="400" textTransform="uppercase">Roof Area</Text>
          <Text color="#222234" fontSize="lg" fontWeight="600">{structure.roofArea || '--'} sq/ft</Text>
        </Stack>
        <Stack bg="#2C62CB1A" borderWidth='1px' borderRadius='lg' w="100%" spacing="4px" p= "4px">
          <Text color="#707A86" fontSize="sm" fontWeight="400" textTransform="uppercase">Floors</Text>
          <Text color="#222234" fontSize="lg" fontWeight="600">{structure.floors?.length || 1}</Text>
        </Stack>
      </Flex>
      <Flex gap="12px" textAlign="center">
        <Stack bg="#2C62CB1A" borderWidth='1px' borderRadius='lg' w="100%" spacing="4px" p= "4px">
          <Text color="#707A86" fontSize="sm" fontWeight="400" textTransform="uppercase">Required Flow</Text>
          <Text color="#222234" fontSize="lg" fontWeight="600">{structure.requiredFlow || '--'} gal/min</Text>
        </Stack>
      </Flex>
    </Stack>
  ) : (
    <Stack width="100%" padding="12px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <Text textAlign="center" py="8px" >No locations selected.</Text>
    </Stack>
  ));
};


export default StructureInfoPanel;
