import React from 'react';

import { useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import FormComponent from '../form/FormComponent';
import { SubmitButton } from '../form/Button';
import { CreateUserComplete } from '../../models/User';
import { zodResolver } from '@hookform/resolvers/zod';
import * as sessionAPI from '../../api/SessionAPI';
import {useNavigate} from "react-router-dom";
import SignUpSecondaryBoxComponent from "./SignUpSecondaryBoxComponent";

const SignUpSecondaryComponent = (props) => {
  const methods = useForm({
    resolver: zodResolver(CreateUserComplete)
  });
  const toast = useToast();
    const navigate = useNavigate();

  const onSubmit = async(values) => {

    return new Promise(resolve => sessionAPI.submitSignUpSecondary({
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
    },
      props.linkPart,
      () => {
        toast({
          title: 'Success',
          position: 'top',
          description: 'User registration complete.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        resolve();
          navigate('/login');
      },
      () => {
        toast({
          title: 'Error',
          position: 'top',
          description: 'There was an error registering your account, please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        resolve();
      }));

  };

  const formFields = [
    {
      id: 'email',
      name: 'email',
      type: 'static',
      label: 'Email'
    },
    {
        id: 'firstName',
        name: 'firstName',
        type: 'text',
        label: 'First Name',
        required: true
    },
    {
        id: 'lastName',
        name: 'lastName',
        type: 'text',
        label: 'Last Name',
        required: true
    },
    {
        id: 'password',
        name: 'password',
        type: 'password',
        label: 'Password',
        required: true
    },
    {
        id: 'confirmPassword',
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm Password',
        required: true,
        confirmPasswordCheck: true
    }
];

  return (
    <SignUpSecondaryBoxComponent title="Complete User Registration">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormComponent formFields={formFields} data={{email: props.userEmail}} />
          <SubmitButton isDisabled={!props.userEmail} width="100%" isLoading={methods.formState.isSubmitting} mt={4} type="submit">
            Submit
          </SubmitButton>
        </form>
      </FormProvider>
    </SignUpSecondaryBoxComponent>
  );

};

export default SignUpSecondaryComponent;
