import { Icon } from "@chakra-ui/react";
import React from "react";

const AddIcon = (props) => (
  <Icon width="12px" height="12px" viewBox="0 0 12 12" fill="white" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.37467 6.62516H0.583008V5.37516H5.37467V0.583496H6.62467V5.37516H11.4163V6.62516H6.62467V11.4168H5.37467V6.62516Z"/>
  </Icon>
);

export default AddIcon;
