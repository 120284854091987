import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Text,
  Stack,
  Box,
  Button,
  Flex,
  Divider,
  Select,
} from '@chakra-ui/react';
import { uniq } from 'lodash';
import { DateRangePicker, NumberSlider } from '../../components/form/FormWrapper';
import { assignmentFieldValues } from '../../models/Assignment';
import {
  setDashboardFilterValues,
  getUIConfig
} from '../../features/user/userSlice';


export const Filters = ({ data }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const uiConfig = getUIConfig(user);

  const setFilterState = (filterId) => (value) => {
    if (uiConfig.dashboardFilterValues[filterId] !== value) {
      dispatch(setDashboardFilterValues({ [filterId]: value }));
    }
  };

  const createSelectFilter = ({id, label}) => {
    const options = getFilterOptionsById(id);

    return {
      id: id,
      label: label,
      type: 'select',
      fn: setFilterState(id),
      options: options,
      value: getFilterValueById(id, options)
    };
  };

  const getFilterValueById = (id, options) =>
    options.find(f => f.value === uiConfig.dashboardFilterValues[id]) ? uiConfig.dashboardFilterValues[id] : '';

  const getFilterOptionsById = (id) => {
    if (id === 'lastReviewedBy') {
      return uniq(data.filter(loc => loc.lastReviewedBy).map(loc => loc.lastReviewedBy)).sort().map(a => ({
        label: `${a}`,
        value: a
      }));
    } else if (id === 'reviewStatus') {
      return uniq(data.filter(loc => loc.reviewStatus).map(loc => loc.reviewStatus)).map(a => ({
        label: `${a}`,
        value: a
      }));
    } else if (id === 'assignedTo') {
      return uniq(data.filter(loc => loc.assignedTo).map(loc => loc.assignedTo)).sort().map(a => ({
        label: `${a}`,
        value: a
      }));
    } else if (id === 'assignmentStatus') {
      return Object.keys(assignmentFieldValues).map(key => ({
        label: assignmentFieldValues[key],
        value: key
      }));
    }

    return [];
  };

  useEffect(() => {
    const ids = ['lastReviewedBy', 'reviewStatus', 'assignedTo', 'assignmentStatus'];
    ids.forEach(id => {
      const options = getFilterOptionsById(id);
      const value = uiConfig.dashboardFilterValues[id];
      if (!value || (value && !options.find(f => f.value === value))) {
        setFilterState(id)(null);
      }
    });
  }, [data, uiConfig.dashboardFilterValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Flex justifyContent="space-between">
        <FilterGroup
          label="PrePlan"
          filters={[
            {
              id: 'completion',
              label: 'Completion Percentage',
              type: 'numberRange',
              fn: setFilterState('completion'),
              min: 0,
              max: 100,
              value: uiConfig.dashboardFilterValues.completion
            }
          ]}
          onReset={() => dispatch(setDashboardFilterValues({ completion: [0, 100] }))}
        />
        <FilterGroup
          label="Review"
          filters={[
            {
              id: 'lastReviewedOn',
              label: 'Last Reviewed On',
              type: 'dateRange',
              fn: setFilterState('lastReviewedOn'),
              startDate: uiConfig.dashboardFilterValues.lastReviewedOn && uiConfig.dashboardFilterValues.lastReviewedOn[0],
              endDate: uiConfig.dashboardFilterValues.lastReviewedOn && uiConfig.dashboardFilterValues.lastReviewedOn[1]
            },
            createSelectFilter({
              id: 'lastReviewedBy',
              label: 'Last Reviewed By'
            }),
            createSelectFilter({
              id: 'reviewStatus',
              label: 'Review Status'
            }),
            {
              id: 'originalPrePlan',
              label: 'Created On',
              type: 'dateRange',
              fn: setFilterState('originalPrePlan'),
              startDate: uiConfig.dashboardFilterValues.originalPrePlan && uiConfig.dashboardFilterValues.originalPrePlan[0],
              endDate: uiConfig.dashboardFilterValues.originalPrePlan && uiConfig.dashboardFilterValues.originalPrePlan[1]
            }
          ]}
          onReset={() => dispatch(setDashboardFilterValues({ lastReviewedOn: null, lastReviewedBy: null, reviewStatus: null, originalPrePlan: null }))}
        />
        {/* <FilterGroup
          label="Creation"
          filters={[
            {
              id: 'originalPrePlan',
              label: 'Created Date',
              type: 'dateRange'
            },
            // {
            //   id: 'createdBy',
            //   label: 'Created By',
            //   type: 'select',
            //   fn: (val) => setFilter('createdBy', val ? val : null),
            //   options: uniq(data.filter(loc => loc.createdBy).map(loc => loc.createdBy)).map(a => ({
            //     label: `${a}`,
            //     value: a
            //   }))
            // },
          ]}
        /> */}
        <FilterGroup
          label="Assignment"
          filters={[
            createSelectFilter({
              id: 'assignedTo',
              label: 'Assigned To'
            }),
            createSelectFilter({
              id: 'assignmentStatus',
              label: 'Assignment Status'
            }),
          ]}
          onReset={() => dispatch(setDashboardFilterValues({ assignedTo: null, assignmentStatus: null }))}
        />
      </Flex>
    </Box>
  );
};

const FilterGroup = (props) => {
  return (
    <Box>
      <Flex>
        <Text fontSize="sm" textTransform="uppercase" fontWeight={600} mr="auto">{props.label}</Text>
        <Button marginLeft={'auto'} size="xs" color="#2C62CB" variant="link" onClick={props.onReset}>Reset</Button>
      </Flex>
      <Divider mb={2} />
      <Flex>
        {props.filters.map((filter, i) => (
          <Stack marginRight={8} spacing={0} key={`filter-${i}`}>
            <Flex justifyContent="space-between">
              <Text fontSize="xs" textTransform="uppercase">{filter.label}</Text>
              {filter.type === 'dateRange' && filter.startDate && filter.endDate && <Button size="xs" color="gray.800" variant="link" onClick={() => filter.fn(null)}>Clear</Button>}
            </Flex>
            {filter.type === 'dateRange' && (
              <DateRangePicker
                startDate={filter.startDate}
                endDate={filter.endDate}
                onChange={filter.fn}
              />
            )}
            {filter.type === 'select' && (
              <Select
                size="xs"
                id={filter.id}
                onChange={(val/*, t*/) => {
                  filter.fn(val.target.value);
                }}
                placeholder={'--'}
                maxWidth="9rem"
                value={filter.value}
              >
                {filter.options && filter.options.map((option) => (
                  <option key={`${props.id}-${option.value}`} value={option.value}>{option.label}</option>
                ))}
              </Select>
            )}
            {filter.type === 'numberRange' && (
              <NumberSlider onChangeEnd={filter.fn} unit="%" min={filter.min} max={filter.max} value={filter.value} />
            )}
          </Stack>
        ))}
      </Flex>
    </Box>
  );
};
