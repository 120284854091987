import axios from 'axios';
import config from '../../config.js';


export function forgotPasswordResetRequest(data, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/passwordresetrequest`, data, {})
    .then(function(/*response*/) {
      onSuccess();
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function forgotPasswordReset(data, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/completepasswordreset`, data, {})
    .then(function(/*response*/) {
      // showMessage({status: 'Success', message: 'Password reset successfully'});
      onSuccess();
    })
    .catch(function(/*error*/) {
      // showMessage({status: 'Error', message: 'Oops! something went wrong in forgotPasswordReset.'});
      onError();
    });
}

export function setSessionFromLocal() {
  const keys = ['jwt', 'customer', 'user', 'tokenType', 'customerID', 'dataFormat'];
  keys.map(k => sessionStorage.setItem(k, localStorage.getItem(k)));
}
