import React, { useMemo, useState } from 'react';
import { Box, Stack, Text, Accordion, AccordionButton, AccordionItem, AccordionPanel, GridItem, Grid, Divider, Flex, useToast, Tooltip } from '@chakra-ui/react';
import { AddressStack } from './AddressStack';
import ArrowUpIcon from '../FigmaExport/ArrowUpIcon';
import ArrowDownIcon from '../FigmaExport/ArrowDownIcon';
import { OutlineButton } from '../form/Button';
import DeleteIcon from '../FigmaExport/DeleteIcon';
import EditIcon from '../FigmaExport/EditIcon';
import AddIcon from '../FigmaExport/AddIcon';
import ModalComponent from './ModalComponent';
import { PropertyForm } from '../form/PropertyForm';
import UnlinkIcon from '../FigmaExport/UnlinkIcon';
import ConfirmForm from '../form/ConfirmFormComponent';
import { useDispatch, useSelector } from 'react-redux';
import { editStructure, selectAllStructures } from '../../features/structures/structuresSlice';
import { userRoleSelector } from '../../features/user/userSlice';


const PropertyInfoPanel = ({ property, structure, handleConnectPropertyStructures }) => {
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  const onLinkToPropety = (e) => {
    handleConnectPropertyStructures(property.id);
    e.preventDefault(); // if we don't preventDefault here, it may submit PrePlan form again.
  };

  const DEFAULT_MODAL_STATE = {
    showModal: false,
    modal: {
      heading: null,
      body: null,
    },
  };

  const [modalState, setModalState] = useState(DEFAULT_MODAL_STATE);
  const dispatch = useDispatch();
  const toast = useToast();
  const allStructures = useSelector(selectAllStructures);

  const structuresCount = useMemo(() => {
    if (!property || !allStructures) return 0;
    return allStructures.filter(structure => structure.propertyId === property.id).length;
  }, [property, allStructures]);

  const closeModal = () => {
    setModalState(DEFAULT_MODAL_STATE);
  };

  const userRole = useSelector(userRoleSelector);

  const canEdit = useMemo(() => {
    return (userRole === "ADMIN" || userRole === "PLANNER") &&
      (!structure?.readOnly) &&
      (structure?.isMine);
  }, [userRole, structure]);

  const reason = useMemo(() => {
    if (structure?.readOnly) {
      return "Editing is disabled. Visit Elite to change.";
    }
    if (structure && !structure.isMine) {
      return "Partner Data can't be edited.";
    }
    if (!(userRole === "ADMIN" || userRole === "PLANNER")) {
      return "Only ADMIN or PLANNER users can edit.";
    }
    return null;
  }, [userRole, structure]);

  const handleAddProperty = () => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Add Property',
        body: (
          <PropertyForm
            property={property}
            structure={structure}
            action="ADD_PROPERTY"
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleEditProperty = () => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Edit Property',
        body: (
          <PropertyForm
            property={property}
            structure={structure}
            action="EDIT_PROPERTY"
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleDeleteProperty = () => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Delete Property',
        body: (
          <PropertyForm
            property={property}
            structure={structure}
            action="DELETE_PROPERTY"
            closeModal={closeModal}
          />
        )
      },
    });
  };

  const handleDisconnect = () => {
    setModalState({
      showModal: true,
      modal: {
        heading: 'Disconnect Property',
        body: (
          <ConfirmForm
            body={`Are you sure you want to disconnect?`}
            onDecline={closeModal}
            onConfirm={async () => {
              const structurePatch = [
                {
                  op: 'replace',
                  path: '/propertyId',
                  value: null
                }
              ];
              const result = await dispatch(editStructure({ structureId: structure.id, structurePatch }));
              if (editStructure.fulfilled.match(result)) {
                toast({
                  title: 'Success',
                  position: 'top',
                  description: 'Successfully disconnected location from property.',
                  status: 'success',
                  duration: 2500,
                  isClosable: true
                });
                closeModal();
              } else {
                toast({
                  title: 'Error',
                  position: 'top',
                  description: 'Failed to disconnect location from property. Please try again.',
                  status: 'error',
                  duration: 2500,
                  isClosable: true
                });
              }
            }}
          />
        )
      },
    });
  };

  return (
    <Box width="100%" padding="12px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <ModalComponent
        showModal={modalState.showModal}
        toggleModal={closeModal}
        modal={modalState.modal}
      />
      <Accordion defaultIndex={0} allowToggle>
        <AccordionItem border={0}>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton paddingLeft={0} paddingY={0}>
                  <Box as='h2' fontSize="lg" fontWeight="600" flex='1' textAlign='left'>
                    Property Info
                  </Box>
                  { isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon /> }
                </AccordionButton>
              </h2>
              <AccordionPanel padding={0}>
                <Divider marginY={1}/>
                <Stack gap="8px">
                  {property && (<Flex gap="8px">
                    <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
                      <Flex>
                        <OutlineButton
                          fontSize="sm"
                          color="#2C62CB"
                          borderColor="#2C62CB"
                          leftIcon={<UnlinkIcon fill="#2C62CB" />}
                          iconSpacing="auto"
                          onClick={() => handleDisconnect()}
                          isDisabled={!canEdit}
                        />
                      </Flex>
                    </Tooltip>
                    <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
                      <Flex flexGrow={1}>
                        <OutlineButton
                          fontSize="sm"
                          color="#2C62CB"
                          borderColor="#2C62CB"
                          leftIcon={<EditIcon fill="#2C62CB" />}
                          onClick={() => handleEditProperty()}
                          flexGrow={1}
                          isDisabled={!canEdit}
                        >
                          Edit
                        </OutlineButton>
                      </Flex>
                    </Tooltip>
                    <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
                      <Flex flexGrow={1}>
                        <OutlineButton
                          fontSize="sm"
                          color="red"
                          borderColor="red"
                          leftIcon={<DeleteIcon fill="red" />}
                          onClick={() => handleDeleteProperty()}
                          flexGrow={1}
                          isDisabled={!canEdit}
                        >
                          Delete
                        </OutlineButton>
                      </Flex>
                    </Tooltip>
                  </Flex>)}
                  {!property && (<Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
                    <Flex gap="8px">
                      <OutlineButton
                        fontSize="sm"
                        color="#2C62CB"
                        borderColor="#2C62CB"
                        leftIcon={<AddIcon fill="#2C62CB" />}
                        onClick={() => handleAddProperty()}
                        flexGrow={1}
                        isDisabled={!canEdit}
                      >
                        Add Property
                      </OutlineButton>
                    </Flex>
                  </Tooltip>)}
                  {property && (<Stack spacing="">
                    <Grid templateColumns='2fr 3fr'>
                      <GridItem><Text {...labelStyle}>Link to Property</Text></GridItem>
                      <GridItem>
                        <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
                          <Text
                            as="button"
                            fontWeight="600"
                            fontSize="sm"
                            textAlign="left"
                            color="#2C62CB"
                            onClick={onLinkToPropety}
                            disabled={!canEdit}
                          >
                            Connect Property Locations
                          </Text>
                        </Tooltip>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns='2fr 3fr'>
                      <GridItem><Text {...labelStyle}>Property Name:</Text></GridItem>
                      <GridItem><Text fontWeight="600" fontSize="sm">{property.name}</Text></GridItem>
                    </Grid>
                    <Grid templateColumns='2fr 3fr'>
                      <GridItem><Text {...labelStyle}>Property Address:</Text></GridItem>
                      <GridItem><AddressStack {...property.address} /></GridItem>
                    </Grid>
                    <Grid templateColumns='2fr 3fr'>
                      <GridItem><Text {...labelStyle}># of Locations:</Text></GridItem>
                      <GridItem><Text fontWeight="600" fontSize="sm">{structuresCount}</Text></GridItem>
                    </Grid>
                    <Grid templateColumns='2fr 3fr'>
                      <GridItem><Text {...labelStyle}>District:</Text></GridItem>
                      <GridItem><Text fontWeight="600" fontSize="sm">{property.district}</Text></GridItem>
                    </Grid>
                    <Grid templateColumns='2fr 3fr'>
                      <GridItem><Text {...labelStyle}>Station:</Text></GridItem>
                      <GridItem><Text fontWeight="600" fontSize="sm">{property.station}</Text></GridItem>
                    </Grid>
                    <Grid templateColumns='2fr 3fr'>
                      <GridItem><Text {...labelStyle}>Owner Contact:</Text></GridItem>
                      <GridItem><Text fontWeight="600" fontSize="sm">{property.ownerContact}</Text></GridItem>
                    </Grid>
                    <Grid templateColumns='2fr 3fr'>
                      <GridItem><Text {...labelStyle}>Owner Phone:</Text></GridItem>
                      <GridItem><Text fontWeight="600" fontSize="sm">{property.ownerPhone}</Text></GridItem>
                    </Grid>
                  </Stack>)}
                  {!property && <Text textAlign="center" py="8px" >No property linked.</Text>}
                </Stack>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};


export default PropertyInfoPanel;
