/* global google */

import React, { useEffect } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import SearchIcon from '../FigmaExport/SearchIcon';

const AddressSearchBox = (props) => {
  const [bounds, setBounds] = React.useState(props.bounds);
  const [searchBox, setSearchBox] = React.useState();

  const onSearchBoxMounted = (sb) => {
    setSearchBox(sb);
  };

  useEffect(() => {
    setBounds(props.bounds);
  }, [props.bounds]);

  const handlePlacesChanged = () => {
    const places = searchBox.getPlaces();

    const currentBounds = bounds ? bounds : new google.maps.LatLngBounds();
    places.forEach(place => {
      if (place.geometry.viewport) {
        currentBounds.union(place.geometry.viewport);
      } else {
        currentBounds.extend(place.geometry.location);
      }
    });
    const nextMarkers = places.map(place => ({
      position: place.geometry.location
    }));
    props.setMarkersFromSearch(nextMarkers);

  };
  return (
    <Box mr='auto'>
      <StandaloneSearchBox
        onLoad={onSearchBoxMounted}
        bounds={bounds}
        controlPosition={google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={handlePlacesChanged}
      >
        <InputGroup>
          <InputLeftElement>
            <SearchIcon />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search for an address"
            style={{
              width: '20rem',
              height: '2.5rem',
              marginRight: 'auto',
              // marginLeft: '200px',
              // top: '10px',
              // borderRadius: '3px',
              // fontSize: '14px',
              // position: 'absolute',
              // textOverflow: 'ellipses',
              // background: '#fff'
            }}
          />
        </InputGroup>
      </StandaloneSearchBox>
    </Box>
  );
};

export default AddressSearchBox;
