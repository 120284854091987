/* global google */
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  setCustomerDefaultState
} from '../actions/customer-actions';
import Map from '../components/map/MapComponent';
import ContextMenu from '../components/common/ContextMenuComponent';
import Modal from '../components/common/ModalComponent';
import HydrantDetailsForm from '../components/form/HydrantDetailsFormComponent';
import ConfirmForm from '../components/form/ConfirmFormComponent';
import ImageStrip from '../components/imageStrip/ImageStrip';
import * as customerAPI from '../api/CustomerAPI';
import * as hydrantAPI from '../api/HydrantAPI';
import * as structureAPI from '../api/StructureAPI';
import * as UserAPI from '../api/UserAPI';
import * as ImageAPI from '../api/ImageAPI';
import AnnotatorContainer from '../containers/AnnotatorContainer';
import { MapFilters } from '../components/drawer/MapFilters';
import { TabsContainer } from './TabsContainer';
import { FaRuler } from '@react-icons/all-files/fa/FaRuler';

import { fetchAddress, getDistance } from '../helpers/GoogleMaps';
import {
  Flex,
  Box,
  Button,
  FormControl,
  Tooltip,
  Switch,
  HStack,
  Icon,
  Spinner,
  Text,
  Center,
  Stack,
} from '@chakra-ui/react';
// import { PrePlanFormContainer } from './PrePlanFormContainer';
import { OutlineButton, PrimaryButton, SubmitButtonDanger } from '../components/form/Button';
import { FlexWithBackgroundTheme } from './ThemeContainer';
import {
  getAllStructures,
  deleteStructure,
  selectAllStructures,
  selectStructureById,
  setSelectedStructureId,
  setSelectedStructures,
  setStructureFilters,
  fetchPartnerStructures,
  editStructure,
  setStructureToEdit
} from '../features/structures/structuresSlice';
import {
  fetchAllHydrants,
  hydrantSelected,
  clearSelectedHydrants,
  setSelectedHydrants,
  selectAllHydrants,
  deleteHydrant,
  fetchPartnerHydrants
} from '../features/hydrants/hydrantsSlice';
import { getAllProperties, selectAllProperties, fetchPartnerProperties } from '../features/properties/propertiesSlice';
import { getAllOccupants, fetchPartnerOccupants } from '../features/occupants/occupantsSlice';
import { getAllImages, setAnnotation } from '../features/images/imagesSlice';
import { ConnectPropertyStructuresPanel } from '../components/map/ConnectPropertyStructuresPanel';
import DeleteIcon from '../components/FigmaExport/DeleteIcon';
import EditIcon from '../components/FigmaExport/EditIcon';
import MoveIcon from '../components/FigmaExport/MoveIcon';
import ResizeIcon from '../components/FigmaExport/ResizeIcon';
import HydrantIcon from '../components/FigmaExport/HydrantIcon';
import RestructureIcon from '../components/FigmaExport/RestructureIcon';
// import { StructureForm } from '../components/form/StructureForm';


const mapMenuItems = [
  {
    value: 'PREPLAN_LOCATION',
    label: 'Pre-Plan Location',
    icon: (<ResizeIcon />),
    color: '#2C62CB',
  },
  {
    value: 'ADD_HYDRANT',
    label: 'Add Hydrant',
    icon: (<HydrantIcon />),
    color: '#2C62CB',
  }
];

const hydrantMenuItems = [
  {
    value: 'EDIT_HYDRANT',
    label: 'Edit Hydrant',
    icon: (<EditIcon />),
    color: '#2C62CB',
  },
  {
    value: 'MOVE_HYDRANT',
    label: 'Move Hydrant',
    icon: (<MoveIcon />),
    color: '#2C62CB',
  },
  {
    value: 'DELETE_HYDRANT',
    label: 'Delete Hydrant',
    icon: (<DeleteIcon />),
    color: 'red',
  }
];

const structureMenuItems = [
  {
    value: 'EDIT_STRUCTURE',
    label: 'Edit Location',
    icon: (<EditIcon />),
    color: '#2C62CB',
  },
  {
    value: 'REPLAN_STRUCTURE',
    label: 'Re-Plan Location',
    icon: (<RestructureIcon />),
    color: '#2C62CB',
  },
  {
    value: 'MOVE_STRUCTURE',
    label: 'Move Location',
    icon: (<MoveIcon />),
    color: '#2C62CB',
  },
  {
    value: 'DELETE_STRUCTURE',
    label: 'Delete Location',
    icon: (<DeleteIcon />),
    color: 'red',
  }
];

class MapContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      action: null,
      loading: false,
      changeCounter: new Date().getTime(),
      showLightbox: false,
      showAnnotations: true,
      currentImage: null,
      contextMenu: null,
      showModal: false,
      showFilters: false,
      modal: {
        heading: null,
        body: null
      },
      isPrePlanningMode: false,
      showDrawingTools: false,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      prePlanLatLng: null,
      isGettingPreplan: false,
      isAnnotationMode: false,
      reDrawPolygon: false,
      locationKounter: null,
      getDistacebyPolyline: '',
      distancePolyline: null,
      prevPolylineData: [],
      showDrawingToolsOption: null,
      formStorey: null,
      formStoreyBelow: null,
      hydrantInMove: null,
      buildingInMove: null,
      buildingDragStartingPoint: null,
      propertyId: null,
      showConnectPanel: false,
      originalPropertyStructures: null,
      actionLoading: false,
    };
    this.setBuildingDragStartingPoint = this.setBuildingDragStartingPoint.bind(this);
    this.handleHydrantMove = this.handleHydrantMove.bind(this);
    this.handleBuildingMove = this.handleBuildingMove.bind(this);
    this.onBuildingDragEnd = this.onBuildingDragEnd.bind(this);
    this.handleBuildingPinMove = this.handleBuildingPinMove.bind(this);
    this.handleStructureClick = this.handleStructureClick.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleMapRightClick = this.handleMapRightClick.bind(this);
    this.handleConnectPropertyStructures = this.handleConnectPropertyStructures.bind(this);
    this.handleDisconnect = this.handleDisconnect.bind(this);
    this.handleContextMenuItemSelect = this.handleContextMenuItemSelect.bind(this);
    this.handleHydrantFormSubmissionError = this.handleHydrantFormSubmissionError.bind(this);
    this.handleHydrantRightClick = this.handleHydrantRightClick.bind(this);
    this.handleGetHydrantsSuccess = this.handleGetHydrantsSuccess.bind(this);
    this.handleGetHydrantsError = this.handleGetHydrantsError.bind(this);
    this.handleCancelPrePlanLocation = this.handleCancelPrePlanLocation.bind(this);
    this.handleCancelHydrantMove = this.handleCancelHydrantMove.bind(this);
    this.handleCancelRePlan = this.handleCancelRePlan.bind(this);
    this.handleCancelBuildingMove = this.handleCancelBuildingMove.bind(this);
    this.onCancelAction = this.onCancelAction.bind(this);
    this.handleCancelPrePlanConfirm = this.handleCancelPrePlanConfirm.bind(this);
    this.handlePolygonComplete = this.handlePolygonComplete.bind(this);
    this.handleStructureRightClick = this.handleStructureRightClick.bind(this);
    this.handleStructureDeleteSuccess = this.handleStructureDeleteSuccess.bind(this);
    this.handleStructureDeleteConfirm = this.handleStructureDeleteConfirm.bind(this);
    this.handleEditLocationSuccess = this.handleEditLocationSuccess.bind(this);
    this.onSubmitAction = this.onSubmitAction.bind(this);
    this.toggleLightbox = this.toggleLightbox.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.falseModal = this.falseModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeContextMenu = this.closeContextMenu.bind(this);
    this.setHydrantProps = this.setHydrantProps.bind(this);
    this.showInitialLoading = this.showInitialLoading.bind(this);
    this.populateDataForms = this.populateDataForms.bind(this);
    this.selectStructureByMessage = this.selectStructureByMessage.bind(this);
    this.handleNewMessageSuccess = this.handleNewMessageSuccess.bind(this);
    this.handleNewMessageError = this.handleNewMessageError.bind(this);
    this.handleNewMessageConnect = this.handleNewMessageConnect.bind(this);
    this.handleNewMessageClosed = this.handleNewMessageClosed.bind(this);
    this.handleRePlanStart = this.handleRePlanStart.bind(this);
    this.handleMoveStart = this.handleMoveStart.bind(this);
    this.showLoadingLocation = this.showLoadingLocation.bind(this);
    this.handleLocationSuccess = this.handleLocationSuccess.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);
    this.handleFilterCriteria = this.handleFilterCriteria.bind(this);
    this.handleLocatorClick = this.handleLocatorClick.bind(this);
    this.handleNextLocatorClick = this.handleNextLocatorClick.bind(this);
    this.handlePrevLocatorClick = this.handlePrevLocatorClick.bind(this);
    this.handlePolylineComplete = this.handlePolylineComplete.bind(this);
    this.handleDistanceMode = this.handleDistanceMode.bind(this);
    this.setMarkersFromSearch = this.setMarkersFromSearch.bind(this);
    // @todo required for location centering in order for map to render correctly
    // we can ensure the global is loaded beacuse we are wrapped using a loader function
    // so this component only renders when the global google object is loaded.
    this.geocoder = new google.maps.Geocoder();

    this.setModal = this.setModal.bind(this);
    this.toggleShowFilters = this.toggleShowFilters.bind(this);
  }

  componentDidMount() {
    this.setState({
      changeCounter: new Date().getTime()
    });
  }

  handleNewMessageSuccess(event) {
    this.setState({
      newMsg: true,
      errorConnection: false,
      closedConnection: false
    });
  }

  handleNewMessageError(event) {
    this.setState({
      newMsg: false,
      errorConnection: true,
      closedConnection: false
    });
  }

  handleNewMessageConnect(event) {
    this.setState({
      errorConnection: false,
      closedConnection: false
    });
  }

  handleNewMessageClosed(event) {
    this.setState({
      newMsg: false,
      errorConnection: true,
      closedConnection: true
    });
  }

  // handledFailedCentereLocation = (nextProps) => {
  //   // @todo handle failed center?
  //   this.geocoder.geocode({ 'address': `${nextProps.customer.city} ${nextProps.customer.state}` }, function (results, status) {
  //     if (status === 'OK') {
  //       nextProps.customer.config.lat = results[0].geometry.location.lat();
  //       nextProps.customer.config.lon = results[0].geometry.location.lng();
  //     } else {
  //       nextProps.customer.config.lat = 41.1209;
  //       nextProps.customer.config.lon = -88.8354;
  //     }
  //   });
  // }

  showLoadingLocation() {
    this.setState({
      loading: true
    });
  }

  // @todo confirm this is what we want...
  handleLocationSuccess(location) {
    const { structures } = this.props;
    const selectedStructure = structures.find(l => l.id === location.id);
    if (this.props.selectedStructure) {
      if (this.props.selectedStructure.id !== selectedStructure.id) {
        this.setState({
          isPrePlanningMode: false
        });
      }
    }
  }

  handleLocationError() {
    this.setState({
      loading: false
    });
    const message = 'Oops.. Something went wrong!!';
    const newState = Object.assign({}, this.state);
    newState.modal.body = (
      <div>
        <div> {message} </div>
        <div> Check your internet connection and refresh the browser </div>
        <div className="text-align-right margin-top-10px">
          <Button type="button" bsStyle="primary" onClick={this.toggleModal}>
            OK
          </Button>
        </div>
      </div>
    );
    this.setState(newState);
    this.showModal();
  }

  handleStructureClick(targetStructure) {
    if (this.state.hydrantInMove || this.state.buildingInMove) return;
    if (this.props.structureToEdit) {
      this.handleCancelPrePlanLocation();
      return;
    }
    if (this.state.showConnectPanel) {
      const selectedStructuresList = this.props.selectedStructures ? this.props.selectedStructures : [];
      const index = selectedStructuresList.findIndex(structure => structure.id === targetStructure.id);
      if (index > -1) {
        this.props.setSelectedStructures(selectedStructuresList.filter((_, i) => i !== index));
      } else {
        this.props.setSelectedStructures([...selectedStructuresList, targetStructure]);
      }
      return;
    }

    const isCurrent = this.props.selectedStructure && this.props.selectedStructure.id === targetStructure.id;
    if (this.state.isPrePlanningMode) {
      this.handleCancelPrePlanLocation();
    } else {
      // Did we just click the current structure?
      this.props.setSelectedStructureId(isCurrent ? null : targetStructure.id);
      if (isCurrent) {
        this.props.clearSelectedHydrants();
      } else {
        this.props.setSelectedHydrants(targetStructure.hydrants || []);
      }
    }
    this.closeContextMenu();
    //Check Location Reordering
  }

  handleMapClick(e) {
    this.closeContextMenu();
  }

  handleMapRightClick(pixel, latLng, address) {
    if (this.state.isPrePlanningMode ||
        this.state.hydrantInMove ||
        this.state.buildingInMove) {
      return;
    }
    this.setState({
      contextMenu: {
        entity: {
          x: pixel.x + 35,
          y: pixel.y + 40,
          latLng: latLng,
          streetAddress: address
        },
        items: mapMenuItems
      }
    });
  }

  handleHydrantRightClick(pixel, hydrant) {
    this.setState({
      contextMenu: {
        entity: {
          id: hydrant.id,
          x: pixel.x,
          y: pixel.y,
          latLng: {
            lat: hydrant.position.lat,
            lng: hydrant.position.lng
          },
          streetAddress: hydrant.streetAddress,
          flow: hydrant.flow,
          size: hydrant.size,
          notes: hydrant.notes,
          inService: hydrant.inService,
          dryHydrant: hydrant.dryHydrant,
          outServiceDate: hydrant.outServiceDate,
          hydrantId: hydrant.hydrantId,
        },
        items: hydrantMenuItems
      }
    });
  }

  handleStructureRightClick(pixel, structure) {
    if (this.state.isPrePlanningMode ||
        this.state.hydrantInMove ||
        this.state.buildingInMove) {
      return;
    }
    this.setState({
      contextMenu: {
        entity: {
          structure: structure,
          x: pixel.x,
          y: pixel.y
        },
        items: structureMenuItems
      }
    });
  }

  handleConnectPropertyStructures(propertyId) {
    const structure = this.props.selectedStructure;
    const propertyStructures = structure.propertyId ? this.props.structures.filter(s => s.propertyId === structure.propertyId) : [structure];
    this.props.setSelectedStructures(propertyStructures);
    this.setState({
      showConnectPanel: true,
      propertyId: propertyId,
      originalPropertyStructures: propertyStructures,
    });
  }

  handleDisconnect(callback) {
    this.setState({
      modal: {
        heading: 'Disconnect Property',
        body: (
          <ConfirmForm
            body={`Are you sure you want to disconnect?`}
            onDecline={this.toggleModal}
            onConfirm={async () => {
              this.toggleModal();
              await callback();
            }}
          />
        )
      }
    });
    this.toggleModal();
  }

  handleContextMenuItemSelect(menuItem, entity) {

    const { structures } = this.props;
    this.closeContextMenu();
    switch (menuItem.value) {
      case 'ADD_HYDRANT':
        this.setState({
          modal: {
            heading: 'Add Hydrant',
            body: (
              <HydrantDetailsForm
                hydrant={entity}
                closeModal={this.toggleModal}
                action={menuItem.value}
              />
            )
          }
        });
        this.toggleModal();
        break;

      case 'EDIT_HYDRANT':
        this.setState({
          modal: {
            heading: 'Edit Hydrant',
            body: (
              <HydrantDetailsForm
                hydrant={entity}
                closeModal={this.toggleModal}
                action={menuItem.value}
              />
            )
          }
        });
        this.toggleModal();
        break;

      case 'MOVE_HYDRANT':
        this.setState({
          hydrantInMove: entity,
        });
        break;

      case 'DELETE_HYDRANT':
        this.setState({
          modal: {
            heading: <Text><Icon as={DeleteIcon} fill="black" mr="4px" />Delete Hydrant</Text>,
            body: <HydrantDetailsForm hydrant={entity} closeModal={this.toggleModal} action={menuItem.value} />,
          }
        });
        this.toggleModal();
        break;
      case 'DELETE_HYDRANT_ALL':
        this.setState({
          modal: {
            heading: 'Delete All Hydrants',
            body: (
              <ConfirmForm
                body="Are you sure you want to delete all hydrants?"
                onDecline={this.toggleModal}
                form={{
                  action: menuItem.value,
                  entity: entity
                }}
              />
            )
          }
        });
        this.toggleModal();
        break;
      case 'PREPLAN_LOCATION':
        this.setState({
          isPrePlanningMode: true,
          showDrawingTools: true,
          showDrawingToolsOption: 'Polygon',
          action: 'ADD_STRUCTURE',
        });
        this.props.clearSelectedHydrants();
        this.props.setSelectedStructureId(null);
        break;

      case 'EDIT_STRUCTURE':
        const structureToEdit = structures.find(l => l.id === entity.structure.id);
        this.props.setSelectedStructureId(structureToEdit.id);
        this.props.setSelectedHydrants(structureToEdit.hydrants || []);
        this.props.setStructureToEdit(entity.structure);

        // this.setState({
        //   modal: {
        //     heading: 'Edit Structure',
        //     body: (
        //       <StructureForm
        //         structure={structureToEdit}
        //         action="EDIT_STRUCTURE"
        //         closeModal={this.toggleModal}
        //       />
        //     )
        //   }
        // });
        // this.toggleModal();
        break;

      case 'REPLAN_STRUCTURE':
        this.handleRePlanStart(entity.structure);
        break;

      case 'MOVE_STRUCTURE':
        this.handleMoveStart(entity.structure);
        break;

      case 'DELETE_STRUCTURE':
        this.setState({
          modal: {
            heading: <Text><Icon as={DeleteIcon} fill="black" mr="4px" />Permanently delete Pre-Plan</Text>,
            body: (
              <Stack spacing="1rem">
                <Text>Are you sure you want to delete <strong>{ entity.structure.name || "this location"}</strong>?</Text>
                <Text>All occupants in this Pre-Plan will be deleted and this action can’t be undone.</Text>
              </Stack>
            ),
            footer: (<HStack justifyContent="end" spacing="1rem">
              <OutlineButton onClick={this.toggleModal} width="8rem">No</OutlineButton>
              <SubmitButtonDanger onClick={() => this.handleStructureDeleteConfirm(entity.structure.id)} width="8rem">Delete</SubmitButtonDanger>
            </HStack>)
          }
        });
        this.toggleModal();
        break;

      default:
        break;
    }
  }

  handleHydrantMove(pixel, latLng) {
    const newHydrant = {
      ...this.state.hydrantInMove,
      x: pixel.x,
      y: pixel.y,
      latLng: latLng,
    };

    this.setState({
      modal: {
        heading: 'Edit Hydrant',
        body: (
          <HydrantDetailsForm
            hydrant={newHydrant}
            closeModal={() => {
              this.setState({
                hydrantInMove: null,
                showModal: !this.state.showModal
              });
            }}
            action={'EDIT_HYDRANT'}
          />
        )
      }
    });
    this.toggleModal();
  }

  setBuildingDragStartingPoint(latLng) {
    this.setState({
      buildingDragStartingPoint: latLng
    });
  }

  onBuildingDragEnd({lat, lng}) {
    if (!this.state.buildingDragStartingPoint) return;

    const distance = {
      lat: lat - this.state.buildingDragStartingPoint.lat,
      lng: lng - this.state.buildingDragStartingPoint.lng
    };
    this.handleBuildingMove(distance);
  }

  handleBuildingMove(distance) {
    if (!this.state.buildingInMove) return;

    const newGeoOutline = this.state.buildingInMove.structure.geoOutline.map(
      coord => ({
        latitude: coord.latitude + distance.lat,
        longitude: coord.longitude + distance.lng
      }));

    const newStructure = {
      ...this.state.buildingInMove.structure,
      geoOutline: newGeoOutline
    };

    this.setState({
      buildingDragStartingPoint: null,
      buildingInMove: {
        ...this.state.buildingInMove,
        structure: newStructure
      }
    });
  }

  handleBuildingPinMove(latLng) {
    if (!this.state.buildingInMove) return;

    const newStructure = {
      ...this.state.buildingInMove.structure,
      latLon: latLng
    };

    this.setState({
      buildingDragStartingPoint: null,
      buildingInMove: {
        ...this.state.buildingInMove,
        structure: newStructure
      }
    });
  }

  handleHydrantFormSubmissionError(result) {
    const newState = Object.assign({}, this.state);
    if (result && result.data && result.data.conflictingLocations) {
      newState.modal = {
        heading: 'Cannot Delete Hydrant',
        body: <Text>Cannot delete hydrants binded to a location.</Text>,
        footer: <Flex justifyContent="right"><PrimaryButton onClick={this.toggleModal} w="8rem">Close</PrimaryButton></Flex>
      };
    } else {
      newState.modal.body = (
        <div>
          <span>There was an error submitting your request. Please try again. </span>
        </div>
      );
    }
    this.setState(newState);
  }

  handleGetHydrantsSuccess(message) {
    if (message) {
      const newState = Object.assign({}, this.state);
      newState.modal.body = (
        <div>
          <div> {message} </div>
          <div className="text-align-right margin-top-10px">
            <Button type="button" bsStyle="primary" onClick={this.toggleModal}>
              OK
            </Button>
          </div>
        </div>
      );
      this.setState(newState);
      this.showModal();
    } else {
      this.falseModal();
    }
  }

  handleGetHydrantsError(msg) {
    let message = 'Error fetching hydrants';
    if (msg) {
      message = msg;
    }
    const newState = Object.assign({}, this.state);
    newState.modal.body = (
      <div>
        <div> {message} </div>
        <div> Check your internet connection and refresh the browser </div>
        <div className="text-align-right margin-top-10px">
          <Button type="button" bsStyle="primary" onClick={this.toggleModal}>
            OK
          </Button>
        </div>
      </div>
    );
    this.setState(newState);
    this.showModal();
  }

  handleCancelPrePlanLocation() {
    this.setState({
      modal: {
        heading: 'Exit Pre-planning',
        body: <Text>Are you sure you want to exit from Pre-planning?</Text>,
        footer: (<HStack justifyContent="end" spacing="1rem">
          <OutlineButton onClick={this.toggleModal} width="8rem">No</OutlineButton>
          <PrimaryButton onClick={this.handleCancelPrePlanConfirm} width="8rem">Yes</PrimaryButton>
        </HStack>)
      }
    });
    this.toggleModal();
  }

  handleCancelHydrantMove() {
    this.setState({
      modal: {
        heading: 'Exit Hydrant Move',
        body: <Text>Are you sure you want to cancel moving hydrant?</Text>,
        footer: (<HStack justifyContent="end" spacing="1rem">
          <OutlineButton onClick={this.toggleModal} width="8rem">No</OutlineButton>
          <PrimaryButton onClick={this.handleCancelPrePlanConfirm} width="8rem">Yes</PrimaryButton>
        </HStack>)
      }
    });
    this.toggleModal();
  }

  handleCancelBuildingMove() {
    this.setState({
      modal: {
        heading: 'Exit Location Move',
        body: <Text>Are you sure you want to cancel moving location?</Text>,
        footer: (<HStack justifyContent="end" spacing="1rem">
          <OutlineButton onClick={this.toggleModal} width="8rem">No</OutlineButton>
          <PrimaryButton onClick={this.handleCancelPrePlanConfirm} width="8rem">Yes</PrimaryButton>
        </HStack>)
      }
    });
    this.toggleModal();
  }

  handleCancelRePlan() {
    this.setState({
      modal: {
        heading: 'Exit Location RePlan',
        body: <Text>Are you sure you want to cancel replanning location?</Text>,
        footer: (<HStack justifyContent="end" spacing="1rem">
          <OutlineButton onClick={this.toggleModal} width="8rem">No</OutlineButton>
          <PrimaryButton onClick={this.handleCancelPrePlanConfirm} width="8rem">Yes</PrimaryButton>
        </HStack>)
      }
    });
    this.toggleModal();
  }

  onCancelAction() {
    if (this.state.hydrantInMove) this.handleCancelHydrantMove();
    else if (this.state.buildingInMove) this.handleCancelBuildingMove();
    else if (this.state.action === 'REPLAN_STRUCTURE') this.handleCancelRePlan();
    else this.handleCancelPrePlanLocation();
  }

  setMarkersFromSearch(markers) {
    this.setState({ markersFromSearch: markers });
  }

  selectStructureByMessage(message) {
    if (message.locationID) {
      const structure = this.props.structures.find(l => l.id === message.locationID);
      if (structure !== this.props.selectedStructure) {
        this.setState({
          isPrePlanningMode: false,
          showDrawingTools: false,
          isPolygonDrawn: false,
          prePlanGeoOutline: [],
          prePlanLatLng: null,
          newMsg: false
        }, () => {
          this.props.setSelectedHydrants(structure.hydrants || []);
        });
        // Moving selected location logic outside of local state.
        this.props.setSelectedStructureId(structure.id);

      }
    } else {
      this.setState({
        isPrePlanningMode: false,
        showDrawingTools: false,
        isPolygonDrawn: false,
        prePlanGeoOutline: [],
        prePlanLatLng: null,
        newMsg: false
      }, () => {
        this.props.clearSelectedHydrants();
      });
      // Reset current location and move it outside of current state.
      this.props.setSelectedStructureId(null);
    }

    if (message.latLon) {
      const marker = { position: { lat: message.latLon.latitude, lng: message.latLon.longitude } };
      this.setMarkersFromSearch([
        marker
      ]);
    }

    return true;
  }

  handleCancelPrePlanConfirm() {
    this.toggleModal();
    this.props.setStructureToEdit(null);
    this.setState({
      isPrePlanningMode: false,
      hydrantInMove: null,
      buildingInMove: null,
      buildingDragStartingPoint: null,
      showDrawingTools: false,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      prePlanLatLng: null,
      reDrawPolygon: false,
      action: null,
    }, () => {
      this.props.setSelectedStructureId(null);
      this.props.clearSelectedHydrants();
      this.removePolygons();
    });
  }

  handleRePlanStart(structureToReplan) {
    this.props.setSelectedStructureId(structureToReplan.id);
    this.props.clearSelectedHydrants();

    this.setState({
      showDrawingTools: true,
      showDrawingToolsOption: 'Polygon',
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      prePlanLatLng: null,
      reDrawPolygon: true,
      formStorey: null,
      formStoreyBelow: null,
      action: 'REPLAN_STRUCTURE',
    });
  }

  handleMoveStart(structureToMove) {
    this.props.setSelectedStructureId(structureToMove.id);
    this.props.clearSelectedHydrants();

    this.setState({
      buildingInMove: { structure: structureToMove },
      buildingDragStartingPoint: null,
      action: 'MOVE_STRUCTURE',
    });
  }

  async handlePolygonComplete(coords) {
    this.setState({
      actionLoading: true,
    });
    const locationCoords = [];
    coords.forEach(function(coord) {
      locationCoords.push({
        latitude: coord.lat,
        longitude: coord.lng
      });
    });
    locationCoords.push({
      latitude: coords[0].lat,
      longitude: coords[0].lng
    });

    if (this.state.action === 'ADD_STRUCTURE') {
      const preplanResponse = await this.props.getPrePlan(
        locationCoords,
        1,
        0,
        this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan')
      );
      const preplanData = preplanResponse.data;
      const address = await fetchAddress(coords);
      const structureAdd = {
        geoOutline: locationCoords,
        roofArea: preplanData.roofArea,
        requiredFlow: preplanData.requiredFlow,
      };
      if (preplanData.hydrants) {
        structureAdd.hydrants = preplanData.hydrants.map(hyd => hyd.id);
      }
      if (address) {
        structureAdd.address = {};
        address.address1 && (structureAdd.address.address1 = address.address1);
        address.address2 && (structureAdd.address.address2 = address.address2);
        address.city && (structureAdd.address.city = address.city);
        address.state && (structureAdd.address.state = address.state);
        address.zip && (structureAdd.address.zip = address.zip);
      }
      this.props.setStructureToEdit(structureAdd);
    } else if (this.state.action === 'REPLAN_STRUCTURE') {
      const structure = this.props.selectedStructure;
      const preplanResponse = await this.props.getPrePlan(
        locationCoords,
        structure.floors ? structure.floors.length : 1,
        0,
        this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan')
      );
      const preplanData = preplanResponse.data;
      const structurePatch = [
        {
          op: 'replace',
          path: '/geoOutline',
          value: locationCoords
        },
        {
          op: 'replace',
          path: '/roofArea',
          value: preplanData.roofArea
        },
        {
          op: 'replace',
          path: '/requiredFlow',
          value: preplanData.requiredFlow
        },
      ];
      if (preplanData.hydrants) {
        structurePatch.push({
          op: 'replace',
          path: '/hydrants',
          value: preplanData.hydrants.map(hyd => hyd.id)
        });
      }
      const result = await this.props.editStructure(structure?.id, structurePatch);
      if (editStructure.fulfilled.match(result)) {
        this.props.setSelectedStructureId(result.payload.id);
        this.props.setSelectedHydrants(result.payload.hydrants || []);
        console.log('Success', 'Location replaned');
      } else {
        console.log('Failed to replan location');
      }
      // this.setState({
      //   isPolygonDrawn: true,
      //   isPrePlanningMode: false,
      //   isGettingPreplan: false,
      //   showDrawingTools: false,
      //   showDrawingToolsOption: null,
      //   action: null,
      //   actionLoading: false,
      // })
      this.removePolygons();
    }

    this.setState({
      isPolygonDrawn: true,
      isPrePlanningMode: false,
      isGettingPreplan: false,
      showDrawingTools: false,
      showDrawingToolsOption: null,
      action: null,
      actionLoading: false,
    });
    // this.removePolygons();
  }


  handlePolylineComplete(polyline) {
    const path = polyline.getPath();
    let dist = 0; // Distance in meters
    for (let ii = 1; ii < path.length; ii++) {
      dist += getDistance(path.getAt(ii - 1).lat(), path.getAt(ii - 1).lng(), path.getAt(ii).lat(), path.getAt(ii).lng());
    }
    this.setState({
      getDistacebyPolyline: `${Math.round(dist)}ft.`,
      distancePolyline: {
        polyline: polyline,
        distance: `${Math.round(dist)}ft.`
      }
    });
  }

  handleDistanceMode(e) {
    if (e.target.checked) {
      this.setState({
        showDrawingTools: true,
        showDrawingToolsOption: 'Polyline'
      });
    } else {
      this.setState({
        showDrawingTools: false,
        showDrawingToolsOption: null,
        getDistacebyPolyline: '',
        distancePolyline: null
      });
    }
  }

  handleGetPreplanSuccess(preplanData) {
    if (this.state.reDrawPolygon) {
      // const alocationDataForm = this.state.propertyDataForm;
      // alocationDataForm.roofArea = preplanData.roofArea;
      // alocationDataForm.requiredFlow = preplanData.requiredFlow;
      // this.setState({
      //   isPolygonDrawn: true,
      //   isPrePlanningMode: true,
      //   prePlanGeoOutline: preplanData.geoOutline,
      //   propertyDataForm: alocationDataForm,
      //   isGettingPreplan: false,
      //   reDrawPolygon: false,
      //   showDrawingTools: false,
      //   showDrawingToolsOption: null,
      //   getDistacebyPolyline: '',
      //   distancePolyline: null,
      // });
      // this.props.setSelectedHydrants(preplanData.hydrants ? preplanData.hydrants.map(hyd => hyd.id) : []);
    } else if (this.state.buildingInMove) {
      // const alocationDataForm = this.state.propertyDataForm;
      // alocationDataForm.roofArea = preplanData.roofArea;
      // alocationDataForm.requiredFlow = preplanData.requiredFlow;
      // alocationDataForm.action = 'EDIT_LOCATION';
      // this.setState({
      //   isPolygonDrawn: true,
      //   isPrePlanningMode: true,
      //   prePlanGeoOutline: preplanData.geoOutline,
      //   propertyDataForm: alocationDataForm,
      //   isGettingPreplan: false
      // });
      // this.props.setSelectedHydrants(preplanData.hydrants ? preplanData.hydrants.map(hyd => hyd.id) : []);
    } else {
      const preplanAddress = {};
      // @todo handle location geocoding differently
      const _this = this;
      this.geocoder.geocode(
        {
          location: {
            lat: preplanData.planningCenter.latitude,
            lng: preplanData.planningCenter.longitude
          }
        },
        function(results, status) {
          // google.maps.GeocoderStatus.OK
          if (status === 'OK') {
            const tmp = { address1: null, city: null, state: null, zip: null };
            for (let i  = 0; i < results.length && results[i]; i++) {
              tmp.address1 = null;
              tmp.city = null;
              tmp.state = null;
              tmp.zip = null;
              results[i].address_components.forEach(function(address) {
                const addressComponent = address.short_name;
                if (address.types && address.types[0] && addressComponent) {
                  switch (address.types[0]) {
                    case 'street_number':
                      tmp.address1 = addressComponent;
                      break;
                    case 'route':
                      tmp.address1 = `${tmp.address1} ${addressComponent}`;
                      break;
                    case 'locality':
                      tmp.city = addressComponent;
                      break;
                    case 'administrative_area_level_1':
                      tmp.state = addressComponent;
                      break;
                    case 'postal_code':
                      tmp.zip = addressComponent;
                      break;
                    default:
                      break;
                  }
                }
              });
              if (tmp.address1) break;
            }
            preplanAddress.address1 = tmp.address1;
            preplanAddress.city = tmp.city;
            preplanAddress.state = tmp.state;
            preplanAddress.zip = tmp.zip;
          }
          _this.setState({
            isPolygonDrawn: true,
            isPrePlanningMode: true,
            prePlanGeoOutline: preplanData.geoOutline,
            preplanData: preplanData,
            preplanAddress: preplanAddress,
            isGettingPreplan: false
          });
          _this.props.setSelectedHydrants(preplanData.hydrants ? preplanData.hydrants.map(hyd => hyd.id) : []);
        }
      );
    }
  }

  handleEditLocationSuccess() {
    this.setState({
      isPrePlanningMode: false,
      showDrawingTools: false,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      buildingInMove: null,
      buildingDragStartingPoint: null,
      prePlanLatLng: null,
      action: null,
    }, () => {
      this.removePolygons();
    });
  }

  async onSubmitAction() {
    if (this.state.buildingInMove) { // or action === MOVE
      const structure = this.state.buildingInMove.structure;
      const structurePatch = [];
      if (structure.latLon) { // pin
        structurePatch.push({
          op: 'replace',
          path: '/latLon',
          value: structure.latLon
        });
      } else { // polygon
        const preplanResponse = await this.props.getPrePlan(
          structure.geoOutline,
          structure.floors ? structure.floors.length : 1,
          0,
          this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan')
        );
        const preplanData = preplanResponse.data;
        structurePatch.push({
          op: 'replace',
          path: '/geoOutline',
          value: structure.geoOutline
        });
        structurePatch.push({
          op: 'replace',
          path: '/roofArea',
          value: preplanData.roofArea
        });
        structurePatch.push({
          op: 'replace',
          path: '/requiredFlow',
          value: preplanData.requiredFlow
        });
        if (preplanData.hydrants) {
          structurePatch.push({
            op: 'replace',
            path: '/hydrants',
            value: preplanData.hydrants.map(hyd => hyd.id)
          });
        }
      }
      const result = await this.props.editStructure(structure.id, structurePatch);
      if (editStructure.fulfilled.match(result)) {
        this.props.setSelectedStructureId(result.payload.id);
        this.props.setSelectedHydrants(result.payload.hydrants || []);
        console.log('Success', 'Location moved');
      } else {
        console.log('Failed to move location');
      }
    } else if (this.state.action === 'REPLAN_STRUCTURE') {
      // TODO
    }
    this.handleEditLocationSuccess();
  }

  async handleStructureDeleteConfirm(structureId) {
    const response = await this.props.deleteStructure(structureId);
    if (deleteStructure.fulfilled.match(response)) {
      this.handleStructureDeleteSuccess();
    }
  }

  handleStructureDeleteSuccess() {
    this.toggleModal();
    this.props.clearSelectedHydrants();
    this.props.setSelectedStructureId(null);
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  falseModal() {
    this.setState({
      showModal: false
    });
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  setModal(modal) {
    this.setState({
      modal: modal,
      showModal: true
    });
  }

  annotateImage(image) {
    const imageId = image.id;
    ImageAPI.getAnnotation(imageId).then((res, err) => {
      if (err) console.warn(err);
      let json = res.data;
      if (res.data === '') {
        json = null;
      } else if (res.data) {
        json = JSON.parse(res.data);
      }
      this.setState({
        currentImage: image,
        annotationJson: json,
        isAnnotationMode: true
      });
    });
  }

  showSavingAnnotation(progress) {
    const savingModal = {
      heading: 'Saving',
      body: (
        <div>
          <span> Saving Annotated Image </span>
        </div>
      ),
      footer: (<span />)
    };
    this.setState({
      modal: savingModal
    }, () => {
      this.toggleModal();
    });
  }

  async saveAnnotation(json, svg) {
    const imageId = this.state.currentImage.id;
    // const locationId = this.props.selectedStructure.id;
    // const selectLocation = this.props.structures[this.props.structures.findIndex(l => l.id === locationId)];

    this.showSavingAnnotation(90);

    const result = await this.props.setAnnotation(imageId, json, svg);
    if (setAnnotation.fulfilled.match(result)) {
      this.setState({
        isAnnotationMode: false,
        changeCounter: new Date().getTime()
      }, () => {
        // this.props.setSelectedHydrants(selectLocation.hydrants || []);
        this.toggleModal();
      });
    } else {
      console.warn('Annotation save failed', result.payload);
    }
  }

  cancelAnnotation() {
    // const locationId = this.props.selectedStructure.id;
    // const selectLocation = this.props.structures[this.props.structures.findIndex(l => l.id === locationId)];
    this.setState({
      isAnnotationMode: false,
      changeCounter: new Date().getTime()
    }, () => {
      // this.props.setSelectedHydrants(selectLocation.hydrants || []);
    });
  }

  toggleLightbox() {
    this.setState({
      showLightbox: !this.state.showLightbox,
      showAnnotations: true
    });
  }

  closeContextMenu() {
    this.setState({
      contextMenu: null
    });
  }

  setHydrantProps(hydrant, props) {
    if ((this.state.isPrePlanningMode && !hydrant.isMine) || this.state.hydrantInMove || this.state.buildingInMove) {
      return;
    }
    this.props.hydrantSelected(hydrant.id);
  }

  showInitialLoading(msg, stepIndex, stepTotal) {
    let progressKnt = 100;
    let stepMsg = null;
    if (stepIndex > 0 && stepTotal > 0) {
      progressKnt = (stepIndex / stepTotal) * 100;
      if (progressKnt > 100) {
        progressKnt = 100;
      }
      stepMsg = `Step ${stepIndex}/${stepTotal}`;
    }
    let msgStr = 'Loading In Progress';
    if (msg) {
      msgStr = msg;
    }
    const loadingModal = {
      heading: 'Loading',
      body: (
        <div>
          <span> {msgStr} </span>
          <div> {stepMsg} </div>
        </div>
      )
    };
    this.setState({
      modal: loadingModal
    }, () => {
      this.toggleModal();
    });

  }

  populateDataForms(action, preplanData) {
    this.setState({
      isGettingPreplan: false
    });
    if (action === 'REPLAN_LOCATION') {
      this.props.setSelectedHydrants(preplanData.hydrants ? preplanData.hydrants.map(hyd => hyd.id) : []);
    }
  }

  handleLocatorClick() {
    this.setLocation(1);
  }

  setLocation(kounter) {
    //Check Location Reordering
    let lKounter = kounter;
    if (lKounter < 1) {
      lKounter = 1;
    } else if (lKounter > this.props.structures.length) {
      lKounter = this.props.structures.length;
    }
    const selectLocation = this.props.structures[lKounter - 1];
    this.setState({
      isPrePlanningMode: false,
      showDrawingTools: false,
      showDrawingToolsOption: null,
      getDistacebyPolyline: '',
      distancePolyline: null,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      newMsg: false,
      locationKounter: lKounter
    }, () => {
      this.props.setSelectedHydrants(selectLocation || []);
    });
    return true;
  }

  handlePrevLocatorClick() {
    this.setLocation(this.state.locationKounter - 1);
  }

  handleNextLocatorClick() {
    this.setLocation(this.state.locationKounter + 1);
  }

  handleFilterCriteria(arr) {
    this.props.setSelectedStructureId(null);
    this.props.clearSelectedHydrants();
    this.props.setStructureFilters(arr);
  }

  toggleShowFilters() {
    this.setState({ showFilters: !this.state.showFilters});
  }

  renderContext() {
    if (!this.state.contextMenu) return null;
    if (this.state.isPrePlanningMode ||
        this.state.hydrantInMove ||
        this.state.buildingInMove) return null;
    const contextMenuProps = {
      contextMenu: this.state.contextMenu,
      role: this.props.user.role,
      handleContextMenuItemSelect: this.handleContextMenuItemSelect
    };
    return <ContextMenu {...contextMenuProps} />;
  }

  render() {
    const mapProps = {
      markersFromSearch: this.state.markersFromSearch,
      handleHydrantRightClick: this.handleHydrantRightClick,
      handleStructureClick: this.handleStructureClick,
      handleStructureRightClick: this.handleStructureRightClick,
      handleMapClick: this.handleMapClick,
      handleMapRightClick: this.handleMapRightClick,
      setMarkerProps: this.setHydrantProps,
      setMarkersFromSearch: this.setMarkersFromSearch,
      handlePolygonComplete: this.handlePolygonComplete,
      handlePolylineComplete: this.handlePolylineComplete,
      distancePolyline: this.state.distancePolyline,
      setRemovePolygons: (f) => { this.removePolygons = f; },
      showDrawingTools: this.state.showDrawingTools,
      showDrawingToolsOption: this.state.showDrawingToolsOption,
      prevPolylineData: this.state.prevPolylineData,
      isInActionMode: this.state.isPrePlanningMode || this.state.hydrantInMove || this.state.buildingInMove || this.state.action === 'REPLAN_STRUCTURE',
      action: this.state.action,
      actionLoading: this.state.actionLoading,
      handleContextMenuItemSelect: this.handleContextMenuItemSelect,
      onCancelAction: this.onCancelAction,
      handleHydrantMove: this.handleHydrantMove,
      hydrantInMove: this.state.hydrantInMove,
      buildingInMove: this.state.buildingInMove,
      onBuildingDragEnd: this.onBuildingDragEnd,
      handleBuildingPinMove: this.handleBuildingPinMove,
      setBuildingDragStartingPoint: this.setBuildingDragStartingPoint,
      toggleShowFilters: this.toggleShowFilters,
      onSubmitAction: this.onSubmitAction,
    };

    const canEditImage = this.props.user &&
      (this.props.user.role === 'ADMIN' || this.props.user.role === 'PLANNER') && 
      !this.props.selectedStructure?.readOnly;
    const showMap = !!(this.props.customer.config.lat && this.props.customer.config.lon);

    const imageProps = {
      onAnnotation: image => this.annotateImage.bind(this, image),
      canEditImage: canEditImage,
      changeCounter: this.state.changeCounter,
      locationLoading: this.state.loading,
      toggleModal: this.toggleModal,
      setModal: this.setModal
    };

    return (
      <FlexWithBackgroundTheme w="100%" height={'calc(100vh)'} overflow="hidden">
        {(showMap && !this.state.isAnnotationMode) ? (
          <Flex width="100%" bg="white" p="10px" gap="10px">
            <Box height="100%" width="100%" position="relative" borderRadius="8px" borderWidth="1px" borderColor="#EBEBEF">
              <Box position="absolute" top="72px" right="12px" zIndex="1">
                <Tooltip placement="right" label="Toggle distance mode">
                  <div>
                    <HStack boxShadow="md" background="#fff" paddingX="0.5rem" borderRadius="0.25rem">
                      {this.state.distancePolyline && <span>{this.state.distancePolyline.distance}</span>}
                      <Icon width={'2rem'} height={'2rem'} padding={'0.25rem'} as={FaRuler} />
                      <FormControl>
                        <Switch id="distanceMode" isChecked={this.state.showDrawingTools && this.state.showDrawingToolsOption === 'Polyline'} onChange={this.handleDistanceMode} />
                      </FormControl>
                    </HStack>
                  </div>
                </Tooltip>
              </Box>
              <Map {...mapProps} />
              <ImageStrip {...imageProps} />
              <MapFilters
                isOpen={this.state.showFilters}
                onClose={() => this.setState({ showFilters: false })}
                onFilterChange={this.handleFilterCriteria}
              />
            </Box>
            <Box width={'500px'} flexShrink={'0'} overflow={'auto'}>
              {/* {this.state.buildingInMove && (
                <Box p={4}>
                  <strong>To move:</strong> Drag the selected location to a new place.
                </Box>
              )}
              {this.state.isPrePlanningMode && this.state.isGettingPreplan && (
                <div className="preplanning-content">
                  <div className="processing">
                    <img src="/images/processing.gif" alt="" />
                  </div>
                </div>
              )}
              {this.state.isPrePlanningMode && !this.state.isGettingPreplan && !this.state.isPolygonDrawn && (
                <div className="preplanning-content">
                  <div>
                    <div>
                      <Box>
                        <strong>To continue:</strong> Trace the edges of the location on the map using the drawing tool.
                      </Box>
                      <Flex paddingTop="1rem" alignItems="center" justifyContent="center">
                        {
                          !this.state.reDrawPolygon && this.props.selectedStructure &&
                          <OutlineButton width="100%" onClick={this.handleRePlanStart}>Redraw</OutlineButton>
                        }
                      </Flex>
                    </div>
                  </div>
                </div>
              )} */}
              { this.state.showConnectPanel ? (
                <ConnectPropertyStructuresPanel
                  isOpen={this.state.showConnectPanel}
                  onClose={() => this.setState({ showConnectPanel: false })}
                  propertyId={this.state.propertyId}
                  originalPropertyStructures={this.state.originalPropertyStructures}
                />
              ) : (
                // <PrePlanFormContainer
                //   action={this.state.propertyDataForm.action}
                //   structureId={this.state.structureDataForm.structureId}
                //   propertyId={this.state.propertyDataForm.propertyId}
                //   prePlanGeoOutline={this.state.prePlanGeoOutline}
                //   prePlanLatLng={this.state.prePlanLatLng}
                //   handleEditLocationSuccess={this.handleEditLocationSuccess}
                //   handleAddLocationSuccess={this.handleAddLocationSuccess}
                // >
                  <TabsContainer
                    selectedStructure={this.props.selectedStructure}
                    selectStructureByMessage={this.selectStructureByMessage}
                    handleContextMenuItemSelect={this.handleContextMenuItemSelect}
                    handleConnectPropertyStructures={this.handleConnectPropertyStructures}
                    handleDisconnect={this.handleDisconnect}
                    handleRePlanStart={this.handleRePlanStart}
                    handleMoveStart={this.handleMoveStart}
                    handleEditLocationSuccess={this.handleEditLocationSuccess}
                  />
                //   </PrePlanFormContainer>
              )}
            </Box>
          </Flex>
        ) : !this.state.isAnnotationMode ? (
          <Center margin="0 auto">
            <Spinner
              thickness=".25rem"
              speed=".5s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>)
          : null}
        {this.state.isAnnotationMode ? (
          <AnnotatorContainer
            image={this.state.currentImage}
            onSave={async (json, svg) => { await this.saveAnnotation(json, svg); }}
            onCancel={() => this.cancelAnnotation()}
            json={this.state.annotationJson}
          />
        ) : null}
        {this.renderContext()}
        <Modal
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          modal={this.state.modal}
        />
      </FlexWithBackgroundTheme>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    customer: store.customer,
    user: store.user,
    smsList: store.customer.smsList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getLocation: (locationLink, showLoader, successCallback, errorCallback) =>
      customerAPI.getLocation(locationLink, showLoader, successCallback, errorCallback),
    addHydrant: (hydrant, successCallback, errorCallback, hydrantsLink) =>
      hydrantAPI.addHydrant(dispatch, hydrant, successCallback, errorCallback, hydrantsLink),
    editHydrant: (id, hydrant, successCallback, errorCallback, hydrantsLink) =>
      hydrantAPI.editHydrant(dispatch, id, hydrant, successCallback, errorCallback, hydrantsLink),
    hydrantSelected: (hydrantId) => dispatch(hydrantSelected(hydrantId)),
    setSelectedHydrants: (hydrantIds) => dispatch(setSelectedHydrants(hydrantIds)),
    clearSelectedHydrants: () => dispatch(clearSelectedHydrants()),
    setDefaultState: () => dispatch(setCustomerDefaultState()),
    getPrePlan: (locationCoords, storey, storeyBelow, preplanLink) => structureAPI.getPrePlan(locationCoords, storey, storeyBelow, preplanLink),
    setAnnotation: (imageId, annotationJson, annotationSVG) => dispatch(setAnnotation({ imageId, annotationJson, annotationSVG })),
    getUser: () => UserAPI.getUser(dispatch),
    setNewMessage: (successCallback, errorCallback, connectCallback, closedCallback, customerId) =>
      customerAPI.eventSourceMessage(successCallback, errorCallback, connectCallback, closedCallback, dispatch, customerId),
    setSelectedStructureId: (locationId) => dispatch(setSelectedStructureId(locationId)),
    setStructureFilters: (filters) => dispatch(setStructureFilters(filters)),
    deleteStructure: (structureId) => dispatch(deleteStructure(structureId)),
    deleteHydrant: (hydrant) => dispatch(deleteHydrant(hydrant)),
    setSelectedStructures: (structures) => dispatch(setSelectedStructures(structures)),
    editStructure: (structureId, structurePatch) => dispatch(editStructure({ structureId, structurePatch })),
    setStructureToEdit: (value) => dispatch(setStructureToEdit(value)),
  };
}


const DataWrapper = (props) => {
  const dispatch = useDispatch();
  const { lastFetch: lastHydFetch, lastPartnerFetch: lastPartnerHydFetch } = useSelector(store => store.hydrants);
  const { lastFetch: lastPropertiesFetch, lastPartnerFetch: lastPartnerPropertiesFetch } = useSelector(store => store.properties);
  const { lastFetch: lastStructuresFetch, lastPartnerFetch: lastPartnerStructuresFetch } = useSelector(store => store.structures);
  const { lastFetch: lastOccupantsFetch, lastPartnerFetch: lastPartnerOccupantsFetch } = useSelector(store => store.occupants);
  const { lastFetch: lastImagesFetch } = useSelector(store => store.images);
  const { selectedStructureId, selectedStructures, structureToEdit } = useSelector(store => store.structures);
  const { partners, links } = useSelector(v => v.customer);
  const selectedStructure = useSelector(state => selectStructureById(state, selectedStructureId));
  const allStructures = useSelector(selectAllStructures);
  const allProperties = useSelector(selectAllProperties);
  const allHydrants = useSelector(selectAllHydrants);

  useEffect(() => {
    if (!lastHydFetch) {
      dispatch(fetchAllHydrants());
    }
  }, [lastHydFetch, dispatch]);

  useEffect(() => {
    if (!lastPropertiesFetch) {
      dispatch(getAllProperties());
    }
  }, [lastPropertiesFetch, dispatch]);

  useEffect(() => {
    if (!lastStructuresFetch) {
      dispatch(getAllStructures());
    }
  }, [lastStructuresFetch, dispatch]);

  useEffect(() => {
    if (!lastOccupantsFetch) {
      dispatch(getAllOccupants());
    }
  }, [lastOccupantsFetch, dispatch]);

  useEffect(() => {
    if (!lastImagesFetch) {
      dispatch(getAllImages());
    }
  }, [lastImagesFetch, dispatch]);

  useEffect(() => {
    if (!lastPartnerPropertiesFetch) {
      const fetches = [];
      partners.forEach(partnerId => {
        fetches.push(dispatch(fetchPartnerProperties(partnerId)));
      });
      Promise.all(fetches).then(() => {
        console.info('Partner properties fetched');
      });
    }
  }, [partners, dispatch, lastPartnerPropertiesFetch]);

  useEffect(() => {
    if (!lastPartnerStructuresFetch) {
      const fetches = [];
      partners.forEach(partnerId => {
        fetches.push(dispatch(fetchPartnerStructures(partnerId)));
      });
      Promise.all(fetches).then(() => {
        console.info('Partner structures fetched');
      });
    }
  }, [partners, dispatch, lastPartnerStructuresFetch]);

  useEffect(() => {
    if (!lastPartnerOccupantsFetch) {
      const fetches = [];
      partners.forEach(partnerId => {
        fetches.push(dispatch(fetchPartnerOccupants(partnerId)));
      });
      Promise.all(fetches).then(() => {
        console.info('Partner occupants fetched');
      });
    }
  }, [partners, dispatch, lastPartnerOccupantsFetch]);

  useEffect(() => {
    if (!lastPartnerHydFetch) {
      const hydsFetch = [];
      partners.forEach(partnerId => {
        hydsFetch.push(dispatch(fetchPartnerHydrants(partnerId)));
      });
      Promise.all(hydsFetch).then(() => {
        console.info('Partner hydrants fetched');
      });
    }
  }, [partners, dispatch, lastPartnerHydFetch]);

  return (
    <MapContainer
      {...props}
      selectedStructure={selectedStructure}
      properties={allProperties}
      structures={allStructures}
      customerHydrants={allHydrants}
      customerLinks={links}
      selectedStructures={selectedStructures}
      structureToEdit={structureToEdit}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DataWrapper));
