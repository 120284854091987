import { Box, Divider, Flex, Heading, Icon, Stack, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as customerAPI from "../../api/CustomerAPI";
import FormComponent from "../../components/form/FormComponent";
import { generateDefaultRedirectUri } from "../../helpers/Oidc";
import { LinkButton, SubmitButton } from "../form/Button";
import EditIcon from "../FigmaExport/EditIcon";

/**
 * @typedef {Object} CustomerOidc
 * @property {string} [issuer]
 * @property {string} [clientId]
 * @property {string} [redirectUri]
 * @property {string} [discoveryEndpoint]
 * @property {string} [authorizationEndpoint]
 * @property {string} [tokenEndpoint]
 * @property {string} [jwksUri]
 * @property {boolean} [allowAutoRegistration]
 */

export default function AccountOidcCard() {
  const customer = useSelector((state) => state.customer);
  /** @type {CustomerOidc} */
  const customerOidc = customer?.oidc ?? {};
  const isCustomerOidc = !!customer?.oidc;

  const [isEditing, setIsEditing] = useState(false);
  const labelStyle = {
    fontSize: "xs",
    textTransform: "uppercase",
    lineHeight: "1.25rem",
    color: "gray.700",
  };

  return (
    <Box minWidth="32rem" bg={useColorModeValue("white", "gray.900")} p="1rem" >
      <Flex align="center" justify="space-between">
        <Heading as="h4" size="md">
          OpenID Connect Integration
        </Heading>
        <LinkButton gap="2px" onClick={() => setIsEditing(!isEditing)}><Icon as={EditIcon} />{isEditing ? "Cancel" : "Edit"}</LinkButton>
      </Flex>
      <Divider my="0.5rem" />
      {isEditing ? (
        <AccountOidcForm onSuccess={() => setIsEditing(false)} />
      ) : isCustomerOidc ? (
        <Box>
          <Stack spacing="0.5rem">
            <Stack spacing="0">
              <Text {...labelStyle}>Client ID</Text>
              <Text fontWeight="600">{customerOidc.clientId ?? ""}</Text>
            </Stack>
            <Stack spacing="0">
              <Text {...labelStyle}>Issuer</Text>
              <Text fontWeight="600">{customerOidc.issuer ?? ""}</Text>
            </Stack>
            <Stack spacing="0">
              <Text {...labelStyle}>Discovery Endpoint</Text>
              {customerOidc.discoveryEndpoint ? (
                <Text fontWeight="600">{customerOidc.discoveryEndpoint}</Text>
              ) : (
                <Text fontWeight="600">
                  Defaults to <code>{`${customerOidc.issuer}/.well-known/openid-configuration`}</code>
                </Text>
              )}
            </Stack>
            <Stack spacing="0">
              <Text {...labelStyle}>Redirect URI</Text>
              {customerOidc.redirectUri ? (
                <Text fontWeight="600">{customerOidc.redirectUri}</Text>
              ) : (
                <Text fontWeight="600">
                  Defaults to <code>{generateDefaultRedirectUri()}</code>
                </Text>
              )}
            </Stack>
            <Stack spacing="0">
              <Text {...labelStyle}>Automatic User Registration</Text>
              <Text fontWeight="600">{customerOidc.allowAutoRegistration ? "Yes" : "No"}</Text>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box>
          <Text>Not configured.</Text>
        </Box>
      )}
    </Box>
  );
}

function AccountOidcForm(props) {
  const customer = useSelector((state) => state.customer);
  /** @type {CustomerOidc} */
  const customerOidc = customer?.oidc ?? {};

  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const data = [];
    if (!values.clientId && !values.issuer && !values.discoveryEndpoint) {
      data.push({ op: "remove", path: `/oidc` });
    } else if (!customer.oidc) {
      data.push({ op: "add", path: `/oidc`, value: values });
    } else {
      const issuerChanged = customerOidc.issuer && customerOidc.issuer !== values.issuer;
      const discoveryEndpointChanged = customerOidc.discoveryEndpoint && customerOidc.discoveryEndpoint !== values.discoveryEndpoint;
      if (issuerChanged || discoveryEndpointChanged) {
        // If the issuer or discovery endpoint changed, then completely replace the oidc object to remove any cached values.
        data.push({ op: "replace", path: `/oidc`, value: values });
      } else {
        const opVal = (path, value) => {
          if (value) {
            data.push({ op: "replace", path: `/oidc/${path}`, value });
          }
        };
        opVal("clientId", values.clientId);
        opVal("issuer", values.issuer);
        opVal("discoveryEndpoint", values.discoveryEndpoint);
        opVal("redirectUri", values.redirectUri);
        opVal("allowAutoRegistration", values.allowAutoRegistration);
      }
    }

    return new Promise((resolve, reject) =>
      customerAPI.editCustomer(
        data,
        () => {
          toast({
            title: "Success",
            position: "top",
            description: "Account information updated.",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          resolve();
          props.onSuccess();
        },
        () => {
          toast({
            title: "Error",
            position: "top",
            description: "Failed to update account information. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          reject();
        },
        dispatch
      )
    );
  };

  const formFields = [
    {
      id: "clientId",
      name: "clientId",
      type: "text",
      label: "Client ID",
    },
    {
      id: "issuer",
      name: "issuer",
      type: "text",
      label: "Issuer",
    },
    {
      id: "discoveryEndpoint",
      name: "discoveryEndpoint",
      type: "text",
      label: "Discovery Endpoint (Optional)",
      placeholder: "{issuer}/.well-known/openid-configuration",
    },
    {
      id: "redirectUri",
      name: "redirectUri",
      type: "text",
      label: "Redirect URI (Optional)",
      placeholder: generateDefaultRedirectUri(),
    },
    {
      id: "allowAutoRegistration",
      name: "allowAutoRegistration",
      type: "checkbox",
      label: "Automatic User Registration",
    },
  ];

  const formData = {
    clientId: customerOidc.clientId,
    issuer: customerOidc.issuer,
    discoveryEndpoint: customerOidc.discoveryEndpoint,
    redirectUri: customerOidc.redirectUri,
    allowAutoRegistration: customerOidc.allowAutoRegistration,
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormComponent formFields={formFields} data={formData} />
        <SubmitButton isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
          Save
        </SubmitButton>
      </form>
    </FormProvider>
  );
}
