import React, { useState, useEffect } from 'react';
import {
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import SearchIcon from '../FigmaExport/SearchIcon';


export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 200,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, onChange, debounce]);

  return (
    <InputGroup>
      <InputLeftElement pointerEvents={'none'} children={<SearchIcon color="gray.500" />} />
      <Input {...props} value={value} onChange={e => setValue(e.target.value)} />
    </InputGroup>
  );
}
