import React from 'react';
import * as util from '../../helpers/Util';
import { Text, Stack, HStack, Box, Flex, Divider, Accordion, AccordionItem, AccordionButton, AccordionPanel, useAccordionItemState } from '@chakra-ui/react';
import ArrowUpIcon from '../FigmaExport/ArrowUpIcon';
import ArrowDownIcon from '../FigmaExport/ArrowDownIcon';

const IconLegend = (props) => {
  const IconLegendRow = (props) => (
    <HStack alignItems="center">
      <Flex width="1.5rem" justifyContent={'center'}>
        <img width="25px" src={util.getHydrantLegendIcon(props.legend.pinColor)} alt="" />
      </Flex>
      <Text fontSize="sm" fontWeight="600">{props.legend.label}</Text>
    </HStack>
  );

  return (
    <Box borderWidth="1px"
    borderRadius="0.5rem">
      <Accordion allowMultiple>
        <AccordionItem border={0}>
          <LegendHeader pinLegend={props.pinLegend} />
          <AccordionPanel p="0.5rem">
            <Stack spacing="0.25rem">
              {props.pinLegend.rangePinColors.map((iconLegend, index) => (<React.Fragment key={`icon-frag-${index}`}>
                <IconLegendRow key={`${index}-icon-legend`} legend={iconLegend} />
                <Divider key={`${index}-div`} />
              </React.Fragment>
              ))}
              <IconLegendRow legend={props.pinLegend.unknownPinColor} />
              <Divider />
              <IconLegendRow legend={{ pinColor: 'DRY', label: 'Dry Hydrant' }} />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

    </Box>
  );
};

const LegendHeader = ({
  pinLegend,
  ...props
}) => {
  const { isOpen } = useAccordionItemState();

  return (
    <h2>
      <AccordionButton justifyContent="space-between">
        <HStack>
          <img width="25px" src={util.getHydrantLegendIcon('BLUE')} alt="" />
          <Text as='h2' fontSize="lg" fontWeight="600" flex="1" size="md">Hydrant Legends</Text>
        </HStack>
        { isOpen ? <ArrowUpIcon /> : <ArrowDownIcon /> }
      </AccordionButton>
    </h2>
  );
};
export default IconLegend;
