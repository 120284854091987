// import { connectRouter } from 'connected-react-router';
import { combineReducers } from '@reduxjs/toolkit';
import session from './SessionReducer';


const rootReducer = (history) => combineReducers({
    // router: connectRouter(history),
    session,
});

export default rootReducer;
