import * as AJAXUtil from './AJAXUtil';
import config from '../../config';


export function getAllImages() {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/image`,
  }); // Image
};

export function uploadImage(image) {
  const formData = new FormData();
  formData.append('file', image);

  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${config.API_BASE_URL}/api/v2/image`,
    data: formData
  }); // Image
};

export function getImageById(imageId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/image/${imageId}`,
  }); // Image
};

export function deleteImage(imageId) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${config.API_BASE_URL}/api/v2/image/${imageId}`,
  }); // Image
}

export function getAnnotation(imageId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/image/${imageId}/annotation`
  });
}

export function setAnnotation(imageId, annotationJson, annotationSVG) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${config.API_BASE_URL}/api/v2/image/${imageId}/annotation`,
    data: { annotationJson, annotationSVG },
  });
}

export function getAnnotationSVG(imageId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/image/${imageId}/annotationSVG`
  });
}

export function getTags(imageId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/image/${imageId}/tags`
  });
}

export function setTags(imageId, tagJson) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${config.API_BASE_URL}/api/v2/image/${imageId}/tags`,
    data: tagJson
  });
}
