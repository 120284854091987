import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Divider, HStack, Stack, Text, useToast, List, ListItem, Spacer, Flex } from '@chakra-ui/react';
import { OutlineButton, SubmitButton } from './Button';
import { useDispatch } from 'react-redux';
import { Reorder } from "framer-motion";
import { editStructure } from '../../features/structures/structuresSlice';
import ListIcon from '../FigmaExport/ListIcon';


export const ReOrderFloorsForm = ({ floors, selectedStructure, closeModal }) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();
  const [items, setItems] = useState(floors.slice(1).map(f => f.name));

  const variants = {
    notDragging: {
      zIndex: 0,
      boxShadow: "none",
      // background: "var(--chakra-colors-gray-100)",
    },
    dragging: {
      zIndex: 9,
      boxShadow: "var(--chakra-shadows-lg)",
      background: "var(--chakra-colors-gray-100)",
    },
  };

  const onSubmit = async () => {
    const structurePatch = [
      {
        op: 'replace',
        path: '/floors',
        value: items.map(item => ({ name: item })),
      }
    ];
    const editStructureResult = await dispatch(editStructure({ structureId: selectedStructure.id, structurePatch }));
    if (editStructure.fulfilled.match(editStructureResult)) {
      onSuccess('Success', 'Floors updated');
    } else {
      onError('Failed to update floors');
    }
  };

  const onError = (...p) => onComplete('error', ...p);

  const onSuccess = (...p) => {
    closeModal();
    onComplete('success', ...p);
  };

  const onComplete = (type, title, body) => {
    toast({
      title: title,
      position: 'top',
      description: body,
      status: type,
      duration: 2500,
      isClosable: true
    });
  };

  const onCancel = () => {
    methods.reset();
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing="1rem">
          <Text>Drag and drop to change the order of the floors.</Text>
          <List
            as={Reorder.Group}
            spacing={2}
            axis="y"
            values={items}
            onReorder={setItems}
          >
            {items.map((item, index) => (
              <ListItem
                key={item}
                as={Reorder.Item}
                value={item}
                p={2}
                borderWidth='1px'
                borderRadius='8px'
                rounded="xl"
                dragTransition={{
                  bounceStiffness: 600,
                }}
                variants={variants}
                initial="notDragging"
                whileDrag="dragging"
                animate="notDragging"
                position="relative"
                cursor="move"
              >
                <Flex>
                  <Text fontWeight={600}>{item} ({floors.find(f => f.name === item).occupants.length})</Text>
                  <Spacer/>
                  <ListIcon width="24" height="24" />
                </Flex>
              </ListItem>
            ))}
          </List>
          <Divider />
          <HStack justifyContent="end" spacing="1rem">
            <OutlineButton onClick={onCancel} width="8rem">Cancel</OutlineButton>
            <SubmitButton isLoading={methods.formState.isSubmitting} width="8rem">Update</SubmitButton>
          </HStack>
        </Stack>
      </form>
    </FormProvider>
  );
};