import * as AJAXUtil from './AJAXUtil';
import config from '../../config';


export function getAllOccupants() {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/occupant`,
  }); // Occupant
};

export function addOccupant(occupant) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${config.API_BASE_URL}/api/v2/occupant`,
    data: occupant,
  }); // Occupant
};

export function getOccupantById(occupantId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/occupant/${occupantId}`,
  }); // Occupant
};

export function deleteOccupant(occupantId) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${config.API_BASE_URL}/api/v2/occupant/${occupantId}`,
  }); // Occupant
}

export function editOccupant(occupantId, occupantPatch) {
  return AJAXUtil.AJAX({
    method: 'PATCH',
    url: `${config.API_BASE_URL}/api/v2/occupant/${occupantId}`,
    data: occupantPatch,
  }); // Occupant
}

export function getImages(occupantId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/occupant/${occupantId}/images`,
  }); // [Image]
};

export function addImageToOccupant(occupantId, imageId) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${config.API_BASE_URL}/api/v2/occupant/${occupantId}/image/${imageId}`,
  }); // Occupant
};

export function removeImageFromOccupant(occupantId, imageId) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${config.API_BASE_URL}/api/v2/occupant/${occupantId}/image/${imageId}`,
  }); // Occupant
};

export const fetchPartnerOccupants = (partnerId) => {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/occupant/partner/${partnerId}`
  });
};
