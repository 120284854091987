import React, { useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import FormComponent from './FormComponent';
import { Divider, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { OutlineButton, SubmitButton, SubmitButtonDanger } from './Button';
import { useDispatch } from 'react-redux';
import { addProperty, deleteProperty, editProperty } from '../../features/properties/propertiesSlice';
import { editStructure } from '../../features/structures/structuresSlice';


const PROPERTY_FORM_FIELDS = [
  {
    label: 'Property Label',
    id: 'name',
    name: 'name',
    type: 'text',
    placeholder: '',
    required: true,
  },
  {
    label: 'Address 1',
    id: 'address1',
    name: 'address1',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Address 2',
    id: 'address2',
    name: 'address2',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Zip',
    id: 'zip',
    name: 'zip',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'District',
    id: 'district',
    name: 'district',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Station',
    id: 'station',
    name: 'station',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Owner Contact',
    id: 'ownerContact',
    name: 'ownerContact',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Owner Phone',
    id: 'ownerPhone',
    name: 'ownerPhone',
    type: 'text',
    placeholder: ''
  },
];


export const PropertyForm = ({
  property,
  structure,
  action,
  closeModal
}) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const dispatchAddProperty = async(data) => {
    const addPropertyResult = await dispatch(addProperty(data));
    if (addProperty.fulfilled.match(addPropertyResult)) {
      onSuccess('Success', 'Property added successfully');

      const structurePatch = [
        {
          op: 'replace',
          path: '/propertyId',
          value: addPropertyResult.payload.id,
        }
      ];
      const editStructureResult = await dispatch(editStructure({ structureId: structure.id, structurePatch }));
      if (editStructure.fulfilled.match(editStructureResult)) {
        onSuccess('Success', 'New property connected to location');
      } else {
        onError('Failed to connect new property to location');
      }
  
    } else {
      onError('Failed to add property', 'Please check property fields and try again.');
    }
  };

  const dispatchEditProperty = async(id, propertyPatch) => {
    const result = await dispatch(editProperty({ propertyId: id, propertyPatch: propertyPatch }));
    if (editProperty.fulfilled.match(result)) {
      onSuccess('Success', 'Property updated');
    } else {
      onError('Failed to update property', 'Please check property fields and try again.');
    }
  };

  const dispatchDeleteProperty = async(id) => {
    const result = await dispatch(deleteProperty(id));
    if (deleteProperty.fulfilled.match(result)) {
      onSuccess('Success', 'Property deleted');
    } else {
      onError('Cannot Delete Property');
    }
  };

  const onSubmit = (data) => {
    if (action === 'ADD_PROPERTY') {
      const propertyAdd = {};
      data.name && (propertyAdd.name = data.name);
      if (
        data.address1 ||
        data.address2 ||
        data.city ||
        data.state ||
        data.zip
      ) {
        propertyAdd.address = {};
        data.address1 && (propertyAdd.address.address1 = data.address1);
        data.address2 && (propertyAdd.address.address2 = data.address2);
        data.city && (propertyAdd.address.city = data.city);
        data.state && (propertyAdd.address.state = data.state);
        data.zip && (propertyAdd.address.zip = data.zip);
      }
      data.district && (propertyAdd.district = data.district);
      data.station && (propertyAdd.station = data.station);
      data.ownerContact && (propertyAdd.ownerContact = data.ownerContact);
      data.ownerPhone && (propertyAdd.ownerPhone = data.ownerPhone);

      return dispatchAddProperty(propertyAdd);
    } else if (action === 'EDIT_PROPERTY') {
      const propertyPatch = [];
      (data.name || property.name) && propertyPatch.push({
        op: 'replace',
        path: '/name',
        value: data.name
      });
      (data.address1 || property.address?.address1) && propertyPatch.push({
        op: 'replace',
        path: '/address/address1',
        value: data.address1
      });
      (data.address2 || property.address?.address2) && propertyPatch.push({
        op: 'replace',
        path: '/address/address2',
        value: data.address2
      });
      (data.city || property.address?.city) && propertyPatch.push({
        op: 'replace',
        path: '/address/city',
        value: data.city
      });
      (data.state || property.address?.state) && propertyPatch.push({
        op: 'replace',
        path: '/address/state',
        value: data.state
      });
      (data.zip || property.address?.zip) && propertyPatch.push({
        op: 'replace',
        path: '/address/zip',
        value: data.zip
      });
      (data.district || property.district) && propertyPatch.push({
        op: 'replace',
        path: '/district',
        value: data.district
      });
      (data.station || property.station) && propertyPatch.push({
        op: 'replace',
        path: '/station',
        value: data.station
      });
      (data.ownerContact || property.ownerContact) && propertyPatch.push({
        op: 'replace',
        path: '/ownerContact',
        value: data.ownerContact
      });
      (data.ownerPhone || property.ownerPhone) && propertyPatch.push({
        op: 'replace',
        path: '/ownerPhone',
        value: data.ownerPhone
      });
      return dispatchEditProperty(property.id, propertyPatch);
    } else if (action === 'DELETE_PROPERTY') {
      return dispatchDeleteProperty(property.id);
    }
    return Promise.resolve();
  };

  const onError = (...p) => onComplete('error', ...p);

  const onSuccess = (...p) => {
    closeModal();
    onComplete('success', ...p);
  };

  const onComplete = (type, title, body) => {
    toast({
      title: title,
      position: 'top',
      description: body,
      status: type,
      duration: 2500,
      isClosable: true
    });
  };

  const onCancel = () => {
    methods.reset();
    closeModal();
  };

  const editData = useMemo(() => {
    if (!property) return {};
    const data = {
      name: property.name,
      district: property.district,
      station: property.station,
      ownerContact: property.ownerContact,
      ownerPhone: property.ownerPhone,
    };
    if (property.address) {
      data.address1 = property.address.address1;
      data.address2 = property.address.address2;
      data.city = property.address.city;
      data.state = property.address.state;
      data.zip = property.address.zip;
    }
    return data;  
  }, [property]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {(action === 'DELETE_PROPERTY') ? (
          <DeleteForm onCancel={onCancel} />
        ) : (
          <EditForm data={editData} onCancel={onCancel} />
        )}
      </form>
    </FormProvider>
  );
};

const DeleteForm = (props) => {
  const methods = useFormContext();

  return (
    <Stack spacing="1rem">
      <Text>Are you sure you want to delete this property?</Text>
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={props.onCancel} width="8rem">No</OutlineButton>
        <SubmitButtonDanger isLoading={methods.formState.isSubmitting} width="8rem">Delete</SubmitButtonDanger>
      </HStack>
    </Stack>
  );
};

const EditForm = ({
  onCancel,
  data
}) => {
  const methods = useFormContext();

  return (
    <Stack spacing="1rem">
      <FormComponent formFields={PROPERTY_FORM_FIELDS} data={data} />
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={onCancel} width="8rem">Cancel</OutlineButton>
        <SubmitButton isLoading={methods.formState.isSubmitting} width="8rem">Save</SubmitButton>
      </HStack>
    </Stack>
  );

};
