import * as AJAXUtil from './AJAXUtil';
import config from '../../config';


export function getAllProperties() {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/property`,
  }); // Property
};

export function addProperty(property) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${config.API_BASE_URL}/api/v2/property`,
    data: property, // Property
  }); // Property
};

export function getPropertyById(propertyId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/property/${propertyId}`,
  }); // Property
};

export function deleteProperty(propertyId) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${config.API_BASE_URL}/api/v2/property/${propertyId}`,
  }); // Property
}

export function editProperty(propertyId, propertyPatch) {
  return AJAXUtil.AJAX({
    method: 'PATCH',
    url: `${config.API_BASE_URL}/api/v2/property/${propertyId}`,
    data: propertyPatch,
  }); // Property
}

export function getImages(propertyId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/property/${propertyId}/images`,
  }); // [Image]
};

export function addImageToProperty(propertyId, imageId) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${config.API_BASE_URL}/api/v2/property/${propertyId}/image/${imageId}`,
  }); // Property
};

export function removeImageFromProperty(propertyId, imageId) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${config.API_BASE_URL}/api/v2/property/${propertyId}/image/${imageId}`,
  }); // Property
};

export const fetchPartnerProperties = (partnerId) => {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/v2/property/partner/${partnerId}`
  });
};
