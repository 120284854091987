import React, { useState } from 'react';
import { IconButton, useToast } from '@chakra-ui/react';
import Modal from '../common/ModalComponent';
import { SubmitButton } from '../form/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { EditUnit } from '../../models/Unit';
import { zodResolver } from '@hookform/resolvers/zod';
import FormComponent from '../../components/form/FormComponent';
import { useDispatch } from 'react-redux';
import { updateUnit } from '../../features/departmentUnits/departmentUnitsSlice';
import { formFields, checkDuplicateUnits } from './utils';
import EditIcon from '../FigmaExport/EditIcon';


export const EditUnitModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(EditUnit)
  });
  const dispatch = useDispatch();
  const toast = useToast();

  const initialData = {
    unit: props.unit.unit,
    desc: props.unit.desc
  };

  const onSubmit = async (data) => {
    if (checkDuplicateUnits(props.units, data.unit)) {
      toast({
        title: 'Error',
        position: 'top',
        description: 'unit must be unique for PSAP.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
      return;
    }

    const patchData = [];
    const opVal = (path, value) => (value !== undefined && value !== null) && patchData.push({
      op: 'replace',
      path: `/${path}`,
      value
    });

    opVal('unit', data.unit);
    opVal('desc', data.desc);

    const resultAction = await dispatch(updateUnit({ unitId: props.unit.id, data: patchData }));

    if (updateUnit.fulfilled.match(resultAction)) {
      setModalOpen(false);
      toast({
        title: 'Success',
        position: 'top',
        description: 'Unit updated successfully.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: 'There was an error updating the unit. Please try again.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
    }
  };

  return (
    <React.Fragment>
      <IconButton
        isDisabled={props.isDisabled}
        size="sm"
        aria-label="Edit unit"
        icon={<EditIcon />}
        bg="none"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        showModal={modalOpen}
        toggleModal={() => setModalOpen(false)}
        modal={{
          heading: 'Update Unit',
          body: (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormComponent formFields={formFields} data={initialData} />
                <SubmitButton mb="1rem" isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
                  Submit
                </SubmitButton>
              </form>
            </FormProvider>
          )
        }}
      />
    </React.Fragment>
  );
};
