/* global google */
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import Capture from 'html2canvas';

import { shouldNotUpdate } from '../../helpers/Memo';
import { Flex, Tooltip, Icon, Box, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_CENTER,
  midPoint,
  offsetCenter,
} from '../../helpers/GoogleMaps';
import CustomMap from './CustomMap';
import {
  selectAllHydrants,
  setSelectedHydrants,
} from '../../features/hydrants/hydrantsSlice';
import {getUIConfig, setUserConfig} from '../../features/user/userSlice';
import {
  selectAllStructures,
  selectStructureById,
  setSelectedStructureId,
} from '../../features/structures/structuresSlice';
import ErrorBoundary from '../../containers/ErrorBoundary';
import { FaCrosshairs } from '@react-icons/all-files/fa/FaCrosshairs';
import { AddItem } from './AddItemComponent';
import FilterIcon from '../FigmaExport/FilterIcon';
import AddressSearchBox from './SearchBoxComponent';
import { FaCamera } from '@react-icons/all-files/fa/FaCamera';
import { OutlineButton } from '../form/Button';


const MapComponent = (props) => {
  const {
    address1: customerStreet,
    city: customerCity,
    state: customerState,
  } = useSelector((state) => state.customer);
  const user = useSelector(state => state.user);
  const userConfig = getUIConfig(user);
  const { customerId } = useSelector((state) => state.customer);
  const { selectedHydrantIds, /*loading: hydrantsLoading*/ } = useSelector(
    (state) => state.hydrants
  );
  const { selectedStructureId, selectedStructures, structureToEdit } = useSelector(state => state.structures);
  const { hydrantIdToLocate } = useSelector(state => state.hydrants);
  const selectedStructure = useSelector(state => selectStructureById(state, selectedStructureId));
  const hydrants = useSelector(selectAllHydrants);
  const allStructures = useSelector(selectAllStructures);
  const filteredStructureIds = useSelector(
    (state) => state.structures.filteredStructureIds
  );
  const dispatch = useDispatch();
  const [visibleLocations, setVisibleLocations] = useState([]);
  const [visibleHydrants, setVisibleHydrants] = useState([]);
  const [map, setMap] = useState();

  const [newHydrant, setNewHydrant] = useState(false);

  const geocoder = new google.maps.Geocoder();

  const [config, setConfig] = useState({
    ...userConfig,
    bounds: null,
  });

  useEffect(() => {
    // If config center is not set, we find customer address and set it as center
    if (!config.center) {
      setCustomerCenter();
    }
  }, [userConfig]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [userConfig, config.center]); // eslint-disable-line react-hooks/exhaustive-deps

  // Create map animation side-effect after render is complete
  useEffect(() => {
    if (map) {
      // @todo remove setTimeout. We ran into race condiiton of map and map.getProjection being available
      setTimeout(() => {
        if (selectedStructure && map.getProjection()) {
          const newCenter =
            selectedStructure.latLon ?
              new google.maps.LatLng(selectedStructure.latLon.latitude, selectedStructure.latLon.longitude)
            :
              midPoint(
                selectedStructure.geoOutline.map((coor) => ({
                  lat: coor.latitude,
                  lng: coor.longitude,
                }))
              );
          if (map && !isEqual(config.center, newCenter)) {
            const newPoint = offsetCenter(map, newCenter, 0, 150);
            setTimeout(() => map.panTo(newPoint), 50);
          }
        }
      }, 250);
    }
  }, [selectedStructure, map]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [selectedStructure, map, config.center]);

  useEffect(() => {
    mapVisibleHydrants();
    mapVisibleLocations();
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    map,
    config,
    selectedHydrantIds,
    hydrants,
    allStructures,
    filteredStructureIds,
    props.hydrantInMove,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.markersFromSearch && props.markersFromSearch.length > 0 && map) {
      map.panTo(props.markersFromSearch[0].position);

      // Wait for expensive operation until panTo is complete
      // if there is a search marker, which is now the map center, let's see if there's a location polygon
      // using first marker as this is what gets centered on
      setTimeout(() => {
        const centerMarker = props.markersFromSearch[0];
        for (let i = 0; i < allStructures.length; i++) {
          const structure = allStructures[i];
          const locPoly = new google.maps.Polygon({
            paths: structure.geoOutline.map((p) => {
              return { lat: p.latitude, lng: p.longitude };
            }),
          });
          const containsMarker = google.maps.geometry.poly.containsLocation(
            centerMarker.position,
            locPoly
          );

          if (containsMarker) {
            dispatch(setSelectedStructureId(structure.id));
            dispatch(setSelectedHydrants(structure.hydrants ? structure.hydrants : []));
            break;
          }
        }
      }, 100);
    }
  }, [props.markersFromSearch]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [dispatch, allStructures, map, props.markersFromSearch]);

  const setCustomerCenter = () => {
    const customerAddress = `${customerStreet} ${customerCity}, ${customerState}`;
    geocoder.geocode({ address: customerAddress }, (results, status) => {
      if (status === 'OK') {
        const customerLocation = results[0].geometry.location;
        setConfig({
          ...config,
          center: {
            lat: customerLocation.lat(),
            lng: customerLocation.lng(),
          },
        });
      } else {
        setConfig({
          ...config,
          center: DEFAULT_CENTER,
        });
      }
    });
  };

  const mapVisibleHydrants = () => {
    if (config.bounds) {
      const visHyds = hydrants.filter((h) =>
        config.bounds.contains(h.position)
      );
      setVisibleHydrants(
        visHyds.map((h) =>
          selectedHydrantIds.includes(h.id) ? { ...h, isSelected: true } : h
        )
      );
    }
  };

  const mapVisibleLocations = () => {
    if (config.bounds) {
      const structures = allStructures
        .filter((structure) =>
          structure.latLon ?
            config.bounds.contains({ lat: structure.latLon.latitude, lng: structure.latLon.longitude })
          :
            structure.geoOutline.some((coor) =>
              config.bounds.contains({ lat: coor.latitude, lng: coor.longitude })
            )
        )
        .map((structure) => ({
          ...structure,
          isMine: structure.customerId === customerId,
          isSelected: structure.id === selectedStructureId, // TODO use this for selectedStructures filtering
        }));
      if (filteredStructureIds.length > 0) {
        setVisibleLocations(
          structures.filter((structure) => filteredStructureIds.includes(structure.id))
        );
      } else {
        setVisibleLocations(structures);
      }
    }
  };

  function getClickLocation(latLng) {
    if (map !== undefined) {
      const projection = map.getProjection();
      const bounds = map.getBounds();
      const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
      const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
      const scale = Math.pow(2, map.getZoom());
      const worldPoint = projection.fromLatLngToPoint(latLng);
      return {
        x: Math.floor((worldPoint.x - bottomLeft.x) * scale) + 40,
        y: Math.floor((worldPoint.y - topRight.y) * scale) + 20,
      };
    }
    return {
      x: 0,
      y: 0,
    };
  }

  function handleMapMounted(map) {
    setMap(map);
    setConfig({ ...config, bounds: map.getBounds() });
  }

  function handleZoomChanged() {}

  const handleOnIdle = (e) => {
    if (map !== undefined) {
      if (map.getCenter() !== undefined) {
        const center = map.getCenter();
        setConfig({
          ...config,
          bounds: map.getBounds(),
          center: {
            lat: center.lat(),
            lng: center.lng(),
          },
          zoom: map.getZoom(),
        });
        dispatch(
          setUserConfig({
            center: {
              lat: center.lat(),
              lng: center.lng(),
            },
            zoom: map.getZoom(),
          })
        );
      }
    }
  };

  function onMapTypeIdChanged() {
    if (map !== undefined && map.getMapTypeId() !== config.mapTypeId) {
      setConfig({ ...config, mapTypeId: map.getMapTypeId() });
      dispatch(
        setUserConfig({
          mapTypeId: map.getMapTypeId(),
        })
      );
    }
  }

  function handleMarkerEvent(targetMarker, markerProps, handleHydrantClick) {
    props.setMarkerProps(targetMarker, markerProps);
  }

  const handleStructureRightClick = (targetStructure) => (e) => {
    if (targetStructure.isMine) {
      props.handleStructureRightClick(
        getClickLocation(e.latLng),
        targetStructure
      );
    }
  };

  function handleMapClick(e) {
    if (newHydrant) {
      if (geocoder && typeof geocoder.geocode === 'function') {
        geocoder.geocode(
          {
            latLng: e.latLng,
          },
          function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                const coordValues = {
                  x: e.pixel.x,
                  y: e.pixel.y,
                  latLng: { lat: e.latLng.lat(), lng: e.latLng.lng() },
                  streetAddress: results[0].formatted_address,
                };
                props.handleContextMenuItemSelect(
                  {
                    value: 'ADD_HYDRANT',
                  },
                  coordValues
                );
              }
            }
          }
        );
      }
      setNewHydrant(false);
    } else {
      props.handleMapClick();
    }
  }

  function handleMapRightClick(e) {
    const _props = props;
    if (geocoder && typeof geocoder.geocode === 'function') {
      geocoder.geocode(
        {
          latLng: e.latLng,
        },
        function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              _props.handleMapRightClick(
                e.pixel,
                { lat: e.latLng.lat(), lng: e.latLng.lng() },
                results[0].formatted_address
              );
            }
          }
        }
      );
    }
  }

  function recenterMap() {
    if (map !== undefined) {
      setCustomerCenter();
    }
  }

  function handleMarkerClose(marker) {
    props.setMarkerProps(marker, { showInfo: false }, false);
  }

  function handleMarkerRightClick(e, marker) {
    if (marker.isMine) {
      props.handleHydrantRightClick(getClickLocation(e.latLng), marker);
    }
  }

  function onScreenshot() {
    const dataURLtoBlob = (dataurl) => {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }

    Capture(document.querySelector('.map-container-v5'), {
      useCORS: true,
      ignoreElements: (el) => {
        if (el.classList.contains('gm-bundled-control')) {
          return true;
        }
        return el.nodeName === 'BUTTON' || el.nodeName === 'INPUT';
      },
      logging: true,
    }).then(canvas => {
      const imgData = canvas.toDataURL();
      const link = document.createElement('a');
      link.download = 'mapImage.png';
      const blob = dataURLtoBlob(imgData);
      const objurl = URL.createObjectURL(blob);
      link.href = objurl;
      document.body.appendChild(link);
      link.click();
    });
  }

  return (
    <ErrorBoundary>
      <Box height="100%">
        <Flex 
          w="100%" 
          h="60px" 
          align="center" 
          gap="12px" 
          py="10px" 
          px="12px" 
        >
          <AddressSearchBox 
            mapCenter={config.center}
            setMarkersFromSearch={props.setMarkersFromSearch}
            bounds={config.bounds} />
          <AddItem
            onSubmitAction={props.onSubmitAction}
            onCancelAction={props.onCancelAction}
            isInActionMode={props.isInActionMode}
            action={props.action}
            newHydrant={newHydrant}
            setNewHydrant={setNewHydrant}
            handleContextMenuItemSelect={props.handleContextMenuItemSelect}
            actionLoading={props.actionLoading}
          />
          {!props.isInActionMode && <OutlineButton
            color="#2C62CB"
            borderColor="#2C62CB"
            leftIcon={<FilterIcon fill="#2C62CB" />}
            onClick={props.toggleShowFilters}
          >
            Filters
          </OutlineButton>}
        </Flex>
        <Stack position="absolute" top="130px" left="12px" zIndex="1" spacing="16px">
          <Tooltip placement="right" label="Download Map Screenshot">
            <Box height="2rem" width="2rem" >
              <Icon
                cursor={'pointer'}
                as={FaCamera}
                width={'2rem'}
                height={'2rem'}
                zIndex={1}
                padding={'0.5rem'}
                borderRadius={'0.25rem'}
                background={'#fff'}
                onClick={onScreenshot}
                boxShadow="md"
              />
            </Box>
          </Tooltip>
          <Tooltip placement="right" label="Re-Center Map">
            <Box height="2rem" width="2rem">
              <Icon
                cursor={'pointer'}
                as={FaCrosshairs}
                width={'2rem'}
                height={'2rem'}
                zIndex={1}
                padding={'0.5rem'}
                borderRadius={'0.25rem'}
                background={'#fff'}
                onClick={recenterMap}
                boxShadow="md"
              />
            </Box>
          </Tooltip>
        </Stack>
        <Flex position="relative" className="map-container-v5" direction="column">
          {config.center && (
            <CustomMap
              newHydrant={newHydrant}
              hydrantInMove={props.hydrantInMove}
              onHydrantDragEnd={props.handleHydrantMove}
              buildingInMove={props.buildingInMove}
              onBuildingDragEnd={props.onBuildingDragEnd}
              onBuildingPinDragEnd={props.handleBuildingPinMove}
              setBuildingDragStartingPoint={props.setBuildingDragStartingPoint}
              visibleLocations={visibleLocations}
              visibleHydrants={visibleHydrants}
              disableDblClick={userConfig.disableDblClick === 'true'}
              markersFromSearch={
                props.markersFromSearch ? props.markersFromSearch : []
              }
              bounds={config.bounds}
              center={config.center}
              zoom={config.zoom}
              mapTypeId={config.mapTypeId}
              setMarkersFromSearch={props.setMarkersFromSearch}
              onMarkerEvent={handleMarkerEvent}
              handleMapMounted={handleMapMounted}
              onZoomChanged={handleZoomChanged}
              onStructureClick={props.handleStructureClick}
              structureToEdit={structureToEdit}
              onStructureRightClick={handleStructureRightClick}
              onMapClick={handleMapClick}
              onMapRightClick={handleMapRightClick}
              onMarkerRightClick={handleMarkerRightClick}
              onMarkerClose={handleMarkerClose}
              onMapTypeIdChanged={onMapTypeIdChanged}
              onIdle={handleOnIdle}
              distancePolyline={props.distancePolyline}
              setRemovePolygons={props.setRemovePolygons}
              showDrawingTools={props.showDrawingTools}
              showDrawingToolsOption={props.showDrawingToolsOption}
              prevPolylineData={props.prevPolylineData}
              onPolygonComplete={props.handlePolygonComplete}
              onPolylineComplete={props.handlePolylineComplete}
              selectedStructures={selectedStructures}
              selectedStructureId={selectedStructureId}
              hydrantIdToLocate={hydrantIdToLocate}
            />
          )}
        </Flex>
      </Box>
    </ErrorBoundary>
  );
};

export default React.memo(MapComponent, shouldNotUpdate);
