import * as types from './types';

export function loginSuccess(authData) {
  return {
    type: types.LOG_IN_SUCCESS,
    authData
  };
}

export function loginError(errMsg) {
	  return {type: types.INVALID_CREDENTIAL, errMsg: errMsg};
}
