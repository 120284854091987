import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Divider, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { OutlineButton, SubmitButtonDanger } from './Button';
import { useDispatch } from 'react-redux';
import { editStructure } from '../../features/structures/structuresSlice';
import { batchEditOccupant } from '../../features/occupants/occupantsSlice';


export const DeleteFloorForm = ({ name, floors, selectedStructure, closeModal }) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    const batchData = floors.find(f => f.name === name).occupants.map((occupant) => {
      const occupantPatch = [
        {
          op: 'replace',
          path: '/floor',
          value: null,
        }
      ];
      return {
        occupantId: occupant.id,
        occupantPatch: occupantPatch
      };
    });
    const batchEditOccupantResult = await dispatch(batchEditOccupant(batchData));
    if (!batchEditOccupant.fulfilled.match(batchEditOccupantResult)) {
      onError('Failed to delete floor', 'Failed to unassign floor attribute of at least one occupant in that floor.');
      return;
    }

    const newFloors = floors.filter(f => f.name && f.name !== name).map(f => ({ name: f.name }));
    const structurePatch = [
      {
        op: 'replace',
        path: '/floors',
        value: newFloors,
      }
    ];
    const editStructureResult = await dispatch(editStructure({ structureId: selectedStructure.id, structurePatch }));
    if (editStructure.fulfilled.match(editStructureResult)) {
      onSuccess('Success', 'Floor deleted');
    } else {
      onError('Failed to delete floor');
    }
  };

  const onError = (...p) => onComplete('error', ...p);

  const onSuccess = (...p) => {
    closeModal();
    onComplete('success', ...p);
  };

  const onComplete = (type, title, body) => {
    toast({
      title: title,
      position: 'top',
      description: body,
      status: type,
      duration: 2500,
      isClosable: true
    });
  };

  const onCancel = () => {
    methods.reset();
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing="1rem">
          <Text>Are you sure you want to delete <strong>{name}</strong>?</Text>
          <Divider />
          <HStack justifyContent="end" spacing="1rem">
            <OutlineButton onClick={onCancel} width="8rem">Cancel</OutlineButton>
            <SubmitButtonDanger isLoading={methods.formState.isSubmitting} width="8rem">Delete</SubmitButtonDanger>
          </HStack>
        </Stack>
      </form>
    </FormProvider>
  );
};