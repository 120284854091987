import { parseISO } from 'date-fns';


/**
 * Returns most recent assignment for a location (structure or occupant) or null
 * @param assignments
 * @param locationId
 * @returns {*|null}
 */
export function getLatestAssignment(assignments, locationId) {
    if (!assignments || !locationId) return null;

    const locationAssignments = assignments
        .filter(assignment => assignment.locationId === locationId)
        .sort((a, b) => {
            return parseISO(b.modifiedOn).getTime() - parseISO(a.modifiedOn).getTime(); // descending order - latest first
        });

    return locationAssignments.length > 0 ? locationAssignments[0] : null;
}
