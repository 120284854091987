import * as z from 'zod';


export const CreateUser = z.object({
  email: z.string().nonempty('A valid email is required'),
  role: z.string(),
});

export const EditUser = z.object({
  firstName: z.string().nonempty('A first name is required'),
  lastName: z.string().nonempty('A last name is required'),
  role: z.string(),
});
