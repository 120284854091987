import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";
import React from "react";

/**
 * @param {{ title?: string; children: React.ReactNode }} props
 */
export default function ErrorComponent(props) {
  const { title, children } = props;
  return (
    <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle>{title ?? "Error"}</AlertTitle>
      <AlertDescription>{children}</AlertDescription>
    </Alert>
  );
}
