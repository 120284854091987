import React, { Component } from 'react';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';
import { FaPencilAlt } from '@react-icons/all-files/fa/FaPencilAlt';
import { FaTag } from '@react-icons/all-files/fa/FaTag';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';

import GracefulImage from '../../helpers/GracefulImage';
export default class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToolbar: false,
    };
  }

  render() {
    const iconStyles = {
      cursor: 'pointer',
      height: '1.5rem',
      width: '1rem',
      marginY: '0.5rem',
      marginX: '0.6rem'
    };
    const spanStyles = {
      position: "absolute",
      color: "#fff",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      fontSize: "12pt",
      textAlign: "right",
      borderRadius: "8px",
      background: this.props.supportsTouch ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.3)",
      visibility: (this.props.supportsTouch || this.state.showToolbar) ? "visible" : "hidden",
    };
    return (
      <Box
        onClick={this.props.onImageClick}
        style={{ height: "100%", display: "inline-block" }}
        onMouseEnter={() => this.setState({ showToolbar: true })}
        onMouseLeave={() => this.setState({ showToolbar: false })}
      >
        <div style={{ height: "100%", minWidth: "256px", position: "relative", display: "inline-block" }}>
          <RetryImage src={this.props.src} isPDF={this.props.isPDF} pdfFileName={this.props.pdfFileName} />
          <Box style={spanStyles} _hover={{ visibility: "visible", }} >
            {!this.props.extremeleft && (this.props.canEditImage || this.props.isPDF) &&  this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaArrowLeft}
                onClick={e => {
                  this.props.onLeftClick();
                  e.stopPropagation();
                }}
              />
            }
            {!this.props.extremeright && (this.props.canEditImage || this.props.isPDF) &&  this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaArrowRight}
                onClick={e => {
                  this.props.onRightClick();
                  e.stopPropagation();
                }}
              />
            }
            {
              this.props.canEditImage && this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaPencilAlt}
                onClick={e => {
                  this.props.onAnnotationClick();
                  e.stopPropagation();
                }}
              />
            }
            {
              (this.props.canEditImage || this.props.isPDF) && this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaTag}
                onClick={e => {
                  this.props.onTaggingClick();
                  e.stopPropagation();
                }}
              />
            }
            {
              (this.props.canEditImage || this.props.isPDF) && this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaTimes}
                onClick={e => {
                  this.props.onDeleteClick();
                  e.stopPropagation();
                }}
              />
            }
          </Box>
        </div>
      </Box>
    );
  }
}

// @todo quick workaround for retry image. to refactor.
const RetryImage = (props) => {

  return (
    <React.Fragment>
      {props.isPDF ? (
        <Flex
          position="absolute"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
          flexDir="column"
          overflow="hidden"
          bg="#FFFFFF"
          borderRadius="8px"
         >
           <Icon as={FaFilePdf} height="4rem" width="4rem" color="red.400" />
           <Text fontSize="xs">
            {props.pdfFileName}
          </Text>
         </Flex>

      ) : (<GracefulImage
        src={props.src}
        noLazyLoad
        placeholder={props.src}
        style={{ borderRadius: "8px", height: "100%", width: "100%", margin: "0 auto", display: "block" }}
      />)}
    </React.Fragment>
  );

};
