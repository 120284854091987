import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
// import { DataTable } from '../../components/dashboard/DataTable';
import { DataTable } from '../../components/dashboard/DataTable';
import styled from 'styled-components';
import { formatLocationDate, isAfterSubDays } from '../../helpers/Date';
import { useDispatch, useSelector } from 'react-redux';

import { selectAllStructures, getAllStructures } from '../../features/structures/structuresSlice';
import { selectAllAssignments, fetchAllAssignments } from '../../features/assignments/assignmentsSlice';
import { resetFilters } from '../../features/user/userSlice';

import { Filters } from './Filters';
import { getNameOrEmail } from '../../helpers/User';
import {getHydrantReportCSV, getISOReportCSV} from '../../api/LocationAPI';
import { getLatestAssignment } from '../../components/assignments/AssignmentUtil';
import { allColumnIds, allColumns, ROW_TYPES } from './PrePlanTableColumns';
import { getAllProperties, selectAllProperties } from '../../features/properties/propertiesSlice';
import { getAllOccupants, selectAllOccupants } from '../../features/occupants/occupantsSlice';
import { buildingDataFields } from '../../components/common/BuildingDataComponent';
import { OutlineButton } from '../../components/form/Button';
import FilterIcon from '../../components/FigmaExport/FilterIcon';
import { ColumnsPreferencesDrawer } from './ColumnsPreferencesDrawer';
import DownloadIcon from '../../components/FigmaExport/DownloadIcon';
import ArrowDownIcon from '../../components/FigmaExport/ArrowDownIcon';
import { AssignmentButton } from './AssignmentButton';
import { CSVDownloadButton } from './CSVDownloadButton';
import { DownloadButton } from './DownloadButton';



const completionCalc = (parsedData) => {
  const breakdown = {
    address: 0,
    footprint: 0,
    building: 0,
    hydrants: 0,
    annotations: 0
  };
  let completion = 0;
  if (parsedData.address && parsedData.address.address1) {
    completion += 5;
    breakdown.address = 5;
  }
  if (!parsedData.latLon) {
    completion += 10;
    breakdown.footprint += 10;
  }
  if (parsedData.building) {
    const buildingFields = Object.keys(parsedData.building).filter(key => buildingDataFields.find(b => b.id === key));

    if (!buildingFields.length || buildingFields.length === 0) {
      breakdown.building = 0;
    } else if (buildingFields.length <= 2) {
      completion += 5;
      breakdown.building += 5;
    } else if (buildingFields.length <= 4) {
      completion += 10;
      breakdown.building += 10;
    } else if (buildingFields.length <= 6) {
      completion += 15;
      breakdown.building += 15;
    } else if (buildingFields.length <= 8) {
      completion += 20;
      breakdown.building += 20;
    } else if (buildingFields.length >= 9) {
      completion += 25;
      breakdown.building += 25;
    }
  } else {
    console.warn('Building data undefined: ', parsedData.building); // eslint-disable-line no-console
  }
  if (Array.isArray(parsedData.hydrants) && parsedData.hydrants.length > 0) {
    completion += 10;
    breakdown.hydrants += 10;
  }
  const imgL = parsedData.imageAnnotations;
  if (imgL && imgL > 5) {
    completion += 50;
    breakdown.annotations += 50;
  }
  if (imgL && imgL <= 5) {
    completion += imgL * 10;
    breakdown.annotations += imgL * 10;
  }

  completion = completion > 100 ? 100 : completion;

  return {
    completion: completion,
    breakdown: breakdown
  };
};

const measureReviewStatus = (lastReviewedOn) => {
  const parsedDate = formatLocationDate(lastReviewedOn);

  if (isAfterSubDays(parsedDate, 270)) {
    return 'Updated';
  } else if (isAfterSubDays(parsedDate, 365)) {
    return 'Due';
  }
  return 'Urgent';
};


const mapStructureData = (structuresWithAssigment) => {
  return structuresWithAssigment.map((item) => {
    return {
      id: item.id,
      type: ROW_TYPES.STRUCTURE,
      name: item.name || "LOCATION",
      address: item.address || {},
      lotNumber: item.lotNumber,
      customerZone: item.customerZone,
      storey: item.storey,
      storeyBelow: item.storeyBelow,
      roofArea: item.roofArea,
      hydrants: Array.isArray(item.hydrants) ? item.hydrants.length : 0,
      imageLength: item.images ? item.images.length : 0,
      imageAnnotations: item.imageAnnotations ? item.imageAnnotations : 0,
      completion: completionCalc(item),
      floorPlan: item.imageTags && item.imageTags.indexOf('Floor Plan') > -1,
      isHighHazard: Boolean(item.isHighHazard),
      riskAssessmentScore: item.riskAssessmentScore,
      reviewStatus: item.lastReviewedOn ? measureReviewStatus(item.lastReviewedOn) : '',
      originalPrePlan: item.originalPrePlan ? item.originalPrePlan : '',
      lastReviewedOn: item.lastReviewedOn ? item.lastReviewedOn : '',
      lastReviewedBy: item.lastReviewedBy ? item.lastReviewedBy : '',
      assignedBy: item.assignedBy,
      assignedTo: item.assignedTo,
      assignmentStatus: item.assignmentStatus,
      assignmentDueOn: item.assignmentDueOn,
      assignmentNotes: item.assignmentNotes,
      rawAssignmentData: item.rawAssignmentData,
      building: item.building ? item.building : {},
    };
  });
};

const mapOccupantData = (occupantsWithAssigment) => {
  return occupantsWithAssigment.map((occupant) => {
    return {
      id: occupant.id,
      type: ROW_TYPES.OCCUPANT,
      name: occupant.name || "OCCUPANT",
      address: occupant.address || {},
      lotNumber: occupant.lotNumber,
      customerZone: occupant.customerZone,
      storey: occupant.storey,
      storeyBelow: occupant.storeyBelow,
      roofArea: occupant.roofArea,
      hydrants: Array.isArray(occupant.hydrants) ? occupant.hydrants.length : 0,
      imageLength: occupant.images ? occupant.images.length : 0,
      imageAnnotations: occupant.imageAnnotations ? occupant.imageAnnotations : 0,
      completion: null, //completionCalc(occupant),
      floorPlan: occupant.imageTags && occupant.imageTags.indexOf('Floor Plan') > -1,
      isHighHazard: Boolean(occupant.isHighHazard),
      riskAssessmentScore: occupant.riskAssessmentScore,
      reviewStatus: occupant.lastReviewedOn ? measureReviewStatus(occupant.lastReviewedOn) : '',
      originalPrePlan: occupant.originalPrePlan ? occupant.originalPrePlan : '',
      lastReviewedOn: occupant.lastReviewedOn ? occupant.lastReviewedOn : '',
      lastReviewedBy: occupant.lastReviewedBy ? occupant.lastReviewedBy : '',
      assignedBy: occupant.assignedBy,
      assignedTo: occupant.assignedTo,
      assignmentStatus: occupant.assignmentStatus,
      assignmentDueOn: occupant.assignmentDueOn,
      assignmentNotes: occupant.assignmentNotes,
      rawAssignmentData: occupant.rawAssignmentData,
      building: occupant.building ? occupant.building : {},
    };
  });
};

export const PrePlanDataContainer = (/*props*/) => {

  const { lastFetch: lastPropertiesFetch } = useSelector(store => store.properties);
  const { lastFetch: lastStructuresFetch } = useSelector(state => state.structures);
  const { lastFetch: lastOccupantsFetch } = useSelector(store => store.occupants);
  const { lastFetch: lastAssignmentsFetch } = useSelector(state => state.assignments);
  
  const loading = useMemo(() => (
    !lastPropertiesFetch || !lastStructuresFetch || !lastOccupantsFetch || !lastAssignmentsFetch
  ), [lastPropertiesFetch, lastStructuresFetch, lastOccupantsFetch, lastAssignmentsFetch]);

  const dispatch = useDispatch();

  const allProperties = useSelector(selectAllProperties);
  const allStructures = useSelector(selectAllStructures);
  const allOccupants = useSelector(selectAllOccupants);
  const allAssignments = useSelector(selectAllAssignments);

  const customer = useSelector(state => state.customer);
  const user = useSelector(state => state.user);

  // Mapped data
  const [tableData, setTableData] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const selectedRows = useMemo(() => {
    return selectedRowIds.map(id => {
      const type = allStructures.find(structure => structure.id === id) ? ROW_TYPES.STRUCTURE : ROW_TYPES.OCCUPANT;
      if (!allOccupants.find(occupant => occupant.id === id)) {
        console.warn("selected row id is not a structure nor an occupant id");
      }
      return {
        id,
        type
      };
    });
  }, [selectedRowIds, allStructures, allOccupants]);

  const [showColumnsDrawer, setShowColumsDrawer] = useState(false);

  useEffect(() => {
    if (!lastPropertiesFetch) {
      dispatch(getAllProperties());
    }
  }, [lastPropertiesFetch, dispatch]);

  useEffect(() => {
    if (!lastStructuresFetch) {
      dispatch(getAllStructures());
    }
  }, [lastStructuresFetch, dispatch]);

  useEffect(() => {
    if (!lastOccupantsFetch) {
      dispatch(getAllOccupants());
    }
  }, [lastOccupantsFetch, dispatch]);

  useEffect(() => {
    if (!lastAssignmentsFetch) {
      dispatch(fetchAllAssignments());
    }
  }, [lastAssignmentsFetch, dispatch]);

  useEffect(() => {
    if (loading) return;

    const mapAssignmentData = (assignables, assignments) => { // assignable = structure | occupant
      return assignables.map(assignable => {
        const latestAssignment = getLatestAssignment(assignments, assignable.id);
        if (latestAssignment) {
          const assignedBy = customer.users.find(u => u.id === latestAssignment.assignedBy);
          const assignedTo = customer.users.find(u => u.id === latestAssignment.assignedTo);
          return {
            ...assignable,
            assignedBy: assignedBy ? getNameOrEmail(assignedBy) : '',
            assignedTo: assignedTo ? getNameOrEmail(assignedTo) : '',
            assignmentStatus: latestAssignment.assignmentStatus,
            assignmentDueOn: latestAssignment.assignmentDueOn,
            rawAssignmentData: latestAssignment,
            assignmentNotes: latestAssignment.notes
          };
        }
        return {
          ...assignable
        };
      });
    };

    const mappedStructuresData = mapAssignmentData(allStructures.filter(structure => structure.isMine), allAssignments);
    const mappedOccupantsData = mapAssignmentData(allOccupants.filter(occupant => occupant.isMine), allAssignments);
    const structuresData = mapStructureData(mappedStructuresData).map(item => ({
      ...item,
      subRows: allOccupants ? mapOccupantData(mappedOccupantsData.filter(occupant => occupant.structureId && occupant.structureId === item.id)) : [],
    }));

    setTableData([...structuresData]);
  }, [allProperties, allStructures, allOccupants, allAssignments, loading, customer]);

  const onRowsSelected = useCallback(rowIds => {
    setSelectedRowIds(rowIds);
  }, [setSelectedRowIds]);


  const activeFilters = useMemo(() => {
    if (!user || !user.uiConfig) return [];
    return user.uiConfig.dashboardFilterValues;
  }, [user]);

  const hiddenColumns = useMemo(() => {
    if (!user || !user.uiConfig || !user.uiConfig.dashboardColumnsVisibility) return [];
    return allColumnIds.filter(id => !user.uiConfig.dashboardColumnsVisibility[id]);
  }, [user]);

  const visibleHeaderIds = useMemo(() => {
    return hiddenColumns && allColumnIds.filter(id => !hiddenColumns.includes(id));
  }, [hiddenColumns]);

  return (
    <Fragment>
      <Flex fontFamily="Inter" width={'100%'} height="100%" direction={'column'} p="16px" overflow="auto">
        <Flex direction={'row'} alignItems="center" justifyContent={'space-between'} p="12px">
          <Heading as="h2" size="lg">Preplan Dashboard</Heading>
          <Menu placement="bottom-end" closeOnSelect={false}>
            {({ onClose }) => (
                <Fragment>
                  <MenuButton as={Button} variant="outline" color="#2C62CB" border={0} leftIcon={<DownloadIcon />} rightIcon={<ArrowDownIcon />}>Download</MenuButton>
                  <MenuList color="#2C62CB">
                    <MenuItem as={'div'} style={{padding: 0}}>
                      <DownloadButton
                          menuItem
                          variant="ghost"
                          visibleHeaderIds={visibleHeaderIds}
                          tableData={tableData}
                          onComplete={onClose}
                      >
                        Preplan Report (CSV)
                      </DownloadButton>
                    </MenuItem>
                    <MenuItem as={'div'} style={{padding: 0}}>
                      <CSVDownloadButton onComplete={onClose} execute={() => getISOReportCSV(customer.slug)} filename="iso_report.csv">ISO Report (CSV)</CSVDownloadButton>
                    </MenuItem>
                    <MenuItem as={'div'} style={{padding: 0}}>
                      <CSVDownloadButton onComplete={onClose} execute={() => getHydrantReportCSV(customer.slug)} filename="hydrant_report.csv">Hydrant Report (CSV)</CSVDownloadButton>
                    </MenuItem>
                  </MenuList>
                </Fragment>
            )}
          </Menu>
        </Flex>
        <Box p="12px" h="100%">
          <Flex direction={'row'} alignItems="center" justifyContent={'space-between'}>
            <Heading as="h4" size="lg">Locations & Assignments</Heading>
          </Flex>
          <Divider mt="12px" mb="24px" />
          <TableStyles>
            {tableData && (
              <DataTable
                actionComponent={
                  <Fragment>
                    <OutlineButton
                      fontSize="sm"
                      color="#2C62CB"
                      borderColor="#2C62CB"
                      leftIcon={<FilterIcon />}
                      onClick={() => setShowColumsDrawer(true)}
                    >
                      Columns
                    </OutlineButton>
                    <AssignmentButton selectedRows={selectedRows} />
                  </Fragment>
                }
                showExpandAllButton
                onRowsSelected={onRowsSelected}
                selectable
                globalSearch
                loading={loading}
                columns={allColumns}
                hiddenColumns={hiddenColumns}
                data={tableData}
                Filters={Filters}
                activeFilters={activeFilters}
                onResetFilters={() => dispatch(resetFilters())}
                chakraProps={{
                  variant: 'simple',
                  size: 'sm'
                }}
              />
            )}
          </TableStyles>
        </Box>
      </Flex>
      <ColumnsPreferencesDrawer isOpen={showColumnsDrawer} onClose={() => setShowColumsDrawer(false)} />
    </Fragment>
  );
};


//@todo move...
const TableStyles = styled.div`
  .hydrants,
  .images,
  .completion,
  .floorPlan,
  .highHazard,
  .district,
  .reviewStatus {
    font-weight: 600;
  }
  .address .name,
  .name-date .name {
    font-weight: 600;
  }
  td, th {
    padding: 0.5rem;
  }
  th {
    align-items: end;
    overflow: hidden;
  }
  thead {
    border-bottom: 1px solid var(--chakra-colors-gray-600);
  }
`;
