import React, { useState } from 'react';
import { IconButton, Text, useToast } from '@chakra-ui/react';
import Modal from '../common/ModalComponent';
import { SubmitButtonDanger } from '../form/Button';
import { useDispatch } from 'react-redux';
import { deleteUnit } from '../../features/departmentUnits/departmentUnitsSlice';
import DeleteIcon from '../FigmaExport/DeleteIcon';


export const DeleteUnitModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();

  const onDelete = async () => {
    setIsLoading(true);

    const resultAction = await dispatch(deleteUnit(props.unit.id));

    if (deleteUnit.fulfilled.match(resultAction)) {
      setModalOpen(false);
      toast({
        title: 'Success',
        position: 'top',
        description: 'Unit deleted successfully.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: 'There was an error deleting this unit, please try again.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <IconButton
        isDisabled={props.isDisabled}
        size="sm"
        aria-label="Delete Unit"
        color="red.400"
        icon={<DeleteIcon />}
        bg="none"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        showModal={modalOpen}
        toggleModal={() => setModalOpen(false)}
        modal={{
          heading: 'Confirm Delete',
          body: (<Text>Are you sure you want to delete this unit?</Text>),
          footer: <SubmitButtonDanger isLoading={isLoading} onClick={onDelete}>Delete</SubmitButtonDanger>
        }}
      />
    </React.Fragment>
  );
};
