import React from 'react';

class HydrantMarkerInfo extends React.Component {

  render() {
    const hydrant = this.props.hydrant;
    return (
      <div className="info-window-container">
        {hydrant.externalRef && (
          <div className="info-window-detail">
            <span className="info-window-label">Hydrant: </span>
            <span>{hydrant.externalRef}</span>
          </div>
        )}
        {hydrant.flow && (
          <div className="info-window-detail">
            <span className="info-window-label">Flow Rate: </span>
            <span>{hydrant.flow}</span>
          </div>
        )}
        {hydrant.streetAddress && (
          <div className="info-window-detail">
            <span className="info-window-label">Address: </span>
            <span>{hydrant.streetAddress}</span>
          </div>
        )}
        {hydrant.notes && (
          <div className="info-window-detail">
            <span className="info-window-label">Notes: </span>
            <span>{hydrant.notes}</span>
          </div>
        )}
        {hydrant.hydrantId && (
          <div className="info-window-detail">
            <span className="info-window-label">External ID: </span>
            <span>{hydrant.hydrantId}</span>
          </div>
        )}
        {!(hydrant.externalRef || hydrant.streetAddress || hydrant.flow || hydrant.notes) && (
          <div className="info-window-detail">
            <span>No hydrant data provided</span>
          </div>
        )}
      </div>
    );
  }

}

export default HydrantMarkerInfo;
