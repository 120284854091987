import React from 'react';
import { Stack } from '@chakra-ui/react';
import StructureInfoPanel from './StructureInfoPanel';
import PropertyInfoPanel from './PropertyInfoPanel';
import { HydrantsInfoPanel } from './HydrantsInfoPanel';
import { AssignmentCard } from '../assignments/AssignmentCard';
import { ROW_TYPES } from '../../containers/dashboard/PrePlanTableColumns';
import { ReadOnlyBanner } from './ReadOnlyBanner';


const StructureDataPanel = ({
  selectedStructure,
  property,
  handleConnectPropertyStructures,
  handleRePlanStart,
  handleMoveStart,
  handleEditLocationSuccess
}) => {
  return ( 
    <Stack gap="12px">
      { selectedStructure?.readOnly && <ReadOnlyBanner name="Elite" /> }
      <StructureInfoPanel
        structure={selectedStructure}
        handleRePlanStart={handleRePlanStart}
        handleMoveStart={handleMoveStart}
        handleEditLocationSuccess={handleEditLocationSuccess}
      />
      { selectedStructure &&
        <PropertyInfoPanel property={property} structure={selectedStructure} handleConnectPropertyStructures={handleConnectPropertyStructures} />
      }
      <HydrantsInfoPanel />
      { selectedStructure &&
        <AssignmentCard locationId={selectedStructure.id} type={ROW_TYPES.STRUCTURE} readOnly={selectedStructure?.readOnly} notMine={!selectedStructure?.isMine} />
      }
    </Stack>
  );
};


export default StructureDataPanel;
