import React from 'react';
import {
  Stack,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  GridItem,
  Grid,
  Divider,
  Flex,
  Tooltip,
  // Icon
} from '@chakra-ui/react';
import { AddressStack } from './AddressStack';
import { AssignmentCard } from '../assignments/AssignmentCard';
import { MoreableText } from './MoreableText';
import { OutlineButton } from '../form/Button';
import EditIcon from '../FigmaExport/EditIcon';
import DeleteIcon from '../FigmaExport/DeleteIcon';
import ArrowUpIcon from '../FigmaExport/ArrowUpIcon';
import ArrowDownIcon from '../FigmaExport/ArrowDownIcon';
import { ROW_TYPES } from '../../containers/dashboard/PrePlanTableColumns';
import DoNotShareIcon from '../FigmaExport/DoNotShareIcon';
// import AssignmentIcon from '../FigmaExport/AssignmentIcon';


const OCCUPANT_FIELDS = [
  {
    label: 'Occupant Label',
    of: (occupant) => occupant.name,
  },
  {
    label: 'Address',
    of: (occupant) => occupant.address,
  },
  {
    label: 'Occupancy Type',
    of: (occupant) => occupant.building.occupancyType,
  },
  {
    label: 'Construction Type',
    of: (occupant) => occupant.building.constructionType,
  },
  {
    label: 'Do Not Share',
    of: (occupant) => occupant.doNotShare ? 'Yes' : 'No',
    iconOf: (occupant) => occupant.doNotShare ? <DoNotShareIcon /> : null,
  },
  {
    label: 'Square Feet',
    of: (occupant) => occupant.sqft,
  },
  {
    label: 'Roof Type',
    of: (occupant) => occupant.building.roofType,
  },
  {
    label: 'Roof Construction',
    of: (occupant) => occupant.building.roofConstruction,
  },
  {
    label: 'Roof Material',
    of: (occupant) => occupant.building.roofMaterial,
  },
  {
    label: 'Sprinklered',
    of: (occupant) => occupant.building.sprinklered,
  },
  {
    label: 'Stand Pipe',
    of: (occupant) => occupant.building.standPipe,
  },
  {
    label: 'Fire Alarm',
    of: (occupant) => occupant.building.fireAlarm,
  },
  {
    label: 'Normal Population',
    of: (occupant) => occupant.building.normalPopulation,
  },
  {
    label: 'Hours Of Operation',
    canBeLong: true,
    of: (occupant) => occupant.building.hoursOfOperation,
  },
  {
    label: 'Owner Contact',
    of: (occupant) => occupant.building.ownerContact,
  },
  {
    label: 'Owner Phone',
    of: (occupant) => occupant.building.ownerPhone,
  },
  {
    label: 'PrePlan Created Date',
    of: (occupant) => occupant.building.originalPrePlan,
  },
  {
    label: 'Last Reviewed Date',
    of: (occupant) => occupant.building.lastReviewedOn,
  },
  {
    label: 'Last Reviewed By',
    of: (occupant) => occupant.building.lastReviewedBy,
  },
  {
    label: 'Notes',
    canBeLong: true,
    of: (occupant) => occupant.notes,
  },
  {
    label: 'Partner Notes',
    canBeLong: true,
    of: (occupant) => occupant.building.partnerNotes,
    hideIfEmpty: true,
  },
];


const OccupantInfoPanel = ({
  occupant,
  handleEditOccupant,
  handleDeleteOccupant,
  canEdit,
  reason
}) => {
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.75rem',
    color: 'gray.700'
  };

  return occupant && (
    <Accordion defaultIndex={0} allowToggle>
      <AccordionItem border={0}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton paddingLeft={0} paddingY={0}>
                <Flex flex='1' alignItems="center" gap="4px">
                  <Text as='h2' fontSize="xl" fontWeight="600">{occupant.name || "Occupant"}</Text>
                  {/* { occupant.activeAssignment && <Icon as={AssignmentIcon} ml="2px" fill={occupant.activeAssignment.status} /> } */}
                </Flex>
                { isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon /> }
              </AccordionButton>
            </h2>
            <AccordionPanel padding={0} mt="6px">
              <Stack gap="10px">
                <Divider marginY={1}/>
                <Flex gap="8px" >
                  <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
                    <Flex flexGrow={1}>
                      <OutlineButton
                        fontSize="sm"
                        color="#2C62CB"
                        borderColor="#2C62CB"
                        leftIcon={<EditIcon fill="#2C62CB" />}
                        onClick={() => handleEditOccupant(occupant)}
                        flexGrow={1}
                        isDisabled={!canEdit}
                      >
                        Edit
                      </OutlineButton>
                    </Flex>
                  </Tooltip>
                  <Tooltip hasArrow isDisabled={canEdit} placement="left" label={reason}>
                    <Flex flexGrow={1}>
                      <OutlineButton
                        fontSize="sm"
                        color="red"
                        borderColor="red"
                        leftIcon={<DeleteIcon fill="red" />}
                        onClick={() => handleDeleteOccupant(occupant)}
                        flexGrow={1}
                        isDisabled={!canEdit}
                      >
                        Delete
                      </OutlineButton>
                    </Flex>
                  </Tooltip>
                </Flex>
                <Stack spacing="">
                  {OCCUPANT_FIELDS.map((field, index) => (!field.hideIfEmpty || field.of(occupant)) && (
                    <Grid key={index} templateColumns='2fr 3fr'>
                      <GridItem><Text {...labelStyle}>{field.label}</Text></GridItem>
                      <GridItem>
                        {field.label === "Address" ?
                          <AddressStack {...occupant.address} />
                          : field.canBeLong ?
                            <MoreableText text={field.of(occupant)} header={field.label} noOfLines={2} fontWeight="600" fontSize="sm" />
                            :
                            <Flex gap={2} alignItems="center">
                              <Text fontWeight="600" fontSize="sm">
                                {field.of(occupant)}
                              </Text>
                              {field.iconOf?.(occupant)}
                            </Flex>
                        }
                      </GridItem>
                    </Grid>
                  ))}
                </Stack>
                <AssignmentCard locationId={occupant.id} type={ROW_TYPES.OCCUPANT} readOnly={!canEdit} />
              </Stack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};


export default OccupantInfoPanel;
