import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as occupantApi from '../../api/OccupantAPI';

// Thunks
export const getAllOccupants = createAsyncThunk(
  'occupants/getAllOccupants',
  async(data, thunkAPI) => {
    const response = await occupantApi.getAllOccupants();
    return response.data;
  }
);

export const getOccupantById = createAsyncThunk(
  'occupants/getOccupantById',
  async(data, thunkAPI) => {
    const response = await occupantApi.getOccupantById(data);
    return response.data;
  }
);

export const addOccupant = createAsyncThunk(
  'occupants/addOccupant',
  async(data, thunkAPI) => {
    const response = await occupantApi.addOccupant(data);
    return response.data;
  }
);

export const editOccupant = createAsyncThunk(
  'occupants/editOccupant',
  async(data, thunkAPI) => {
    const response = await occupantApi.editOccupant(data.occupantId, data.occupantPatch);
    return response.data;
  }
);

export const batchEditOccupant = createAsyncThunk(
  'occupants/batchEditOccupant',
  async(batchData, thunkAPI) => {
    const responses = await Promise.all(batchData.map(data =>
      occupantApi.editOccupant(data.occupantId, data.occupantPatch)
    ));
    return responses.map(response => response.data);
  }
);

export const deleteOccupant = createAsyncThunk(
  'occupants/deleteOccupant',
  async(data, thunkAPI) => {
    const response = await occupantApi.deleteOccupant(data);
    return { ...response.data, id: data };
  }
);

export const addImageToOccupant = createAsyncThunk(
  'occupants/addImageToOccupant',
  async(data, thunkAPI) => {
    const response = await occupantApi.addImageToOccupant(data.occupantId, data.imageId);
    return response.data;
  }
);

export const removeImageFromOccupant = createAsyncThunk(
  'occupants/removeImageFromOccupant',
  async(data, thunkAPI) => {
    const response = await occupantApi.removeImageFromOccupant(data.occupantId, data.imageId);
    return response.data;
  }
);

export const fetchPartnerOccupants = createAsyncThunk(
  'occupants/fetchPartnerOccupants',
  async(partnerId, thunkAPI) => {
    const response = await occupantApi.fetchPartnerOccupants(partnerId);
    return response.data;
  }
);


// Adapter & State
export const occupantsAdapter = createEntityAdapter();
const initialState = occupantsAdapter.getInitialState({
  loading: 'idle',
  error: null,
  lastFetch: null,
  selectedOccupantId: null,
  filters: [],
  filteredOccupantIds: [],
  partnerLoading: 'idle',
  lastPartnerFetch: null,
});

export const occupantsSlice = createSlice({
  name: 'occupants',
  initialState,
  reducers: {
    // occupantsLoading: (state, action) => {
    //   if (state.loading === 'idle') {
    //     state.loading = 'pending';
    //   }
    // },
    // occupantsReceived: (state, action) => {
    //   if (state.loading === 'pending') {
    //     state.loading = 'idle';
    //     state.entities = action.payload;
    //   }
    // },
    setSelectedOccupantId: (state, action) => {
      state.selectedOccupantId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getAllOccupants
    builder
      .addCase(getAllOccupants.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(getAllOccupants.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.lastFetch = Date.now();
        if (action.payload && action.payload.length > 0) {
          occupantsAdapter.setAll(state, action.payload.map(occupant => ({ ...occupant, isMine: true })));
        }
      })
      .addCase(getAllOccupants.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.lastFetch = Date.now();
        }
      });
    // getOccupantById
    builder
      .addCase(getOccupantById.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(getOccupantById.fulfilled, (state, action) => {
        state.loading = 'idle';
        occupantsAdapter.setOne(state, { ...action.payload, isMine: true });
      })
      .addCase(getOccupantById.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // addOccupant
    builder
      .addCase(addOccupant.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(addOccupant.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          occupantsAdapter.addOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(addOccupant.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // editOccupant
    builder
      .addCase(editOccupant.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(editOccupant.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          occupantsAdapter.setOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(editOccupant.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // batchEditOccupant
    builder
      .addCase(batchEditOccupant.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(batchEditOccupant.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          occupantsAdapter.setMany(state, action.payload.map(payload => ({ ...payload, isMine: true })));
        }
      })
      .addCase(batchEditOccupant.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // deleteOccupant
    builder
      .addCase(deleteOccupant.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(deleteOccupant.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          occupantsAdapter.removeOne(state, action.payload.id);
        }
      })
      .addCase(deleteOccupant.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // addImageToOccupant
    builder
      .addCase(addImageToOccupant.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(addImageToOccupant.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          occupantsAdapter.setOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(addImageToOccupant.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // removeImageFromOccupant
    builder
      .addCase(removeImageFromOccupant.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(removeImageFromOccupant.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          occupantsAdapter.setOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(removeImageFromOccupant.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // fetchPartnerOccupants
    builder
      .addCase(fetchPartnerOccupants.pending, (state, action) => {
        if (state.partnerLoading === 'idle') {
          state.partnerLoading = 'pending';
        }
      })
      .addCase(fetchPartnerOccupants.fulfilled, (state, action) => {
          state.partnerLoading = 'idle';
        if (action.payload.data) {
          occupantsAdapter.upsertMany(state, action.payload.data.map(occupant => ({ ...occupant, isMine: false })));
          state.lastPartnerFetch = Date.now();
        }
      })
      .addCase(fetchPartnerOccupants.rejected, (state, action) => {
        if (
          state.partnerLoading === 'pending'
        ) {
          state.partnerLoading = 'idle';
          state.error = action.error;
        }
      });
  }
});


const { actions, reducer } = occupantsSlice;

export const {
  setSelectedOccupantId
} = actions;

export default reducer;


export const {
  selectById: selectOccupantById,
  selectAll: selectAllOccupants,
  selectTotal: selectTotalOccupants,
} = occupantsAdapter.getSelectors(state => state.occupants);
