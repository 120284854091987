import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { Box, Text as ChText, Divider, Heading, Center, Spinner, Flex, Button } from '@chakra-ui/react';
import { FlexWithBackgroundTheme } from '../../containers/ThemeContainer';
import { fetchAllHydrants, selectAllHydrants } from '../../features/hydrants/hydrantsSlice';
import { buildStaticMapUrl } from '../map/StaticMap';
import { STRUCTURE_FIELDS } from '../common/StructureInfoPanel';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';
import { getStructureById } from '../../api/StructureAPI';
import { getAllImages, selectAllImages } from '../../features/images/imagesSlice';


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: '24px 12px'
  },
  section: {
    margin: 12,
    padding: '0px 24px',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 12,
  },
  text: {
    fontSize: 12,
    fontWeight: 600
  },
  image: {
    maxWidth: '1024px'
  }
});

// Create Document Component
const PrePlanPDF = ({
  structure,
  hydrants,
  images
}) => {

  const staticMapUrl = buildStaticMapUrl(structure, hydrants);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={styles.section}>
            <Text style={styles.text}>{structure.name}</Text>
            {/* <Text style={styles.text}>{structure./ess.city}, {structure.address.state} {structure.address.zip}</Text> */}
            {structure.lotNumber && <Text style={styles.text}>Lot #: {structure.lotNumber}</Text>}
            {structure.customerZone && <Text style={styles.text}>District: {structure.customerZone}</Text>}
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Floors Above: {structure.storey}</Text>
            <Text style={styles.text}>Floors Below: {structure.storeyBelow}</Text>
            <Text style={styles.text}>Roof Area: {structure.roofArea}</Text>
            <Text style={styles.text}>Required Flow: {structure.requiredFlow}</Text>
          </View>
        </View>
        {structure.isHighHazard && <View style={styles.section}>
          <Text style={styles.text}>HIGH HAZARD</Text>
          {structure.highHazardDescription && <Text style={styles.text}>{structure.highHazardDescription}</Text>}
        </View>
        }
        {staticMapUrl && (
          <View style={styles.section}>
            <Image src={staticMapUrl} />
          </View>
        )}
        {structure && (
          <View style={styles.section}>
            {structure.commercialStructure && (
              <View style={styles.section}>
                <Text style={styles.text}>Commercial</Text>
              </View>
            )}
            {STRUCTURE_FIELDS.map((field, index) => (
              <DataField key={index} label={field.label} value={field.of(structure)} />
            ))}
          </View>
        )}
      </Page>
      {hydrants && (
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>Hydrants</Text>
          </View>
          {hydrants.map((hydrant, index) => (
            <HydrantData key={index} hydrant={hydrant} />
          ))}
        </Page>
      )}
      {images && images.length > 0 && (
        <Page size="A4" style={styles.page}>
          {images && images.filter(image => image.hrefThumbnail && image.hrefThumbnail !== 'NoImage').map((image, index) => {
            return (
              <PdfImage key={index} image={image} />
            );
          }
          )}
        </Page>
      )}
    </Document>
  );
};

const HydrantData = ({
  hydrant
}) => {
  // @todo map to existing field structur -- external ID needed, too?
  return (
    <View style={styles.section} wrap={false}>
      <DataField label="Lat/Lon" value={`${hydrant.position.lat}, ${hydrant.position.lng}`} />
      <DataField label="Address" value={hydrant.streetAddress} />
      <DataField label="Flow (GPM)" value={hydrant.flow} />
      <DataField label="Size" value={hydrant.size} />
      <DataField label="Notes" value={hydrant.notes} />
      <DataField label="Dry Hydrant" value={hydrant.dryHydrant ? 'Yes' : 'No'} />
      <DataField label="In Service" value={hydrant.inService ? 'Yes' : 'No'} />
      <DataField label="Out of Service Date" value={hydrant.outServiceDate} />
      <DataField label="External Hydrant Identifier" value={hydrant.hydrantId} />
    </View>
  );
};

const DataField = ({
  label,
  value }) => {

  const dfStyle = StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      ...styles.text,
      fontWeight: 'bold',
      width: '40%',
    },
    value: {
      ...styles.text,
      width: '60%'
    }
  });

  return (
    <View style={dfStyle.wrapper}>
      <Text style={dfStyle.label}>{label}: </Text>
      {label !== 'Address' && <Text style={dfStyle.value}>{value ? value : '--'}</Text>}
      {label === 'Address' &&
        <Text style={dfStyle.value}>
          {value.address1}, {value.address2}, {value.city}, {value.state} {value.zip}
        </Text>
      }
    </View>
  );
};

const PdfImage = ({ image }) => {
  return (
    <View style={styles.section} wrap={false}>
      <Image style={{ width: '100%', height: 'auto', maxHeight: '90vh', objectFit: 'contain' }} src={image.hrefAnnotated ? image.hrefAnnotated : image.href} />
    </View>
  );

};
export const PrePlanExport = () => {
  const { lastFetch: lastHydFetch } = useSelector(store => store.hydrants);
  const { lastFetch: lastImagesFetch } = useSelector(store => store.images);

  const [loading, setLoading] = useState(true);
  const [/*error*/, setError] = useState(null);
  const [structure, setStructure] = useState(null);

  const { id } = useParams();
  const allHydrants = useSelector(selectAllHydrants);
  const allImages = useSelector(selectAllImages);
  const dispatch = useDispatch();

  const [hydrantIds, setHydrantIds] = useState([]);
  const [imageIds, setImageIds] = useState([]);

  useEffect(() => {
    if (!lastHydFetch) {
      dispatch(fetchAllHydrants());
    }
    if (!lastImagesFetch) {
      dispatch(getAllImages());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [dispatch, allHydrants]);

  useEffect(() => {
    if (structure && structure.hydrants) {
      setHydrantIds(structure.hydrants.map(hydrant => hydrant.id));
    }
    if (structure && structure.images) {
      setImageIds(structure.images);
    }
  }, [structure]);

  const images = allImages.filter(img => imageIds.includes(img.id));

  useEffect(() => {
    if (id) {
      getStructureById(id)
        .then(res => {
          setStructure(res.data);
        }).catch(err => {
          setError(err);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [id]);

  useEffect(() => {
    if (structure && lastImagesFetch && lastHydFetch) {
      setLoading(false);
    }
  }, [structure, lastImagesFetch, lastHydFetch]);

  return (
    <FlexWithBackgroundTheme height="100vh" overflow="auto" w="100%" flexDirection="column" paddingBottom="1.5rem" paddingX={'1.5rem'}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h2" mt="1rem" size="lg">Pre-Plan Export</Heading>
        <ChText>
          Location ID: {id}
        </ChText>
      </Flex>
      <Divider mb={4} />
      {
        images && images.length > 0 && (
          images.filter(image => image.hrefThumbnail === 'NoImage').length > 0 && (
            <Box mb={4}>
              <ChText fontSize="sm" mb={2}>We are unable to collate PDFs attached to this location. Please download these documents separately as needed.</ChText>
              <Flex flexDirection="column" alignItems="flex-start">{images.filter(image => image.hrefThumbnail === 'NoImage').map((pdf, index) => {
                return (
                <Button variant="link"
                  key={index}
                  fontSize="sm"
                  color={'blue.400'}
                  href={pdf.href}
                  leftIcon={<FaFilePdf />}
                >
                  {pdf.originalFileName}
                </Button>);
              }
              )}</Flex>
            </Box>
          )
        )
      }
      {loading && (
        <Center height="100vh" margin="auto auto">
          <Spinner
            thickness=".25rem"
            speed=".5s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      )
      }
      {
        !loading && structure && (
          <Box h="100%" w="100%">
            <PDFViewer style={{
              height: '100%',
              width: '100%'
            }}>
              <PrePlanPDF
                hydrants={allHydrants.filter(hyd => hydrantIds.includes(hyd.id))}
                images={images}
                structure={structure}
              />
            </PDFViewer>
          </Box>
        )
      }
    </FlexWithBackgroundTheme>
  );
};
