import { Icon } from "@chakra-ui/react";
import React from "react";

const FilterIcon = (props) => (
  <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.48725 13.25C6.2778 13.25 6.10259 13.1794 5.96162 13.0383C5.82051 12.8974 5.74995 12.7222 5.74995 12.5127V7.68917L1.08495 1.76292C0.924677 1.54917 0.901413 1.32694 1.01516 1.09625C1.12905 0.865417 1.32114 0.75 1.59141 0.75H12.4085C12.6788 0.75 12.8709 0.865417 12.9847 1.09625C13.0985 1.32694 13.0752 1.54917 12.915 1.76292L8.24995 7.68917V12.5127C8.24995 12.7222 8.1794 12.8974 8.03829 13.0383C7.89732 13.1794 7.72211 13.25 7.51266 13.25H6.48725ZM6.99995 7.25L11.125 2H2.87495L6.99995 7.25Z" fill="#2C62CB"/>
  </Icon>
);

export default FilterIcon;
