import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as customerAPI from '../api/CustomerAPI';
import { FlexWithBackgroundTheme } from './ThemeContainer';
import { Box, Divider, Flex, Heading, Switch, useColorModeValue, Text, useToast, InputGroup, InputLeftElement, NumberInput, NumberInputField, HStack } from '@chakra-ui/react';
import { DataTable } from '../components/dashboard/DataTable';
import { PrimaryButton } from '../components/form/Button';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { filterPartnerLocations, resetLastPartnerFetch as resetLastPartnerLocationsFetch } from '../features/locations/locationsSlice';
import { filterPartnerHydrants, resetLastPartnerFetch as resetLastPartnerHydrantsFetch } from '../features/hydrants/hydrantsSlice';


export const DataSharing = (props) => {
  return (
    <FlexWithBackgroundTheme
      height={'calc(100vh)'}
      overflow="auto"
      w="100%"
      flexDirection="column"
      paddingBottom="1.5rem"
      paddingX={'1.5rem'}
      fontFamily="Inter"
    >
      <Heading as="h2" mt="1rem" size="lg">Data Sharing</Heading>
      <Divider my="12px" />
      <DataConsentForm {...props} />
      <DataSharingTable {...props} />
    </FlexWithBackgroundTheme>
  );
};

const DataConsentForm = (props) => {
  const customer = useSelector(state => state.customer);
  const dispatch = useDispatch();
  const toast = useToast();

  const onDataSharingChange = (e) => {
    handleUpdateConsent(e.target.checked);
  };

  const handleUpdateConsent = (consentVal) => {
    const data = [];
    const obj = {};
    obj.op = 'replace';
    obj.path = '/dataSharingConsent';
    obj.value = consentVal;
    data.push(obj);

    return new Promise(resolve => customerAPI.editCustomer(
      data,
      () => {
        //success
        toast({
          title: 'Success',
          position: 'top',
          description: 'Data sharing consent updated.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        resolve();
      },
      () => {
        //error
        toast({
          title: 'Error',
          position: 'top',
          description: 'Failed to update account information. Please try again.',
          status: 'error',
          duration: 500,
          isClosable: true
        });
        resolve();
      },
      dispatch
    ));
  };

  return (
    <Box bg={useColorModeValue('white', 'gray.900')} p="1rem">
      <Heading as="h4" size="md">Data Sharing Consent</Heading>
      <Divider my="0.5rem" />
      <Box>
        <Flex gap="8px" alignItems="center">
          <Switch isChecked={customer && customer.dataSharingConsent} onChange={onDataSharingChange} />
          <Text fontSize="sm" marginRight="1rem">I agree to share preplanning data with other fire departments.</Text>
        </Flex>
      </Box>
    </Box>
  );
};

const DataSharingTable = (props) => {
  const customer = useSelector(state => state.customer);
  const [searchRadius, setSearchRadius] = useState(50);
  const toast = useToast();
  const dispatch = useDispatch();

  const togglePartnerSharing = (partnerId) => {

    // If currently a partner, we remove them from the list.
    const isPartner = customer.partners.indexOf(partnerId) > -1;
    const newPartnerList = isPartner ? customer.partners.filter(i => i !== partnerId) : [...customer.partners, partnerId];

    return new Promise(resolve => customerAPI.addCustomerPartners(
      newPartnerList,
      () => {
        //success
        toast({
          title: 'Success',
          position: 'top',
          description: 'Partner data sharing updated.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        dispatch(resetLastPartnerLocationsFetch());
        dispatch(resetLastPartnerHydrantsFetch());

        dispatch(filterPartnerLocations(newPartnerList));
        dispatch(filterPartnerHydrants(newPartnerList));

        resolve();
      },
      () => {
        //error
        toast({
          title: 'Error',
          position: 'top',
          description: 'Failed to update data sharing with partner. Please try again.',
          status: 'error',
          duration: 500,
          isClosable: true
        });
        resolve();
      },
      dispatch,
      customer.links && customer.links.find(x => x.rel === 'partners')
    ));

  };

  const columns = [
    {
      id: 'fireDepartment',
      header: 'Fire Department',
      accessorKey: 'fireDepartment',
    },
    {
      accessor: 'address',
      header: 'Address',
      accessorKey: 'address',
    },
    {
      id: 'isPartner',
      header: 'Pull Data From',
      accessor: 'isPartner',
      accessorFn: row => row.isPartner ? 1 : -1,
      cell: ({ row }) => {
        return (
          <Switch isDisabled={!row.original.dataSharingConsent} isChecked={customer.partners.indexOf(row.original.id) > -1} onChange={() => togglePartnerSharing(row.original.id)} />
        );
      }

    }
  ];

  const [customerList, setCustomerList] = useState([]);
  useEffect(() => {
    if (customer.customerRadiusList) {
      setCustomerList(customer.customerRadiusList.filter(c => c.dataSharingConsent).map(item => ({
        id: item.id,
        fireDepartment: item.name,
        address: item.address ? `${item.address.address1} ${item.address.address2 ? item.address.address2 : ''} ${item.address.city}, ${item.address.state} ${item.address.zip}` : '',
        dataSharingConsent: item.dataSharingConsent,
        isPartner: customer.partners.indexOf(item.id) > -1
      })));
    } else {
      setCustomerList([]);
    }
  }, [customer.customerRadiusList]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [customer.customerRadiusList, customer.partners]);

  useEffect(() => {
    // Call customer radius search on first load
    onRadiusSearch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // ^ will fire every render if removed

  const onRadiusSearch = () => {
    return new Promise(resolve => customerAPI.getCustomerRadius(
      searchRadius,
      (data) => {
        //success
      },
      (err) => {
        //error
        console.warn('Error searching partner radius', err);
      },
      dispatch,
      customer.links && customer.links.find(x => x.rel === 'partners')
    ));
  };

  return (
    <Box bg={useColorModeValue('white', 'gray.900')} px="1rem">
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Heading as="h4" size="md">Data Partners</Heading>
        <RadiusSearch searchRadius={searchRadius} setSearchRadius={setSearchRadius} onSubmit={onRadiusSearch} />
      </Flex>
      <Divider my="0.5rem" />
      {customerList && (
          <DataTable
            loading={false}
            globalSearch
            columns={columns}
            data={customerList}
            customSizing={false}
          />
      )}
    </Box>
  );
};

const RadiusSearch = (props) => {

  return (
    <HStack spacing="4">
      <Text flexShrink="0" fontSize="xs" textTransform="uppercase">Search Radius (Miles)</Text>
      <InputGroup my="0.5rem">
        <InputLeftElement
          pointerEvents={'none'}
          children={<FaSearch color="gray.500" />}
        />
        <NumberInput
          value={props.searchRadius > 0 ? props.searchRadius : ''}
          onChange={(s, n) => {
            Number(n) ? props.setSearchRadius(n) : props.setSearchRadius(0);
          }}
        >
          <NumberInputField paddingLeft="2rem" />
        </NumberInput>
      </InputGroup>
      <PrimaryButton minWidth="6rem" onClick={props.onSubmit}>Search</PrimaryButton>
    </HStack>
  );
};

// export default connect(mapStateToProps, mapDispatchToProps)(DataSharingContainer);

export default DataSharing;
