import React from 'react';
import { FlexWithBackgroundTheme } from '../ThemeContainer';
import { PrePlanDataContainer } from './PrePlanDataContainer';


export const DashboardPage = (/*props*/) => {
  return (
    <FlexWithBackgroundTheme w="100%" height={'100vh'} overflow="hidden">
      <PrePlanDataContainer />
    </FlexWithBackgroundTheme>
  );
};
