import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { FaRegCalendarCheck } from '@react-icons/all-files/fa/FaRegCalendarCheck';
import { selectAllAssignments } from '../../features/assignments/assignmentsSlice';
import { CreateAssignmentModal } from '../../components/assignments/AssignmentModal';


export const AssignmentButton = (props) => {
  const user = useSelector(state => state.user);
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const assignments = useSelector(selectAllAssignments);
  const [canEdit, setCanEdit] = useState(false);
  const onClick = () => {
    setAssignmentModalOpen(true);
  };
  const [buttonText, setButtonText] = useState('Create Assignment');

  useEffect(() => {
    setCanEdit(false);
    if (user.role === 'ADMIN' || user.role === 'PLANNER') {
      setCanEdit(true);
    }
  }, [user, props.selectedRows]);

  useEffect(() => {
    if (props.selectedRows.length > 0) {
      let anyAssignments = false;
      for (let i = 0; i < props.selectedRows.length; i++) {
        if (assignments.find(assignment => assignment.locationId === props.selectedRows[i].id)) {
          anyAssignments = true;
          break;
        }
      }
      if (anyAssignments) {
        setButtonText(`Update ${props.selectedRows.length} Assignments`);
      } else {
        setButtonText(`Assign ${props.selectedRows.length} Locations`);
      }
    } else {
      setButtonText('Create Assignment');
    }
  }, [props.selectedRows]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [props.selectedRows, assignments]);

  return (
    <Fragment>
      <Button style={{minWidth: 'unset', marginLeft: '1em'}} isDisabled={(props.selectedRows.length < 1) || !canEdit} variant="outline" color="green" leftIcon={<FaRegCalendarCheck />} onClick={onClick}>
        {buttonText}
      </Button>
      <CreateAssignmentModal showModal={assignmentModalOpen} toggleModal={() => {
        setAssignmentModalOpen(false);
      }} locationIds={props.selectedRows} />
    </Fragment>
  );
};
