import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function NavigateToLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    navigate(
      {
        pathname: resolveLoginPathname(location),
        state: { from: location },
        search: resolveLoginSearch(location),
      },
      { replace: true }
    );
  }, [navigate, location]);

  return null;
}

/**
 * @param {import('react-router').Location} location
 */
function resolveLoginPathname(location) {
  let loginPath = "/login";
  try {
    const query = new URLSearchParams(location?.search);
    const loginSlug = query.get("department");
    if (loginSlug) {
      loginPath += `/${encodeURIComponent(loginSlug)}`;
    }
  } catch (err) {
    // Do nothing
  }
  return loginPath;
}

/**
 * @param {import('react-router').Location} location
 */
function resolveLoginSearch(location) {
  let returnTo = location.pathname;
  if (!returnTo || returnTo === "/" || returnTo === "/login") {
    return location.search;
  }

  let search = location.search;
  if (search) {
    returnTo += search;

    const searchParams = new URLSearchParams(search);
    searchParams.set("returnTo", returnTo);
    search = `?${searchParams.toString()}`;
  } else {
    search = `?returnTo=${encodeURIComponent(returnTo)}`;
  }
  return search;
}
