import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import FormComponent from './FormComponent';
import { Divider, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { OutlineButton, SubmitButton, SubmitButtonDanger } from './Button';
import { useDispatch } from 'react-redux';
import { format as formatDate, isDate } from 'date-fns';
import { addHydrant, deleteHydrant, editHydrant } from '../../features/hydrants/hydrantsSlice';


export const hydrantFormFields = [
  {
    id: 'lat',
    name: 'lat',
    type: 'static',
    label: 'Latitude',
  },
  {
    id: 'lng',
    name: 'lng',
    type: 'static',
    label: 'Longitude'
  },
  {
    id: 'streetAddress',
    name: 'streetAddress',
    type: 'text',
    label: 'Address'
  },
  {
    id: 'flow',
    name: 'flow',
    type: 'number',
    label: 'Flow',
    min: 0
  },
  {
    id: 'size',
    name: 'size',
    type: 'number',
    label: 'Size',
    min: 0
  },
  {
    id: 'notes',
    name: 'notes',
    type: 'textarea',
    label: 'Notes'
  },
  {
    id: 'dryHydrant',
    name: 'dryHydrant',
    type: 'select',
    label: 'Dry Hydrant',
    options: [
      {
        label: 'No',
        value: false
      },
      {
        label: 'Yes',
        value: true
      }
    ]
  },
  {
    id: 'inService',
    name: 'inService',
    type: 'select',
    label: 'In Service',
    options: [
      {
        label: 'Yes',
        value: 'true'
      },
      {
        label: 'No',
        value: 'false'
      }
    ]
  },
  {
    id: 'outServiceDate',
    name: 'outServiceDate',
    type: 'date',
    label: 'Out of Service Date',
    required: true,
    conditional: {
      name: 'inService',
      condition: (value) => value === 'false' || value === false
    }
  },
  {
    id: 'hydrantId',
    name: 'hydrantId',
    type: 'text',
    label: 'External ID',
    description: 'Optional - For linking to external hydrant data source.',
  }
];


export const HydrantForm = ({
  hydrant,
  ...props
}) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();


  const handleAddHydrant = async(hydrant) => {

    const result = await dispatch(addHydrant(hydrant));

    if (addHydrant.fulfilled.match(result)) {
      onSuccess('Success', 'Hydrant added successfully');
    } else {
      onError('Failed to add hydrant', 'Please check hydrant fields and try again.');
    }
  };

  const handleEditHydrant = async(id, hydrant) => {

    const result = await dispatch(editHydrant({ id: id, data: hydrant }));

    if (editHydrant.fulfilled.match(result)) {
      onSuccess('Success', 'Hydrant updated');
    } else {
      onError('Failed to update hydrant', 'Please check hydrant fields and try again.');
    }
  };

  const onSubmit = (data) => {
    const form = data;
    if (props.action === 'ADD_HYDRANT') {
      const hydrantAdd = {
        latLon: {
          latitude: form.lat,
          longitude: form.lng
        },
        streetAddress: form.streetAddress,
        flow: form.flow,
        size: form.size,
        inService: form.inService,
        notes: form.notes,
        dryHydrant: form.dryHydrant,
        outServiceDate: form.outServiceDate,
        hydrantId: form.hydrantId
      };
      if (hydrantAdd.inService === 'false' || hydrantAdd.inService === false) {
        hydrantAdd.inService = false;
        hydrantAdd.outServiceDate = isDate(form.outServiceDate) ? formatDate(form.outServiceDate, 'yyyy-MM-dd hh:mm:ss') : '';
      } else {
        hydrantAdd.inService = true;
        hydrantAdd.outServiceDate = '';
      }
      return handleAddHydrant(hydrantAdd);

    } else if (props.action === 'EDIT_HYDRANT') {
      const hydrantEdit = [];
      // Note: External Hydrant Id only. Used by customer RMS.
      form.hydrantId && hydrantEdit.push({
        op: 'replace',
        path: '/hydrantId',
        value: form.hydrantId
      });
      form.lat && form.lng && hydrantEdit.push({
        op: 'replace',
        path: '/latLon',
        value: {
          latitude: form.lat,
          longitude: form.lng
        }
      });
      form.streetAddress && hydrantEdit.push(
        {
          op: 'replace',
          path: '/streetAddress',
          value: form.streetAddress
        });
      form.flow && hydrantEdit.push({
        op: 'replace',
        path: '/flow',
        value: form.flow
      });
      form.size && hydrantEdit.push({
        op: 'replace',
        path: '/size',
        value: form.size
      });
      hydrantEdit.push({
        op: 'replace',
        path: '/notes',
        value: form.notes ? form.notes : ''
      });
      form.dryHydrant && hydrantEdit.push({
        op: 'replace',
        path: '/dryHydrant',
        value: form.dryHydrant
      });
      if (form.inService === false || form.inService === 'false') {
        hydrantEdit.push({
          op: 'replace',
          path: '/outServiceDate',
          value: formatDate(form.outServiceDate, 'yyyy-MM-dd hh:mm:ss')
        });
        hydrantEdit.push({
          op: 'replace',
          path: '/inService',
          value: false
        });
      } else {
        hydrantEdit.push({
          op: 'remove',
          path: '/outServiceDate',
          value: ''
        });
        hydrantEdit.push({
          op: 'replace',
          path: '/inService',
          value: true
        });
      }
      return handleEditHydrant(hydrant.id, hydrantEdit);
    } else if (props.action === 'DELETE_HYDRANT') {
      return handleDeleteHydrant(hydrant.id);
    }
    return Promise.resolve();
  };

  const handleDeleteHydrant = async(id) => {
    const response = await dispatch(deleteHydrant(id));
    if (deleteHydrant.fulfilled.match(response)) {
      onSuccess('Success', 'Hydrant deleted');
    } else {
      onError('Cannot Delete Hydrant', 'Cannot delete hydrants binded to a location.');
    }
  };

  const onError = (...p) => onComplete('error', ...p);

  const onSuccess = (...p) => {
    props.closeModal();
    onComplete('success', ...p);
  };

  const onComplete = (type, title, body) => {
    toast({
      title: title,
      position: 'top',
      description: body,
      status: type,
      duration: 2500,
      isClosable: true
    });
  };

  const onCancel = () => {
    methods.reset();
    props.closeModal();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {(props.action === 'DELETE_HYDRANT') ? (
          <DeleteForm hydrant={hydrant} onCancel={onCancel} />
        ) : (
          <EditForm hydrant={hydrant} onCancel={onCancel} />
        )}
      </form>
    </FormProvider>
  );
};

const DeleteForm = (props) => {
  const methods = useFormContext();

  return (
    <Stack spacing="1rem">
      <Text>Are you sure you want to delete hydrant<strong>{props.hydrant?.flow ? ` ${props.hydrant?.flow} gpm` : ''}</strong>?</Text>
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={props.onCancel} width="8rem">No</OutlineButton>
        <SubmitButtonDanger isLoading={methods.formState.isSubmitting} width="8rem">Delete</SubmitButtonDanger>
      </HStack>
    </Stack>
  );
};

const EditForm = ({
  onCancel,
  ...props
}) => {
  const methods = useFormContext();

  // Flatten latlng object
  const hydrant = {
    ...props.hydrant,
    streetAddress: props.hydrant.streetAddress,
    outServiceDate: props.hydrant.outServiceDate ? new Date(props.hydrant.outServiceDate) : null,
    lat: props.hydrant.latLng.lat,
    lng: props.hydrant.latLng.lng
  };
  return (
    <Stack spacing="1rem">
      <FormComponent formFields={hydrantFormFields} data={hydrant} />
      <Divider />
      <HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={onCancel} width="8rem">Cancel</OutlineButton>
        <SubmitButton isLoading={methods.formState.isSubmitting} width="8rem">Save</SubmitButton>
      </HStack>
    </Stack>
  );

};

export default HydrantForm;
