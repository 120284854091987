import React from 'react';
import { FlexWithBackgroundTheme } from './ThemeContainer';
import { Box, Divider, Flex, Icon, Link, Stack, Text } from '@chakra-ui/react';
import EmailIcon from '../components/FigmaExport/EmailIcon';
import PhoneIcon from '../components/FigmaExport/PhoneIcon';
import TicketIcon from '../components/FigmaExport/TicketIcon';


export const SupportPage = (props) => {
  return (
    <FlexWithBackgroundTheme
      height={'calc(100vh)'}
      overflow="auto"
      w="100%"
      flexDirection="column"
      paddingBottom="1.5rem"
      paddingX={'1.5rem'}
      alignItems="center"
      justifyContent="center"
      fontFamily="Inter"
    >
      <Box>
        <Text fontWeight={700} fontSize="24px" mb="12px">Support center</Text>
        <Divider mb="16px" />
        <Flex gap="16px">
        <Card
            icon={EmailIcon}
            text1="Email Us"
            text2="6:00 AM - 4:00 PM CT"
            text3="support@imagetrend.com"
            href="mailto:support@imagetrend.com"
          />
          <Card
            icon={PhoneIcon}
            text1="Call us"
            text2="6:00 AM - 4:00 PM CT"
            text3="833-846-1710"
            href="tel:+18338461710"
          />
          <Card
            icon={TicketIcon}
            text1="Send a ticket"
            text2="6:00 AM - 4:00 PM CT"
            text3="https://imagetrend.com/support/"
            href="https://imagetrend.com/support/"
          />
        </Flex>
      </Box>
    </FlexWithBackgroundTheme>
  );
};

const Card = ({ icon, text1, text2, text3, href }) => {
  return (
    <Stack alignItems="center" py="12px" px="16px" w="320px" gap="16px">
      <Icon as={icon} w="32px" h="32px" />
      <Stack alignItems="center" gap="4px">
        <Text fontWeight={700} fontSize="16px">{text1}</Text>
        <Text fontWeight={400} fontSize="12px" textColor="#707A86">{text2}</Text>
      </Stack>
      <Divider w="300px" />
      <Link color="#2C62CB" fontWeight={600} isExternal href={href}>{text3}</Link>
    </Stack>
  );
};

export default SupportPage;
