/* global google */
export const GMAPS_API_KEY = 'AIzaSyCx-skGzBQpfifpGsclSgQ0rlDng25ZdCg';
export const GMAPS_API_LIBRARIES = ['geometry', 'drawing', 'places', 'visualization'];

export const GMAPS_STATIC_URL = 'https://maps.googleapis.com/maps/api/staticmap';


// San Fran
export const DEFAULT_CENTER = { lat: 37.773972, lng: -122.431297 };

export function offsetCenter(map, latlng, offsetx, offsety) {
  const point1 = map.getProjection().fromLatLngToPoint(
    (latlng instanceof google.maps.LatLng) ? latlng : map.getCenter()
  );
  const point2 = new google.maps.Point(
    ((typeof (offsetx) === 'number' ? offsetx : 0) / Math.pow(2, map.getZoom())) || 0,
    ((typeof (offsety) === 'number' ? offsety : 0) / Math.pow(2, map.getZoom())) || 0
  );
  return map.getProjection().fromPointToLatLng(new google.maps.Point(
    point1.x - point2.x,
    point1.y + point2.y
  ));
}

export const midPoint = coords => {

  const lats = [];
  const lngs = [];
  const coordsLength = coords.length - 1;

  for (let ii = 0; ii < coordsLength; ii++) {
    lngs.push(coords[ii].lng);
    lats.push(coords[ii].lat);
  }

  lats.sort();
  lngs.sort();
  const lowx = lats[0];
  const highx = lats[coordsLength - 1];
  const lowy = lngs[0];
  const highy = lngs[coordsLength - 1];
  const centerX = lowx + ((highx - lowx) / 2);
  const centerY = lowy + ((highy - lowy) / 2);
  return (new google.maps.LatLng(centerX, centerY));
};


export const toRadian = x => {
  return x * Math.PI / 180;
};

export const getDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6378137; // Earth's mean radius in meter
  const dLat = toRadian(lat2 - lat1);
  const dLong = toRadian(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d * 3.28084; // returns the distance in feets
};

export const fetchAddress = async (coords) => {
  const center = midPoint(coords);
  const geocoder = new google.maps.Geocoder();
  const { results } = await geocoder.geocode(
    {
      location: {
        lat: center.lat(),
        lng: center.lng(),
      }
    }
  );
  if (results && results.length) {
    const tmp = { address1: null, city: null, state: null, zip: null };
    for (let i  = 0; i < results.length && results[i]; i++) {
      tmp.address1 = null;
      tmp.city = null;
      tmp.state = null;
      tmp.zip = null;
      results[i].address_components.forEach(function(address) {
        const addressComponent = address.short_name;
        if (address.types && address.types[0] && addressComponent) {
          switch (address.types[0]) {
            case 'street_number':
              tmp.address1 = addressComponent;
              break;
            case 'route':
              tmp.address1 = `${tmp.address1} ${addressComponent}`;
              break;
            case 'locality':
              tmp.city = addressComponent;
              break;
            case 'administrative_area_level_1':
              tmp.state = addressComponent;
              break;
            case 'postal_code':
              tmp.zip = addressComponent;
              break;
            default:
              break;
          }
        }
      });
      if (tmp.address1) break;
    }
    return tmp;
  }
  return null;
};
