import { createBrowserHistory as createHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';
import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
// import logger from 'redux-logger';

export const history = createHistory();

const middleware = [
  // routerMiddleware(history),
];
if (process.env.NODE_ENV === 'development') {
  // middleware.push(logger);
}

/**
 * Truncates an array to the first 5 entries
 * @param array
 * @returns {*[]}
 */
function devtoolsArrayCleaner(array) {
    return [
        `<< ${array.length} ids (sanitized, showing up to first 5 entries) >>`,
        ...array.slice(0, 5),
    ];
}

/**
 * Truncates an entity toolkit state to the first 5 entries
 * @param slice
 * @returns {*&{entities: {_summary_: string}, ids: *[]}}
 */
function devtoolsEntityCleaner(slice) {
    return {
        ...slice,
        ids: devtoolsArrayCleaner(slice.ids),
        entities: {
            _summary_: `<< ${Object.keys(slice).length} keys (sanitized, showing up to first 5 entries) >>`,
            ...Object.keys(slice.entities)
                .slice(0, 5)
                .reduce((a, c) => { a[c] = slice.entities[c]; return a; }, {})
        }
    };
}

function toggleDevTools(enabled, sanitized) {

    // DevTools enabled but do not sanitize
    if (enabled && !sanitized) return true;

    // DevTools enabled and sanitize
    if (enabled) {
        return {
            stateSanitizer: (state) => {
                if (state.locations || state.hydrants || state.customer) {
                    return {
                        ...state,
                        customer: {
                            ...state.customer,
                            users: devtoolsArrayCleaner(state.customer.users),
                        },
                        locations: devtoolsEntityCleaner(state.locations),
                        hydrants: devtoolsEntityCleaner(state.hydrants),

                    };
                }
                return state;
            }
        };
    }

    // DevTools not enabled
    return false;
}

/* eslint-disable no-underscore-dangle */
export function configureStore(initialState) {
  return rtkConfigureStore(
    {
      reducer: rootReducer(history),
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({
          // Speed up performance on dev
          immutableCheck: false,
          serializableCheck: false,
      })
        .concat(...middleware),

      // Swap the next two lines if you _really_ need to see the full, un-truncated state
      // devTools: toggleDevTools(process.env.NODE_ENV !== 'production', false),
      devTools: toggleDevTools(process.env.NODE_ENV !== 'production', true),

      preloadedState: initialState,
      // enhancers: []
    }
  );
}
export default configureStore;
