/**
 * Environment configurations, keyed by name
 * @type {{prod: {API_BASE_URL: string, ENV_TYPE: string}, dev: {API_BASE_URL: string, ENV_TYPE: string}, local: {API_BASE_URL: string, ENV_TYPE: string}}}
 */
const ENVIRONMENTS = {
    local: {
        ENV_TYPE: 'local',
        API_BASE_URL: 'http://mac-studio.local:4567'
    },
    dev: {
        ENV_TYPE: 'dev',
        API_BASE_URL: 'https://test.flowmsp.com'
    },
    prod: {
        ENV_TYPE: 'prod',
        API_BASE_URL: 'https://app.flowmsp.com'
    }
};

/**
 * Domain hostname to environment mappings
 * @type {{localhost: {API_BASE_URL: string, ENV_TYPE: string}, "app.flowmsp.com": {API_BASE_URL: string, ENV_TYPE: string}, "test.flowmsp.com": {API_BASE_URL: string, ENV_TYPE: string}}}
 */
const DOMAIN_MAPPINGS = {
    'localhost': ENVIRONMENTS.dev,
    'test.flowmsp.com': ENVIRONMENTS.dev,
    'staging.flowmsp.com': ENVIRONMENTS.prod,
    'app.flowmsp.com': ENVIRONMENTS.prod,
};

/**
 * Returns the environment configuration to use based on current url
 * @returns {{API_BASE_URL: string, ENV_TYPE: string}|*}
 */
function getEnvironment() {
    // Use the env from the build process as priority
    const buildEnv = process.env.REACT_APP_ENV_TYPE;
    const buildApiUrl = process.env.REACT_APP_API_BASE_URL;
    if (buildEnv && buildApiUrl) {
        console.info(`Overriding all from build: ${buildEnv}`); // eslint-disable-line no-console
        return {
            ENV_TYPE: buildEnv,
            API_BASE_URL: buildApiUrl
        };
    }
    if (buildEnv && ENVIRONMENTS[buildEnv]) {
        console.info(`Overriding env from build: ${buildEnv}`); // eslint-disable-line no-console
        return ENVIRONMENTS[buildEnv];
    }

    const params = new URLSearchParams(window.location.search);
    const urlEnv = params.get('env');

    // Use the env from the URL as priority
    if (urlEnv && ENVIRONMENTS[urlEnv]) {
        console.info(`Overriding env from url: ${urlEnv}`); // eslint-disable-line no-console
        return ENVIRONMENTS[urlEnv];
    }

    // Use the hostname env mapping
    const hostname = window.location.hostname.toLowerCase();
    if (DOMAIN_MAPPINGS[hostname]) return DOMAIN_MAPPINGS[hostname];

    // Default (assume production)
    console.info('Unknown env, defaulting to prod'); // eslint-disable-line no-console
    return ENVIRONMENTS.prod;
}


export const firebaseConfig = {
    apiKey: 'AIzaSyAYNZ_D-ZJzazYt4DhFfBf1zLXFNz1uSnQ',
    authDomain: 'flowmsp.firebaseapp.com',
    databaseURL: 'https://flowmsp.firebaseio.com',
    projectId: 'flowmsp',
    storageBucket: 'flowmsp.appspot.com',
    messagingSenderId: '84318145205',
    appId: '1:84318145205:web:2bdcc06696b3cda5c0ccfc',
    measurementId: 'G-Z6JFH1XYVG'
};

export default getEnvironment();
