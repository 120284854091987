import { loginSuccess, loginError } from '../actions/session-actions';
import axios from 'axios';
import config from '../../config.js';

export function loginUser(dispatch, credentials, successCallback, errorCallback) {

  const credString = btoa(`${credentials.email}:${credentials.password}`);
  axios.post(`${config.API_BASE_URL}/api/auth/token`, {}, {
    headers: { 'Authorization': `Basic ${credString}` }
  })

    .then(function(response) {
      processLoginUserData(
        dispatch,
        response.data,
        {
          persistLogin: credentials.persistLogin,
        },
        successCallback?.bind(this, credentials.email),
        errorCallback
      );
    })
    .catch(function(error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something went wrong. Please try again.";
      callCommonCallback(errorCallback, message);
      dispatch(loginError(message));
    });
}

export function loginUserByAccessToken(dispatch, accessToken, successCallback, errorCallback) {
  axios.post(
    `${config.API_BASE_URL}/api/auth/introspect`,
    new URLSearchParams({ token: accessToken }),
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
    .then(function(response) {
      if (!response.data.active || !response.data.links) {
        const message = "Something went wrong. Please try again.";
        callCommonCallback(errorCallback, message);
        dispatch(loginError(message));
        return;
      }

      processLoginUserData(
        dispatch,
        { accessToken, tokenType: 'Bearer', links: response.data.links },
        {
          persistLogin: false,
        },
        successCallback,
        errorCallback
      );
    })
    .catch(function() {
      const message = "Something went wrong. Please try again.";
      callCommonCallback(errorCallback, message);
      dispatch(loginError(message));
    });
}

/**
 * @param {Function} dispatch
 * @param {{ accessToken?: string; tokenType?: string; links?: Array<{ rel: string; op: string; href: string; }> }} data
 * @param {{ persistLogin?: boolean }} [options]
 * @param {(userId: string) => void} [successCallback]
 * @param {(message: string) => void} [errorCallback]
 * @returns {{ customerId: string; jwt: string; customerLink: {href: string}; userLink: {href: string}; tokenType: string; customerIDLink: {href: string}; dataFormat: string} | { msg: string }}
 */
export function processLoginUserData(
  dispatch,
  data,
  options,
  successCallback,
  errorCallback
) {
  const errMsg = getLink(data.links, "msg");
  if (errMsg) {
    callCommonCallback(errorCallback);
    dispatch(loginError(errMsg.href));
    return { msg: errMsg.href };
  }

  const jwt = data.accessToken;
  const customerLink = getLink(data.links, "customer");
  const userLink = getLink(data.links, "user");
  const tokenType = data.tokenType;
  const customerIDLink = getLink(data.links, "customerID");
  const dataFormat = data.dataFormat || 'OLD';

  sessionStorage.setItem("jwt", jwt);
  sessionStorage.setItem("customer", JSON.stringify(customerLink));
  sessionStorage.setItem("user", JSON.stringify(userLink));
  sessionStorage.setItem("tokenType", tokenType);
  sessionStorage.setItem("customerID", JSON.stringify(customerIDLink));
  sessionStorage.setItem('dataFormat', dataFormat);

  if (options?.persistLogin === true) {
    localStorage.setItem("jwt", jwt);
    localStorage.setItem("customer", JSON.stringify(customerLink));
    localStorage.setItem("user", JSON.stringify(userLink));
    localStorage.setItem("tokenType", tokenType);
    localStorage.setItem("customerID", JSON.stringify(customerIDLink));
    localStorage.setItem('dataFormat', dataFormat);
  }

  if (typeof successCallback === "function") {
    const strComp = userLink.href.split("/");
    const userId = strComp[strComp.length - 1];
    successCallback(userId);
  }

  const customerId = customerIDLink.href;
  const authData = {
    customerId,
    jwt,
    customerLink,
    userLink,
    tokenType,
    customerIDLink,
    dataFormat,
  };
  dispatch(loginSuccess(authData));
  return authData;
}

function callCommonCallback(commonCallback, ...args) {
  if (typeof commonCallback === 'function') {
    commonCallback(...args);
  }
}

function getLink(links, rel) {
  return links.filter(function(obj) {
    return obj.rel === rel;
  })[0];
}

export function forgotPasswordResetRequest(data, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/passwordresetrequest`, data, {})
    .then(function(/*response*/) {
      onSuccess();
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function forgotPasswordReset(data, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/completepasswordreset`, data, {})
    .then(function(/*response*/) {
      // showMessage({status: 'Success', message: 'Password reset successfully'});
      onSuccess();
    })
    .catch(function(/*error*/) {
      // showMessage({status: 'Error', message: 'Oops! something went wrong in forgotPasswordReset.'});
      onError();
    });
}

export function submitSignUpSecondary(data, linkPart, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/registrationLink/${linkPart}/createSecondary`, data, {})
    .then(function(/*response*/) {
      onSuccess();
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function validateLinkPart(linkPart, onSuccess, onError) {
  axios.get(`${config.API_BASE_URL}/api/registrationLink/${linkPart}/validate`, {})
    .then(function(response) {
      if (response.data.success) {
        onSuccess();
      } else {
        onError();
      }
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function getUserFromLinkPart(linkPart, onSuccess, onError) {
  axios.get(`${config.API_BASE_URL}/api/registrationLink/${linkPart}/user`, {})
    .then(function(response) {
      onSuccess(response.data.email);
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function validateCreateDepartmentLinkPart(linkPart, onSuccess, onError) {
  axios.get(`${config.API_BASE_URL}/api/signup/validate/${linkPart}`, {})
    .then(function(response) {
      if (response.data.success) {
        onSuccess();
      } else {
        onError();
      }
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function submitCreateDepartment(data, linkPart, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/signup/${linkPart}`, data, {})
    .then(function(/*response*/) {
      onSuccess();
    })
    .catch(function(/*error*/) {
      onError();
    });
}
