import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text
} from '@chakra-ui/react';
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle';
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';

import { formFields as buildingDataFields } from '../../components/form/BuildingDataFormComponent';

const CompletionStats = (props) => {
  return (
    <HStack justify="space-between">
      <Text fontSize="xs" width="50%" fontWeight={600}>{props.label}</Text>
      <HStack width="25%" justify="space-between">
        <Text fontSize="xs" textAlign="center">{props.value}</Text>
        <Text fontSize="xs">{props.percent}</Text>
      </HStack>
    </HStack>
  );
};

export const CompletionPopover = ({ value, row }) => {
  // const completionColor = (val) => {
  //   if (value > 80) {
  //     return 'green';
  //   }
  //   if (value > 60) {
  //     return 'yellow';
  //   }
  //   if (value > 40) {
  //     return 'orange';
  //   }
  //   if (value <= 40) {
  //     return 'red';
  //   }
  //   return 'gray';
  // };

  return (
    <div>
      <Popover placement="right">
        <PopoverTrigger>
          <Flex
            cursor="pointer"
            bg="#2C62CB33"
            _hover={{ bg: 'gray.100' }}
            _expanded={{ bg: '#2C62CBBF', color: 'white' }}
            alignItems="center"
            py={2}
            px={2}
            borderRadius={999} borderWidth={1}
            justifyContent="space-between"
            maxWidth="4rem"
          >
            <Text cursor="pointer">
              {value}%
            </Text>
            <Icon ml={1} width={3} height={3} as={FiChevronRight} />
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Box p={2}>
              <Heading as="h4" mb={2} size="sm" textTransform="uppercase">Pre-Plan Completion</Heading>
              <Stack spacing={1}>
                <CompletionStats label={'Location Address'} value={row.original.address && row.original.address.address1 ? 'Yes' : 'No'} percent={`${row.original.completion.breakdown.address}%`} />
                <CompletionStats label={'Building Footprint'} value={row.original.latLon ? 'No' : 'Yes'} percent={`${row.original.completion.breakdown.footprint}%`} />
                <CompletionStats label={'Building Data'} value={`${row.original.building ? Object.keys(row.original.building).filter(key => buildingDataFields.find(b => b.id === key)).length : '0'}`} percent={`${row.original.completion.breakdown.building}%`} />
                <CompletionStats label={'Hydrants Linked'} value={`${row.original.hydrants ? row.original.hydrants : '0'}`} percent={`${row.original.completion.breakdown.hydrants}%`} />
                <CompletionStats label={'Image Annotations'} value={`${row.original.imageAnnotations ? row.original.imageAnnotations : '0'}`} percent={`${row.original.completion.breakdown.annotations}%`} />
                <Divider />
                <Flex justifyContent="space-between">
                  <Text fontSize="xs" fontWeight={600}>Total</Text>
                  <Text fontSize="xs">{value}%</Text>
                </Flex>
              </Stack>
              {value < 100 && (
                <Stack spacing={2} mt={2}>
                  {row.original.latLon && (
                    <Text fontSize="sm" fontWeight={400} fontStyle="italic">Add a building trace to increase building footprint score from <strong>{row.original.completion.breakdown.footprint}%</strong> to <strong>15%</strong></Text>
                  )}
                  {row.original.hydrants < 1 && (
                    <Text fontSize="sm" fontWeight={400} fontStyle="italic">Link hydrants to location to increase hydrant score from <strong>{row.original.completion.breakdown.hydrants}%</strong> to <strong>10%</strong></Text>
                  )}
                  {row.original.imageAnnotations < 5 && (
                    <Text fontSize="sm" fontWeight={400} fontStyle="italic">Annotate {5 - row.original.imageAnnotations} more image{5 - row.original.imageAnnotations > 1 ? 's' : ''} to increase annotation score from <strong>{row.original.completion.breakdown.annotations}%</strong> to <strong>50%</strong></Text>
                  )}
                  {row.original.building && Object.keys(row.original.building).length < 9 && (<React.Fragment>
                    <Text fontSize="sm" fontWeight={400} fontStyle="italic">Add missing building data points to increase building data score from <strong>{row.original.completion.breakdown.building}%</strong> to <strong>25%</strong></Text>
                  </React.Fragment>
                  )}
                  <Divider />
                  <Text fontSize="sm">Building data:</Text>
                  <List spacing={1}>
                    {buildingDataFields.map(b => (
                      <ListItem display="flex" alignItems="center" key={b.id}>
                        {row.original.building[b.id] ? (
                          <ListIcon as={FaCheckCircle} color="green.500" />
                        ) : (
                          <ListIcon as={FaTimesCircle} color="red.500" />
                        )}
                        <Text display="flex" fontSize="sm" fontWeight={400}>{b.label}</Text>
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};
