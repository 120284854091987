import React, { useEffect, useState } from 'react';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Divider, Flex, Grid, GridItem, Stack, Text, Tooltip } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectAllAssignments } from '../../features/assignments/assignmentsSlice';
import { formatDateFromISO } from '../../helpers/Date';
import { AssignmentBadge } from './AssignmentBadge';
import { getNameOrEmail } from '../../helpers/User';
import { getLatestAssignment } from './AssignmentUtil';
import ArrowUpIcon from '../FigmaExport/ArrowUpIcon';
import ArrowDownIcon from '../FigmaExport/ArrowDownIcon';
import { OutlineButton } from '../form/Button';
import AddIcon from '../FigmaExport/AddIcon';
import EditIcon from '../FigmaExport/EditIcon';
import { MoreableText } from '../common/MoreableText';
import { CreateAssignmentModal } from './AssignmentModal';

export const AssignmentCard = ({ locationId, type, readOnly, notMine }) => { // locationId = structureId | occupantId
  const customer = useSelector(state => state.customer);
  const user = useSelector(state => state.user);
  const assignments = useSelector(selectAllAssignments);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [reason, setReason] = useState(null);
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);

  useEffect(() => {
    const latestAssignment = getLatestAssignment(assignments, locationId);
    if (latestAssignment) {
      // Check if assignment is cleared
      if (!latestAssignment.assignedTo && !latestAssignment.assignmentStatus && !latestAssignment.assignmentDueOn) {
        // cleared - act like no assignment exists
        setCurrentAssignment(null);
        return;
      }

      const assignedBy = customer.users.find(u => u.id === latestAssignment.assignedBy);
      const assignedTo = customer.users.find(u => u.id === latestAssignment.assignedTo);

      setCurrentAssignment({
        assignedBy: getNameOrEmail(assignedBy),
        assignedTo: getNameOrEmail(assignedTo),
        assignmentStatus: latestAssignment.assignmentStatus,
        assignmentDueOn: latestAssignment.assignmentDueOn,
        notes: latestAssignment.notes,
      });
    } else {
      setCurrentAssignment(null);
    }
  }, [locationId, assignments, customer]);


  useEffect(() => {
    if (readOnly) {
      setCanEdit(false);
      setReason("Editing is disabled. Visit Elite to change.");
    } else if (notMine) {
      setCanEdit(false);
      setReason("Partner Data can't be edited.");
    } else if (!(user.role === 'ADMIN' || user.role === 'PLANNER')) {
      setCanEdit(false);
      setReason("Only ADMIN or PLANNER users can edit.");
    // } else if (user.id === (currentAssignment && currentAssignment.assignedTo)) {
    //   setCanEdit(true);
    } else {
      setCanEdit(true);
    }
  }, [user, currentAssignment, readOnly, notMine]);

  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    color: 'gray.700',
    lineHeight: '1.5rem'
  };

  const isActiveAssignment = (currentAssignment && currentAssignment.assignmentStatus !== 'COMPLETE');

  const locationIds = locationId && [{id: locationId, type: type}];

  return (
    <Box key={`assignment-${locationId}`} width="100%" padding="12px" borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <CreateAssignmentModal showModal={assignmentModalOpen} toggleModal={() => {
        setAssignmentModalOpen(false);
      }} locationIds={locationIds} />
      <Accordion defaultIndex={0} allowToggle>
        <AccordionItem border={0}>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton paddingLeft={0} paddingY={0}>
                  <Box as='h2' fontSize="lg" fontWeight="600" flex='1' textAlign='left'>
                    Active Assignment
                  </Box>
                  { isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon /> }
                </AccordionButton>
              </h2>
              <AccordionPanel padding={0}>
                <Divider marginY={1}/>
                <Stack gap="8px">
                  {isActiveAssignment && (<Tooltip isDisabled={canEdit} placement="left" label={reason}>
                    <Flex gap="8px">
                      <OutlineButton
                        fontSize="sm"
                        color="#2C62CB"
                        borderColor="#2C62CB"
                        leftIcon={<EditIcon fill="#2C62CB" />}
                        onClick={() => setAssignmentModalOpen(true)}
                        flexGrow={1}
                        isDisabled={!canEdit}
                      >
                        Edit Assignment
                      </OutlineButton>
                    </Flex>
                  </Tooltip>)}
                  {!isActiveAssignment && (<Tooltip isDisabled={canEdit} placement="left" label={reason}>
                    <Flex gap="8px">
                      <OutlineButton
                        fontSize="sm"
                        color="#2C62CB"
                        borderColor="#2C62CB"
                        leftIcon={<AddIcon fill="#2C62CB" />}
                        onClick={() => setAssignmentModalOpen(true)}
                        flexGrow={1}
                        isDisabled={!canEdit}
                      >
                        Add Assignment
                      </OutlineButton>
                    </Flex>
                  </Tooltip>)}
                  {isActiveAssignment ? (
                    <Stack spacing="">
                      <Grid templateColumns='2fr 3fr'>
                        <GridItem><Text {...labelStyle}>Assigned To</Text></GridItem>
                        <GridItem><Text fontWeight="600" fontSize="sm">{currentAssignment.assignedTo || <em>Not assigned</em>}</Text></GridItem>
                      </Grid>
                      <Grid templateColumns='2fr 3fr'>
                        <GridItem><Text {...labelStyle}>Due By</Text></GridItem>
                        <GridItem><Text fontWeight="600" fontSize="sm">{currentAssignment.assignmentDueOn ? formatDateFromISO(currentAssignment.assignmentDueOn) : <em>–</em>}</Text></GridItem>
                      </Grid>
                      <Grid templateColumns='2fr 3fr'>
                        <GridItem><Text {...labelStyle}>Status</Text></GridItem>
                        <GridItem><AssignmentBadge status={currentAssignment.assignmentStatus} /></GridItem>
                      </Grid>
                      <Grid templateColumns='2fr 3fr'>
                        <GridItem><Text {...labelStyle}>Notes</Text></GridItem>
                        <GridItem><MoreableText text={currentAssignment.notes} header="Notes" noOfLines={2} fontWeight="600" fontSize="sm" /></GridItem>
                      </Grid>
                    </Stack>
                  ) : (
                    <Text textAlign="center" py="8px" >No assignment added.</Text>
                  )}
                </Stack>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
