import * as AJAXUtil from './AJAXUtil';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '../../config';
import { store } from '../index';

axiosRetry(axios, {
  retries: 50,
  retryCondition: err => {
    console.warn('Fetch failed, response:', err.response); // eslint-disable-line no-console
    return (err.config && err.config.method === 'GET') && err.response && (err.response.status === 404 || err.response.status === 403);
  },
  retryDelay: () => 500
});


export const uploadImage = (data, locationsLink/*, dispatch*/) => {
  const formData = new FormData();
  formData.append('file', data.image);
  return AJAXUtil.AJAX({
    method: 'POST',
    url: `${locationsLink.href}/${data.locationId}/image`,
    data: formData
  });
};

export const deleteImage = (locationId, imageId, locationsLink) => {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${locationsLink.href}/${locationId}/image/${imageId
      }`
  });
};

export const reorderImage = (locationId, images, locationsLink) => {
  return AJAXUtil.AJAX({
    method: 'POST',
    url: `${locationsLink.href}/${locationId}/imageReorder`,
    data: images
  });
};


export function getPreplan(locationCoords, storey, storeyBelow, successCallback, preplanLink) {
  AJAXUtil.AJAX({
    method: 'POST',
    data: { locationCoords, storey, storeyBelow },
    url: preplanLink.href
  }).then(res => {
    if (typeof successCallback === 'function') {
      successCallback(res.data);
    }
  });
}

export function getPreplanByLocation(location, successCallback, locationsLink) {
  AJAXUtil.AJAX({
    method: 'GET',
    url: `${locationsLink.href}/${location.id}/preplan`
  }).then(res => {
    if (typeof successCallback === 'function') {
      successCallback(location, res.data);
    }
  });
}

export function getISOReportCSV(slug) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/${slug}/location/isoreport`
  });
}

export function getHydrantReportCSV(slug) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/${slug}/hydrant/isoreport`
  });
}

// export function editLocation(dispatch, id, location, successCallback, errorCallback) {
//   AJAXUtil.AJAX({
//     method: 'PATCH',
//     url: `${JSON.parse(sessionStorage.location).href}/${id}`,
//     data: location
//   }).then(res => {
//     dispatch(editCustomerLocation(res.data));
//     if (typeof successCallback === 'function') {
//       successCallback();
//     }
//   }).catch(err => {
//     if (typeof errorCallback === 'function') {
//       errorCallback(err);
//     }
//   });
// }

export function deleteLocation(location, locationsLink) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${locationsLink.href}/${location.id}`
  });
}

export function saveAnnotation(locationId, imageId, annotationJson, annotationSVG, locationsLink/*, dispatch*/) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${locationsLink.href}/${locationId}/image/${imageId
      }/annotation`,
    data: { annotationJson, annotationSVG }
  });
}

export function getAnnotation(locationId, imageId, locationsLink) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${locationsLink.href}/${locationId}/image/${imageId
      }/annotation`
  });
}

export function saveTags(locationId, imageId, tagJson, locationsLink) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: `${locationsLink.href}/${locationId}/image/${imageId
      }/tags`,
    data: tagJson
  });
}

export function getTags(locationId, imageId, locationsLink) {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${locationsLink.href}/${locationId}/image/${imageId
      }/tags`
  });
}

export function uploadPrePlan(data, successCallback, errorCallback, uploadConfig, locationsLink) {
  const state = store.getState();
  const jwt = state && state.session && state.session.jwt;
  const tokenType = state && state.session && state.session.tokenType;

  const req = {
    headers: {
      Authorization: `${tokenType} ${jwt}`,
      'Content-Type': 'multipart/form-data',
      'X-FlowMSP-Source': 'Web',
      'X-FlowMSP-Version': '2.40.0'
    }, // TODO: Replace with localStorage config or something
    onUploadProgress: function(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (typeof uploadConfig === 'function') {
        uploadConfig(percentCompleted);
      }
    }
  };
  const url = `${locationsLink.href}/upload`;
  axios.put(url, data, req).then((res) => {
    if (res.data) {
      if (res.data.successFlag === 0) {
        if (typeof successCallback === 'function') {
          const msg = res.data.log;
          successCallback(msg);
        }
      } else {
        if (typeof errorCallback === 'function') {
          const msg = res.data.log;
          errorCallback(msg);
        }
      }
    } else {
      if (typeof errorCallback === 'function') {
        errorCallback('Error processing file');
      }
    }
  }).catch(function(/*error*/) {
    if (typeof errorCallback === 'function') {
      errorCallback('Error while uploading file');
    }
  });
}

/**
 * New API Services
 * Individually controlalbel functions for each API call
 */
export const fetchAllLocations = (locationsLink) => {
  return AJAXUtil.AJAX({
    method: locationsLink.op,
    url: locationsLink.href,
  });
};

export const addLocation = (location, locationsLink) => {
  return AJAXUtil.AJAX({
    method: 'PUT',
    url: locationsLink.href,
    data: location
  });
};

export function editLocation(id, location, locationsLink) {
  return AJAXUtil.AJAX({
    method: 'PATCH',
    url: `${locationsLink.href}/${id}`,
    data: location
  });

  // }).then(res => {
  //   dispatch(editCustomerLocation(res.data));
  //   if (typeof successCallback === 'function') {
  //     successCallback();
  //   }
  // }).catch(err => {
  //   if (typeof errorCallback === 'function') {
  //     errorCallback(err);
  //   }
  // });
}

export const fetchPartnerLocations = (partnerId, locationsLink) => {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${locationsLink.href}/partners/${partnerId}`
  });
};
